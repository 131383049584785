import React, { useContext, useEffect } from 'react';
import { ApolloClient, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';

import { cache } from './cache';
import { AuthContext } from './Pages/Auth/AuthFunctions';

export const ApolloClientProvider: React.FC = ({ children }) => {
    const { isAuthenticated, getCurrentAccessToken } = useContext(AuthContext);

    const httpLink = createHttpLink({
        uri: '/graphql',
        fetch,
    });

    const authLink = setContext(async (_, { headers }) => {
        const accessToken = await getCurrentAccessToken();
        if (accessToken) {
            return {
                headers: {
                    'X-Authentication-Token': accessToken,
                    'X-Authentication-Type': 'customer',
                },
            };
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache,
    });

    useEffect(() => {
        if (!isAuthenticated) {
            client.resetStore();
        }
    }, [client, isAuthenticated]);

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
