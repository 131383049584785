import React, { useCallback, useEffect } from 'react';
import { gql } from '@apollo/client';

import { View, Text } from 'react-native';
import { NewAddressForm, AddressInput } from '../../../../Organisms/NewAddress/NewAddressForm';
import { CountryCode, LocaleType } from '../../../../Organisms/AddressTypings';
import { Message } from '@valet/ui-components';
import {
    CustomerAddress,
    addressPageSize,
    PICKUP_RETRIEVE_ADDRESS_QUERY,
} from './AddressSelection';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAddAddressMutation, CustomerAddressType } from '../../../../../schema-types';

export const PICKUP_ADD_CUSTOMER_ADDRESS_MUTATION = gql`
    mutation addAddress($customerAddress: AddCustomerAddressInput!) {
        addCustomerAddress(data: $customerAddress) {
            customerAddress {
                id
                address1
                address2
                city
                zoneId
                countryId
                zip
                longitude
                latitude
                phone
                firstName
                lastName
            }
        }
    }
`;

type NewAddressPageProps = {
    onGoToNextPage: () => void;
    customerLocale: LocaleType;
    customerCountryCode: CountryCode;
    onNewAddressSubmit: (newAddress: CustomerAddress) => void;
};

export const NewAddressPage: React.FC<NewAddressPageProps> = ({
    onGoToNextPage,
    customerLocale,
    customerCountryCode,
    onNewAddressSubmit,
}) => {
    const intl = useIntl();

    const [addCustomerAddress, { data, loading, error }] = useAddAddressMutation({
        refetchQueries: [
            {
                query: PICKUP_RETRIEVE_ADDRESS_QUERY,
                variables: { pageSize: addressPageSize, cursor: '' },
            },
        ],
        awaitRefetchQueries: true,
    });

    useEffect(() => {
        if (data !== undefined) {
            onGoToNextPage();
            return;
        }
    }, [data, onGoToNextPage]);

    const handleSuccessfulSubmit = useCallback(
        async (addressInput: AddressInput): Promise<void> => {
            const {
                address1,
                address2,
                city,
                zoneId,
                countryId,
                zip,
                phone,
                firstName,
                lastName,
            } = addressInput;

            try {
                const res = await addCustomerAddress({
                    variables: {
                        customerAddress: {
                            addressType: CustomerAddressType.Delivery,
                            address1: address1,
                            address2: address2,
                            city: city,
                            zoneId: zoneId,
                            countryId: countryId,
                            zip: zip,
                            longitude: 0,
                            latitude: 0,
                            phone: phone,
                            firstName: firstName,
                            lastName: lastName,
                        },
                    },
                });

                if (res.data?.addCustomerAddress.customerAddress) {
                    onNewAddressSubmit(res.data?.addCustomerAddress.customerAddress);
                }
            } catch (ignore) {} //eslint-disable-line no-empty
        },
        [addCustomerAddress, onNewAddressSubmit],
    );

    if (loading) {
        return (
            <View testID="data-newAddressPage">
                <Text>
                    <FormattedMessage
                        id="newAddress.addingNewAddressLoading"
                        defaultMessage="Adding new address..."
                    />
                </Text>
            </View>
        );
    }

    if (!data) {
        return (
            <View testID="data-newAddressPage">
                {error && (
                    <Message
                        type="negative"
                        header={intl.formatMessage({
                            id: 'newAddress.errorCouldNoAddTryAgain',
                            defaultMessage: 'Could not add your address - please try again',
                        })}
                        content={error.message}
                        testID="data-newAddressPageMessage"
                    />
                )}

                <NewAddressForm
                    locale={customerLocale}
                    countryCode={customerCountryCode}
                    onSuccessfulSubmit={handleSuccessfulSubmit}
                />
            </View>
        );
    }

    return <></>;
};
