import { gql } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { Button, Message } from '@valet/ui-components';
import {
    useGetVisitManagementByIdQuery,
    useVisitCancelVisitMutation,
    useUpdateVisitManagementMutation,
    VisitManagementDetailFragment,
} from '../../../schema-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ScheduleWindow, ScheduleWindowSelectionPage } from '../Request/Components/VisitSelection/NewVisit/ScheduleWindowSelection'

const VISIT_MANAGEMENT_FRAGMENT = gql`
    fragment VisitManagementDetail on Visit {
        id
        status
        startTime
        endTime
        expectedDuration
        customerAddress{
            zip
        }
    }
`;


export const RETRIEVE_VISIT_MANAGEMENT_QUERY = gql`
    query getVisitManagementById($visitId: String!) {
        visitById(visitId: $visitId) {
            ...VisitManagementDetail
        }
    }
    ${VISIT_MANAGEMENT_FRAGMENT}
`;

export const UPDATE_VISIT_MUTATION = gql`
    mutation updateVisitManagement($data: VisitUpdateWindowInput!) {
        visitUpdateWindow(data: $data) {
            data {
                ...VisitManagementDetail
            }
        }
    }
`;


export const CANCEL_VISIT_MUTATION = gql`
    mutation visitCancelVisit($data: VisitCancelVisitInput!) {
        visitCancelVisit(data: $data) {
            data {
                ...VisitManagementDetail
            }
        }
    }
    ${VISIT_MANAGEMENT_FRAGMENT}
`;

type VisitManagementDetailPageProps = {};

export const VisitManagementDetailPage: React.FC<VisitManagementDetailPageProps> = () => {
    const history = useHistory();
    const intl = useIntl();
    const match = useRouteMatch();
    const params: { visitId?: string } = match.params;
    const visitId = params.visitId;
    const [selectedScheduleWindow, setSelectedScheduleWindow] = useState<ScheduleWindow | undefined>(undefined);

    const {
        data: retrievedVisitManagementData,
        loading: retrievedVisitManagementDetailLoading,
        error: retrievedVisitManagementDetailError,
    } = useGetVisitManagementByIdQuery({
        variables: {
            visitId: visitId || '',
        },

    });

    const visitData = retrievedVisitManagementData?.visitById;


    const [
        UpdateVisitManagementMutation,
        {
            loading: UpdateVisitManagementDetailMutationLoading,
            error: UpdateVisitManagementDetailMutationError,
        },
    ] = useUpdateVisitManagementMutation();

    const [
        CancelVisitMutation,
        {
            loading: CancelVisitManagementDetailMutationLoading,
            error: CancelVisitManagementDetailMutationError,
        },
    ] = useVisitCancelVisitMutation();

    const handleBackClick = useCallback(async () => {
        history.push(`/visitManagement`);
    }, [history]);


    const handleScheduleWindowSelection = useCallback(
        (scheduleWindow: ScheduleWindow) => {
            setSelectedScheduleWindow(scheduleWindow);

        },
        [setSelectedScheduleWindow],
    );

    const handleSaveVisitManagementDetailClick = useCallback(async () => {
        if (visitData) {
            try {
                await UpdateVisitManagementMutation({
                    variables: {
                        data: {
                            visitId: visitData.id,
                            startTime: selectedScheduleWindow?.startTime,
                            endTime: selectedScheduleWindow?.endTime
                        }

                    },
                });
                handleBackClick();
            } catch (error) { }
        }
    }, [visitData, handleBackClick, selectedScheduleWindow, UpdateVisitManagementMutation]);

    const handleCancelVisitManagementDetailClick = useCallback(async () => {
        if (visitData) {
            try {
                await CancelVisitMutation({
                    variables: {
                        data: {
                            id: visitData.id,
                        }

                    },
                });
                handleBackClick();
            } catch (error) { }
        }
    }, [visitData, handleBackClick, CancelVisitMutation]);



    return (
        <ScrollView>
            <View style={VisitManagementPageStyles.ViewParent} testID="data-VisitManagementDetail">
                <View style={VisitManagementPageStyles.ViewTabs}>
                    <View>
                        <View style={{ flex: 1, marginRight: 2 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'VisitManagementDetail.backButton',
                                    defaultMessage: 'Back',
                                })}
                                onPress={handleBackClick}
                            />
                        </View>
                    </View>
                </View>
                {retrievedVisitManagementDetailError &&
                    (
                        <View style={VisitManagementPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                            <Message
                                type="negative"
                                header={intl.formatMessage({
                                    id: 'VisitManagementDetail.VisitManagementDetailErrorMessage',
                                    defaultMessage: 'Could not load detail for the storage Item Type - please try again',
                                })}
                                content={retrievedVisitManagementDetailError.message}
                                testID="data-VisitManagementDetailErrorMessage"
                            />
                        </View>)}
                {CancelVisitManagementDetailMutationLoading &&
                    (<View testID="data-cancelVisitManagementDetailMutationLoading">
                        <Text>
                            <FormattedMessage id="VisitManagementDetail.cancelLoading" defaultMessage="Loading..." />
                        </Text>
                    </View>)}
                {CancelVisitManagementDetailMutationError &&
                    (
                        <View style={VisitManagementPageStyles.ViewErrorMessageParent} testID="data-cancelErrorMessage">
                            <Message
                                type="negative"
                                header={intl.formatMessage({
                                    id: 'VisitManagementDetail.cancelVisitManagementDetailMutationError',
                                    defaultMessage: 'Only PENDING Or CREATED Visit Status can be Canceled - please try again',
                                })}
                                content={CancelVisitManagementDetailMutationError.message}
                                testID="data-cancelVisitManagementDetailMutationError"
                            />
                        </View>)}

                {UpdateVisitManagementDetailMutationError &&
                    (<View style={VisitManagementPageStyles.ViewErrorMessageParent} testID="data-errorMessage">
                        <Message
                            type="negative"
                            header={intl.formatMessage({
                                id: 'VisitManagementDetail.VisitManagementDetailUpdateErrorMessage',
                                defaultMessage: 'Could not update storage Item Type detail - please try again',
                            })}
                            content={UpdateVisitManagementDetailMutationError.message}
                            testID="data-VisitManagementDetailUpdateErrorMessage"
                        />
                    </View>)}
                {(retrievedVisitManagementDetailLoading || UpdateVisitManagementDetailMutationLoading) &&
                    (<View testID="data-VisitManagementDetailItemLoading">
                        <Text>
                            <FormattedMessage id="data.errorLoading" defaultMessage="Loading..." />
                        </Text>
                    </View>)}

                <>
                    <View style={VisitManagementPageStyles.ViewChild}>
                        <View style={VisitManagementPageStyles.ViewContentParent}>
                            <View style={VisitManagementPageStyles.ViewTitleParent}>
                                <Text style={VisitManagementPageStyles.TextTitle} testID="data-VisitManagementDetailTitle">
                                    {`${intl.formatMessage({
                                        id: 'VisitManagementDetail.id',
                                        defaultMessage: 'Visit Details:',
                                    })}${''}`}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={VisitManagementPageStyles.ViewTabs}>
                        <View>
                            <View style={{ flex: 1, marginRight: 10 }}>
                                <Button
                                    title={intl.formatMessage({
                                        id: 'VisitManagementDetail.Cancel',
                                        defaultMessage: 'Cancel Visit',
                                    })}
                                    onPress={handleCancelVisitManagementDetailClick}
                                />
                            </View>
                        </View>
                    </View>

                    {visitData && (
                        <View style={VisitManagementPageStyles.ViewChild}>

                            <ScheduleWindowSelectionPage
                                newVisitDetails={{
                                    ...visitData, startTime: selectedScheduleWindow?.startTime ?? visitData?.startTime
                                    , endTime: selectedScheduleWindow?.endTime ?? visitData?.endTime
                                } as VisitManagementDetailFragment}
                                onClearNewVisitDate={() => null}
                                onScheduleWindowSelection={handleScheduleWindowSelection}
                                zip={visitData?.customerAddress?.zip || ''}
                            />
                        </View>
                    )
                    }
                    <View style={VisitManagementPageStyles.ViewTabs}>
                        <View>
                            <View style={{ flex: 1, marginRight: 10 }}>
                                <Button
                                    title={intl.formatMessage({
                                        id: 'VisitManagementDetail.Save',
                                        defaultMessage: 'Save Visit',
                                    })}
                                    onPress={() => handleSaveVisitManagementDetailClick()}
                                />
                            </View>
                        </View>
                    </View>
                </>
            </View>

        </ScrollView >
    );
};

const VisitManagementPageStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },

    ViewChild: {
        display: 'flex',
        flexDirection: 'row',
    },
    ViewImageParent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    RouteImage: {
        height: 40,
        width: 40,
        resizeMode: 'stretch',
    },
    ViewContentParent: {
        paddingHorizontal: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ViewTitleParent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    ViewTitleChild: {},
    TextTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    ViewDescription: {
        marginTop: 10,
        fontWeight: 'bold',
    },
    TextDescription: { fontSize: 14 },
    ViewStatus: {},
    TextStatus: {
        fontSize: 12,
        fontWeight: '700',
    },
    ViewDate: {},
    TextDate: {
        fontSize: 12,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
    TextContainerItem: {
        marginVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
    ViewImage: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
