import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Inventory } from './Inventory';
import { InventoryContainerDetail } from './Container/InventoryContainerDetail';
import { InventoryContainerItemDetail } from './Item/InventoryContainerItemDetail';
import { InventoryItemDetail } from './Item/InventoryItemDetail';

type InventoryRoutesUrlParams = {
    item?: string;
};

type InventoryRouteProps = React.ComponentPropsWithoutRef<typeof Inventory>;

export const InventoryRoutes: React.FC<InventoryRouteProps> = ({ onScheduleVisit }) => {
    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${url}/container/:item`} exact>
                <InventoryContainerDetail />
            </Route>

            <Route path={`${url}/item/container/:item`} exact>
                <InventoryContainerItemDetail />
            </Route>

            <Route path={`${url}/item/:item`} exact>
                <InventoryItemDetailRoute />
            </Route>

            <Route path={`${url}`}>
                <Inventory onScheduleVisit={onScheduleVisit} />
            </Route>
        </Switch>
    );
};

const InventoryItemDetailRoute: React.FC<{}> = () => {
    const { params = {} } = useRouteMatch();
    const { item: itemId } = params as InventoryRoutesUrlParams;
    return <InventoryItemDetail itemId={itemId ?? ''} />
};