import React, { useState } from 'react';

import { View, Text, StyleSheet } from 'react-native';
import { TextInput, Message, ErrorMessage, Button } from '@valet/ui-components';
import { CustomerPaymentMethod } from '../../Pages/Request/Components/PaymentSelection/PaymentSelection';
import { FormattedMessage, useIntl } from 'react-intl';

export type PaymentMethodInput = {
    description: string;
};

type NewPaymentProps = {
    onSuccessfulSubmit: (paymentMethod: CustomerPaymentMethod) => void;
};

export const NewPayment: React.FC<NewPaymentProps> = ({ onSuccessfulSubmit }) => {
    const intl = useIntl();

    const [input, setInput] = useState<PaymentMethodInput>({ description: '' });
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);

    const handleInputChange = (value: string): void => {
        setErrorMessage(undefined);

        const updatedInput: PaymentMethodInput = {
            ...input,
            description: value,
        };
        return setInput(updatedInput);
    };

    const onSubmitPress = (): void => {
        setErrorMessage(undefined);

        if (input.description === '') {
            return setErrorMessage({
                header: '',
                content: intl.formatMessage({
                    id: 'newPayment.errorProvideADescription',
                    defaultMessage: 'Please provide a description.',
                }),
                type: 'warning',
            });
        } else {
            return onSuccessfulSubmit({ id: '', description: input.description });
        }
    };

    return (
        <View testID="data-newPayment">
            <Text style={newPaymentPageStyles.TextHeader}>
                <FormattedMessage
                    id="newPayment.newPaymentMethodHeader"
                    defaultMessage="New Payment Method"
                />
            </Text>

            {errorMessage && (
                <Message errorMessage={errorMessage} testID="data-messagePaymentError" />
            )}

            <View style={newPaymentFieldsGroupStyles.ViewParent}>
                <View style={newPaymentFieldsGroupStyles.ViewFields}>
                    <TextInput
                        type="text"
                        onChange={handleInputChange}
                        ariaLabel={intl.formatMessage({
                            id: 'newPayment.descriptionLabel',
                            defaultMessage: 'Payment method description',
                        })}
                        value={input.description}
                        placeholder={intl.formatMessage({
                            id: 'newPayment.descriptionLabel',
                            defaultMessage: 'Payment method description',
                        })}
                    />
                </View>
            </View>

            <Button
                title={intl.formatMessage({
                    id: 'newPayment.submitPaymentMethod',
                    defaultMessage: 'Submit Payment Method',
                })}
                onPress={onSubmitPress}
                testID="data-submitPaymentMethodButton"
            />
        </View>
    );
};

const newPaymentPageStyles = StyleSheet.create({
    TextHeader: {
        fontSize: 20,
        fontWeight: '700',
    },
});

const newPaymentFieldsGroupStyles = StyleSheet.create({
    ViewParent: {
        marginTop: 5,
        marginBottom: 5,
        width: '100%',
        flexDirection: 'row',
        alignContent: 'space-between',
    },
    ViewFields: {
        flex: 1,
    },
});
