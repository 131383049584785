import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { FormattedMessage, useIntl } from 'react-intl';

import { View, Text, StyleSheet } from 'react-native';
import { TextInput, Button, Message, ErrorMessage } from '@valet/ui-components';

import { HandleAuthFormInputChange, HandleAuthFormSubmit } from './AuthTypes';

type SignInProps = {
    handleSignInAttempt: (email: string, password: string) => any; // eslint-disable-line @typescript-eslint/no-explicit-any
    handleNewPasswordRequired: (user: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
    handleRedirectForgotPassword: () => void;
    handleRedirectSignUp: () => void;
};

export const SignIn: React.FC<SignInProps> = ({
    handleSignInAttempt,
    handleNewPasswordRequired,
    handleRedirectForgotPassword,
    handleRedirectSignUp,
}) => {
    const intl = useIntl();

    type UserDetails = {
        email: string;
        password: string;
    };

    const [userDetails, setUserDetails] = useState<UserDetails>({
        email: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);

    const handleEmailInputChange: HandleAuthFormInputChange = (value: string) => {
        setUserDetails({ ...userDetails, email: value });
    };

    const handlePasswordInputChange: HandleAuthFormInputChange = (value: string) => {
        setUserDetails({ ...userDetails, password: value });
    };

    const handleSignInFormSubmit: HandleAuthFormSubmit = async () => {
        setErrorMessage({
            content: intl.formatMessage({
                id: 'authentication.loading',
                defaultMessage: 'Loading...',
            }),
            type: 'info',
            header: '',
        });

        const { email, password } = userDetails;

        if (email === '' || password === '') {
            enum UserKeys {
                email = 'email',
                password = 'password',
            }

            interface TmpUserDetails {
                [key: string]: UserKeys[keyof UserKeys];
            }

            const tmpUserDetails: TmpUserDetails = userDetails;
            const userKeys: string[] = Object.keys(tmpUserDetails);
            let emptyField;

            for (let i = 0; i < userKeys.length; i++) {
                if (tmpUserDetails[userKeys[i]] === '') {
                    emptyField = userKeys[i];
                    break;
                }
            }

            switch (emptyField) {
                case 'email':
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorEnterEmail',
                            defaultMessage: 'Please enter your email address',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
                case 'password':
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorEnterPassword',
                            defaultMessage: 'Please enter your password',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
                default:
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorFillInAllFields',
                            defaultMessage: 'Please fill in all the fields.',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
            }
        }

        try {
            const res = await handleSignInAttempt(email, password);
            if (res.challengeName) {
                if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    handleNewPasswordRequired(res);
                }
            }
        } catch (err) {
            setErrorMessage({
                ...errorMessage,
                content: err.message,
                type: 'negative',
                header: intl.formatMessage({
                    id: 'authentication.errorHeader',
                    defaultMessage: 'Error',
                }),
            });
        }
    };

    return (
        <View style={signInStyles.ViewParent} testID="data-signInPage">
            <View style={signInStyles.ViewForm} testID="data-signInForm">
                <Text style={signInStyles.TextHeader}>
                    <FormattedMessage
                        id="authentication.signInHeader"
                        defaultMessage="Sign in to the Valet Application"
                    />
                </Text>

                {errorMessage && (
                    <View style={signInStyles.ViewErrorMessage}>
                        <Message errorMessage={errorMessage} />
                    </View>
                )}

                <View style={signInStyles.ViewFormGroup}>
                    <Text>
                        <FormattedMessage id="authentication.email" defaultMessage="Email" />
                    </Text>

                    <TextInput
                        type="text"
                        testID="data-emailInput"
                        onChange={handleEmailInputChange}
                        ariaLabel={intl.formatMessage({
                            id: 'authentication.email',
                            defaultMessage: 'Email',
                        })}
                        value={userDetails.email}
                    />
                </View>

                <View style={signInStyles.ViewFormGroup}>
                    <Text>
                        <FormattedMessage id="authentication.password" defaultMessage="Password" />
                    </Text>

                    <TextInput
                        type="password"
                        testID="data-passwordInput"
                        onChange={handlePasswordInputChange}
                        ariaLabel={intl.formatMessage({
                            id: 'authentication.password',
                            defaultMessage: 'Password',
                        })}
                        value={userDetails.password}
                    />
                </View>

                <View style={signInStyles.ViewFormGroup}>
                    <Button
                        title={intl.formatMessage({
                            id: 'authentication.signIn',
                            defaultMessage: 'Sign In',
                        })}
                        onPress={handleSignInFormSubmit}
                        testID="data-submitButton"
                    />
                </View>

                <View style={signInStyles.ViewRedirectionLinks}>
                    <Button
                        title={intl.formatMessage({
                            id: 'authentication.forgotPasswordLink',
                            defaultMessage: 'Forgot your password?',
                        })}
                        appearance="ghost"
                        onPress={handleRedirectForgotPassword}
                    />

                    <Button
                        title={intl.formatMessage({
                            id: 'authentication.signUpLink',
                            defaultMessage: "Don't have an account? Create one now.",
                        })}
                        appearance="ghost"
                        onPress={handleRedirectSignUp}
                    />
                </View>

                <View style={signInStyles.ViewDivider}></View>

                <View style={signInStyles.ViewSocialSignIn}>
                    <Text style={signInStyles.TextHeader}>
                        <FormattedMessage
                            id="authentication.signInWithSocialHeader"
                            defaultMessage="Sign in with your social account"
                        />
                    </Text>

                    <GoogleButton />
                    <FacebookButton />
                </View>
            </View>
        </View>
    );
};

export const authStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    ViewForm: {
        width: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    ViewErrorMessage: {
        marginTop: 5,
    },
    ViewFormGroup: {
        paddingTop: 5,
        paddingBottom: 5,
    },
    ViewRedirectionLinks: {},
    TextHeader: {
        fontSize: 16,
    },
    ViewDivider: {
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.1)',
    },
    ViewSocialSignIn: {},
});

const signInStyles = authStyles;

type GoogleButtonProps = {};

export const GoogleButton: React.FC<GoogleButtonProps> = () => {
    const intl = useIntl();

    return (
        <View style={socialSignInStyles.ViewParent}>
            <Button
                testID="data-googleButton"
                onPress={() =>
                    Auth.federatedSignIn({
                        provider: CognitoHostedUIIdentityProvider.Google,
                    })
                }
                title={intl.formatMessage({
                    id: 'authentication.google',
                    defaultMessage: 'Sign in with Google',
                })}
                appearance="outline"
            />
        </View>
    );
};

type FacebookButtonProps = {};

export const FacebookButton: React.FC<FacebookButtonProps> = () => {
    const intl = useIntl();

    return (
        <View style={socialSignInStyles.ViewParent}>
            <Button
                testID="data-facebookButton"
                onPress={() =>
                    Auth.federatedSignIn({
                        provider: CognitoHostedUIIdentityProvider.Facebook,
                    })
                }
                title={intl.formatMessage({
                    id: 'authentication.facebook',
                    defaultMessage: 'Sign in with Facebook',
                })}
                appearance="outline"
            />
        </View>
    );
};

const socialSignInStyles = StyleSheet.create({
    ViewParent: {
        marginVertical: 5,
    },
});
