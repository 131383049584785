import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';

import { View, Text, FlatList, Image, StyleSheet } from 'react-native';
import { Button, Card, Checkbox } from '@valet/ui-components';
import { InventorySelectedItemsType, inventoryStyles } from '../Inventory';
import { INVENTORY_STORAGE_CONTAINER_FRAGMENT } from './InventoryContainerDetail';
import {
    useRetrieveStorageContainersQuery,
    InventoryStorageContainerFragment,
} from '../../../../schema-types';

export const RETRIEVE_STORAGE_CONTAINERS_QUERY = gql`
    query RetrieveStorageContainers($pageSize: Int, $cursor: String) {
        currentCustomer {
            id
            storageContainers(
                filter: {
                    pageSize: $pageSize
                    after: $cursor
                    conditions: { field: "status", value: "IN_USE", operator: Equal }
                }
            ) {
                edges {
                    cursor
                    node {
                        id
                        ...InventoryStorageContainer
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${INVENTORY_STORAGE_CONTAINER_FRAGMENT}
`;

export const inventoryContainersPageSize = 50;

type InventoryContainersProps = {
    searchValue: string;
    selectedItems: InventorySelectedItemsType;
    onEmptyContainerSelectionChange: (item: InventoryStorageContainerFragment) => void;
    onEditStorageContainerPress: (id: string) => void;
};

export const InventoryContainers: React.FC<InventoryContainersProps> = ({
    searchValue,
    selectedItems,
    onEmptyContainerSelectionChange,
    onEditStorageContainerPress,
}) => {
    const { data, loading, error, fetchMore } = useRetrieveStorageContainersQuery({
        variables: { pageSize: inventoryContainersPageSize, cursor: '' },
    });

    const containers: InventoryStorageContainerFragment[] =
        data?.currentCustomer?.storageContainers.edges.map((edge) => edge.node) ?? [];

    const hasNextPage = data?.currentCustomer?.storageContainers?.pageInfo?.hasNextPage ?? true;
    const endCursor = data?.currentCustomer?.storageContainers?.pageInfo?.cursor?.afterCursor;
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const handleEndOfPageScroll = useCallback(() => {
        if (hasNextPage) {
            setIsFetching(true);
            fetchMore({
                variables: {
                    pageSize: inventoryContainersPageSize,
                    cursor: `${endCursor}`,
                },
            }).then(() => setIsFetching(false));
        }
    }, [endCursor, hasNextPage, fetchMore]);

    useEffect(() => {
        const windowHeight = window.innerHeight;
        const pageElement = document.querySelector('[data-testid="data-inventoryPage"]');

        if (pageElement) {
            const pageWindow = parseInt(
                window.getComputedStyle(pageElement).height.replace('px', ''),
            );

            if (windowHeight > pageWindow) {
                if (data && !isFetching) {
                    handleEndOfPageScroll();
                }
            }
        }
    }, [data, handleEndOfPageScroll, isFetching]);

    const filteredContainers: InventoryStorageContainerFragment[] = useMemo(() => {
        if (searchValue === '') {
            return containers;
        } else {
            const filteredResults: InventoryStorageContainerFragment[] = containers.filter(
                (node: InventoryStorageContainerFragment) =>
                    node.containerType?.description
                        .toUpperCase()
                        .includes(searchValue.toUpperCase()),
            );

            return filteredResults;
        }
    }, [searchValue, containers]);

    if (loading) {
        return (
            <View testID="data-inventoryContainerPage">
                <Text>
                    <FormattedMessage
                        id="inventoryContainers.loading"
                        defaultMessage="Loading..."
                    />
                </Text>
            </View>
        );
    }

    if (error) {
        return (
            <View testID="data-inventoryContainerPage">
                <Text>
                    <FormattedMessage
                        id="inventoryContainers.error"
                        defaultMessage="There was an error - please refresh this page."
                    />
                </Text>
            </View>
        );
    }

    const emptyStorageContainers = filteredContainers.filter(
        (container) => !container.assignedStorageItem,
    );

    return (
        <View style={inventoryStyles.ViewGroupStatus}>
            <Text style={inventoryStyles.TextGroupStatusHeader}>
                <FormattedMessage
                    id="inventory.storageContainers"
                    defaultMessage="Empty/Unused Storage Containers"
                />
            </Text>

            {emptyStorageContainers.length > 0 ? (
                <FlatList
                    data={emptyStorageContainers}
                    renderItem={({ item }) => (
                        <EmptyStorageContainer
                            item={item}
                            onEditStorageContainerPress={onEditStorageContainerPress}
                            selected={selectedItems.emptyContainers.includes(item)}
                            onEmptyContainerSelectionChange={onEmptyContainerSelectionChange}
                            key={item.id}
                        />
                    )}
                    keyExtractor={(item) => item.id.toString()}
                />
            ) : (
                <FormattedMessage id="inventory.noItemsToDisplay" defaultMessage="None" />
            )}
        </View>
    );
};

type EmptyStorageContainerProps = {
    item: InventoryStorageContainerFragment;
    onEditStorageContainerPress: (id: string) => void;
    onEmptyContainerSelectionChange: (item: InventoryStorageContainerFragment) => void;
    selected: boolean;
};

const EmptyStorageContainer: React.FC<EmptyStorageContainerProps> = ({
    item,
    onEditStorageContainerPress,
    onEmptyContainerSelectionChange,
    selected,
}) => {
    const intl = useIntl();

    return (
        <Card
            disabled
            footer={() => (
                <View style={inventoryContainerStyles.ViewActions}>
                    <View style={[inventoryContainerStyles.ViewActionButton, { marginLeft: 3 }]}>
                        <Button
                            title={intl.formatMessage({
                                id: 'inventory.edit',
                                defaultMessage: 'Edit',
                            })}
                            onPress={() => onEditStorageContainerPress(item.id)}
                            size="small"
                        />
                    </View>
                </View>
            )}
            style={inventoryContainerStyles.CardParent}
        >
            <View
                style={{ flex: 1, display: 'flex', flexDirection: 'row' }}
                testID="data-inventoryItem"
            >
                <View style={inventoryContainerStyles.ViewCheckbox}>
                    <Checkbox
                        checked={selected}
                        onValueChange={(value: boolean) => onEmptyContainerSelectionChange(item)}
                        testID="data-inventoryItemCheckbox"
                    />
                </View>

                <View style={inventoryContainerStyles.ViewImage}>
                    <Image
                        style={{ height: 80, width: 120 }}
                        source={{
                            uri:
                                'https://www.containerstore.com/catalogimages/356410/10008758-our-accessory-box.jpg',
                        }}
                    />
                </View>

                <View style={inventoryContainerStyles.ViewInfo}>
                    <View style={inventoryContainerStyles.ViewDetails}>
                        <Text>{item.containerType?.description}</Text>

                        <Text>{item.barcode}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const inventoryContainerStyles = StyleSheet.create({
    CardParent: {
        marginHorizontal: 2,
        marginVertical: 5,
    },
    ViewCheckbox: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ViewImage: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ViewDetails: {
        paddingVertical: 5,
    },
    ViewInfo: {
        flex: 1,
        paddingHorizontal: 5,
    },
    ViewActions: {
        paddingVertical: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    ViewActionButton: {
        flex: 1,
    },
});
