import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { gql } from '@apollo/client';

import { View, Text, Image, FlatList, StyleSheet } from 'react-native';
import { Button, Counter, TextInput } from '@valet/ui-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { OrderLoader } from '../OrderLoader';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    usePickupStorageItemTypesQuery,
    PickupItemSelectionStorageItemTypeFragmentFragment,
} from '../../../../../schema-types';

export enum PropertyType {
    STRING_SINGLE_LINE,
    STRING_MULTI_LINE,
    LIST_DROPDOWN,
    LIST_CHECKBOX,
    LIST_RADIO,
    BOOLEAN,
    NUMBER_INTEGER,
    NUMBER_DECIMAL,
}

type ItemSelectionPageProps = {
    onGoToNextPage: () => void;
    selectedItems: Record<string, { storageItemTypeName: string; quantity: number }>;
    onCounterChange: (id: string, name: string, value: number, container: boolean) => void;
};

const PICKUP_ITEM_SELECTION_STORAGE_ITEM_TYPE_FRAGMENT = gql`
    fragment PickupItemSelectionStorageItemTypeFragment on StorageItemType {
        id
        name
        description
        image
        maximumRequestQuantity
        containerType {
            id
        }
    }
`;

export const PICKUP_STORAGE_ITEM_TYPES_QUERY = gql`
    query PickupStorageItemTypes($cursor: String, $pageSize: Int) {
        serviceProvider {
            id
            storageItemTypes(filter: { pageSize: $pageSize, after: $cursor }) {
                edges {
                    cursor
                    node {
                        id
                        ...PickupItemSelectionStorageItemTypeFragment
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${PICKUP_ITEM_SELECTION_STORAGE_ITEM_TYPE_FRAGMENT}
`;

export const storageItemsPageSize = 100;

type StorageItemsData = {
    storageItems: PickupItemSelectionStorageItemTypeFragmentFragment[];
    hasNextPage: boolean;
    endCursor: string;
};

export const ItemSelectionPage: React.FC<ItemSelectionPageProps> = ({
    onGoToNextPage,
    selectedItems,
    onCounterChange,
}) => {
    const intl = useIntl();

    const { data, loading, error, fetchMore } = usePickupStorageItemTypesQuery({
        variables: { pageSize: storageItemsPageSize, cursor: '' },
        errorPolicy: 'all',
    });

    const serviceProviderData = data?.serviceProvider === null ? undefined : data?.serviceProvider;

    const storageItemEdges = serviceProviderData?.storageItemTypes.edges ?? [];

    const storageItemsData = useMemo(() => {
        return {
            storageItems: storageItemEdges.map((storageItemEdge) => storageItemEdge?.node),
            hasNextPage: serviceProviderData?.storageItemTypes.pageInfo.hasNextPage ?? false,
            endCursor: serviceProviderData?.storageItemTypes.pageInfo.cursor.afterCursor ?? '',
        };
    }, [serviceProviderData, storageItemEdges]);

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const handleLoadMoreItems = useCallback((): void => {
        if (storageItemsData.hasNextPage && !isFetching) {
            setIsFetching(true);

            fetchMore({
                variables: {
                    pageSize: storageItemsPageSize,
                    cursor: storageItemsData.endCursor,
                },
            }).then(() => {
                setIsFetching(false);
            });
        }
    }, [fetchMore, isFetching, storageItemsData.hasNextPage, storageItemsData.endCursor]);

    useEffect(() => {
        if (!error && storageItemsData.hasNextPage) {
            handleLoadMoreItems();
        }
    }, [error, handleLoadMoreItems, storageItemsData]);

    if (error) {
        return (
            <View testID="data-itemSelectionPage">
                <Text>
                    <FormattedMessage
                        id="errorLoading"
                        defaultMessage="There was an error. Please reload this page."
                    />
                </Text>
            </View>
        );
    }

    return (
        <View testID="data-itemSelectionPage">
            <Text style={itemSelectionPageStyles.TextHeader}>
                <FormattedMessage
                    id="pickupRequest.selectItemsHeader"
                    defaultMessage="Select Items For Pickup"
                />
            </Text>

            <Button
                title={intl.formatMessage({
                    id: 'pickupRequest.next',
                    defaultMessage: 'Next',
                })}
                onPress={onGoToNextPage}
                disabled={Object.keys(selectedItems).length === 0}
                testID="data-nextButton"
            />

            {loading ? (
                <OrderLoader />
            ) : (
                <StorageItemsPopulated
                    storageItemsData={storageItemsData}
                    selectedItems={selectedItems}
                    onCounterChange={onCounterChange}
                    onLoadMore={handleLoadMoreItems}
                />
            )}
        </View>
    );
};

type StorageItemsPopulatedProps = {
    storageItemsData: StorageItemsData;
    selectedItems: Record<string, { storageItemTypeName: string; quantity: number }>;
    onCounterChange: (id: string, name: string, value: number, container: boolean) => void;
    onLoadMore: () => void;
};

const StorageItemsPopulated: React.FC<StorageItemsPopulatedProps> = ({
    storageItemsData,
    selectedItems,
    onCounterChange,
    onLoadMore,
}) => {
    const intl = useIntl();
    const [searchValue, setSearchValue] = useState<string>('');

    const filteredStorageItems = useMemo(() => {
        return storageItemsData.storageItems.filter(
            (storageItem: PickupItemSelectionStorageItemTypeFragmentFragment) =>
                storageItem.name.includes(searchValue),
        );
    }, [searchValue, storageItemsData.storageItems]);

    if (storageItemsData.storageItems.length === 0) {
        return (
            <Text>
                <FormattedMessage
                    id="pickupRequest.noStorageItems"
                    defaultMessage="No items to show."
                />
            </Text>
        );
    }

    return (
        <>
            <View style={{ marginVertical: 5 }}>
                <TextInput
                    type="text"
                    onChange={(value: string): void => setSearchValue(value)}
                    ariaLabel={intl.formatMessage({
                        id: 'pickupRequest.itemSelectionSearchForItem',
                        defaultMessage: 'Search for item',
                    })}
                    value={searchValue}
                    placeholder={intl.formatMessage({
                        id: 'pickupRequest.itemSelectionSearchForItem',
                        defaultMessage: 'Search for item',
                    })}
                    testID="data-searchbox"
                />
            </View>

            {searchValue === '' && (
                <StorageItemsList
                    storageItems={storageItemsData.storageItems}
                    selectedItems={selectedItems}
                    onCounterChange={onCounterChange}
                    onLoadMore={onLoadMore}
                    hasMore={storageItemsData.hasNextPage}
                />
            )}

            {searchValue !== '' && storageItemsData.hasNextPage && (
                <Text>
                    <FormattedMessage
                        id="pickupRequest.loadingAllStorageItems"
                        defaultMessage="Loading all storage item types..."
                    />
                </Text>
            )}

            {searchValue !== '' &&
                !storageItemsData.hasNextPage &&
                filteredStorageItems.length === 0 && (
                    <Text>
                        <FormattedMessage
                            id="pickupRequest.noStorageItemFilterMatch"
                            defaultMessage="No items match search criteria"
                        />
                    </Text>
                )}

            {searchValue !== '' &&
                !storageItemsData.hasNextPage &&
                filteredStorageItems.length > 0 && (
                    <FlatList
                        data={filteredStorageItems}
                        renderItem={({ item: storageItem }) => (
                            <StorageItem
                                details={storageItem}
                                quantity={
                                    selectedItems[storageItem.id]
                                        ? selectedItems[storageItem.id].quantity
                                        : 0
                                }
                                onCounterChange={onCounterChange}
                                key={storageItem.id}
                            />
                        )}
                        keyExtractor={(storageItem) => storageItem.id}
                    />
                )}
        </>
    );
};

type StorageItemsListProps = {
    storageItems: PickupItemSelectionStorageItemTypeFragmentFragment[];
    selectedItems: Record<string, { storageItemTypeName: string; quantity: number }>;
    onCounterChange: (id: string, name: string, value: number, container: boolean) => void;
    onLoadMore: () => void;
    hasMore: boolean;
};

const StorageItemsList: React.FC<StorageItemsListProps> = ({
    storageItems,
    selectedItems,
    onCounterChange,
    onLoadMore,
    hasMore,
}) => {
    return (
        <InfiniteScroll
            dataLength={storageItems.length}
            next={onLoadMore}
            hasMore={hasMore}
            loader={
                <Text style={itemSelectionPageStyles.TextHeader}>
                    <FormattedMessage
                        id="pickupRequest.loadingMoreOptions"
                        defaultMessage="Loading more options..."
                    />
                </Text>
            }
            scrollThreshold={1}
        >
            <FlatList
                data={storageItems}
                renderItem={({ item: storageItem }) => (
                    <StorageItem
                        details={storageItem}
                        quantity={
                            selectedItems[storageItem.id]
                                ? selectedItems[storageItem.id].quantity
                                : 0
                        }
                        onCounterChange={onCounterChange}
                        key={storageItem.id}
                    />
                )}
                keyExtractor={(storageItem) => storageItem.id}
            />
        </InfiniteScroll>
    );
};

type StorageItemProps = {
    details: PickupItemSelectionStorageItemTypeFragmentFragment;
    quantity: number;
    onCounterChange: (id: string, name: string, value: number, container: boolean) => void;
};

const StorageItem: React.FC<StorageItemProps> = ({ details, quantity, onCounterChange }) => {
    const { id, name, description, image, containerType } = details;

    return (
        <View style={storageItemStyles.ViewParent} testID="data-storageItem">
            <View style={storageItemStyles.ViewItemImage}>
                {image ? (
                    <Image
                        style={storageItemStyles.ImageItemImage}
                        source={{ uri: image }}
                        resizeMode="contain"
                    />
                ) : (
                    <Text style={storageItemStyles.TextItemImage}>
                        <FormattedMessage
                            id="pickupRequest.storageItemNoLoadedImage"
                            defaultMessage="No image loaded."
                        />
                    </Text>
                )}
            </View>

            <View style={storageItemStyles.ViewItemInfo}>
                {/* TODO: Internationalization */}
                <Text style={{ fontWeight: '600' }}>
                    <FormattedMessage
                        id="pickupRequest.storageItemName"
                        defaultMessage="{name}"
                        values={{ name: name }}
                    />
                </Text>
                <Text>
                    <FormattedMessage
                        id="pickupRequest.storageItemDescription"
                        defaultMessage="{description}"
                        values={{ description: description }}
                    />
                </Text>
            </View>

            <View style={storageItemStyles.ViewItemCounter}>
                <Counter
                    value={quantity}
                    onChange={(value) => onCounterChange(id, name, value, !!containerType)}
                    min={0}
                />
            </View>
        </View>
    );
};

const itemSelectionPageStyles = StyleSheet.create({
    TextHeader: {
        fontSize: 20,
        fontWeight: '700',
    },
});

const storageItemStyles = StyleSheet.create({
    ViewParent: {
        paddingTop: 5,
        paddingBottom: 5,
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(0, 0, 0, 0.3)',
    },
    ViewItemImage: {
        paddingRight: 10,
    },
    ImageItemImage: {
        height: 50,
        width: 50,
    },
    TextItemImage: {
        height: 50,
        width: 50,
        flexWrap: 'wrap',
        textAlign: 'center',
    },
    ViewItemInfo: {
        flex: 3,
        paddingRight: 10,
    },
    ViewItemCounter: {
        flex: 2,
    },
});
