import React, { useState } from 'react';

import { View, Text } from 'react-native';
import { TextInput, Button, Message, ErrorMessage } from '@valet/ui-components';

import { HandleAuthFormInputChange, HandleAuthFormSubmit } from './AuthTypes';
import { authStyles } from './SignIn';
import { FormattedMessage, useIntl } from 'react-intl';

type ConfirmSignUpProps = {
    handleConfirmSignUpAttempt: (email: string, code: string) => void;
    handleRedirectResendCode: () => void;
    handleRedirectSignUp: () => void;
};

export const ConfirmSignUp: React.FC<ConfirmSignUpProps> = ({
    handleConfirmSignUpAttempt,
    handleRedirectResendCode,
    handleRedirectSignUp,
}) => {
    const intl = useIntl();

    type UserDetails = {
        email: string;
        code: string;
    };

    const [userDetails, setUserDetails] = useState<UserDetails>({
        email: '',
        code: '',
    });
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);

    const handleEmailInputChange: HandleAuthFormInputChange = (value: string) => {
        setUserDetails({ ...userDetails, email: value });
    };

    const handleCodeInputChange: HandleAuthFormInputChange = (value: string) => {
        setUserDetails({ ...userDetails, code: value });
    };

    const handleConfirmSignUpFormSubmit: HandleAuthFormSubmit = async () => {
        setErrorMessage({ content: '', type: 'info', header: '' });

        const { email, code } = userDetails;

        if (email === '' || code === '') {
            enum UserKeys {
                email = 'email',
                code = 'code',
            }

            interface TmpUserDetails {
                [key: string]: UserKeys[keyof UserKeys];
            }

            const tmpUserDetails: TmpUserDetails = userDetails;
            const userKeys: string[] = Object.keys(tmpUserDetails);
            let emptyField;

            for (let i = 0; i < userKeys.length; i++) {
                if (tmpUserDetails[userKeys[i]] === '') {
                    emptyField = userKeys[i];
                    break;
                }
            }

            switch (emptyField) {
                case 'email':
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorEnterEmail',
                            defaultMessage: 'Please enter your email address',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
                case 'code':
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorEnterVerificationCode',
                            defaultMessage: 'Please enter your verification code',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
                default:
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorFillInAllFields',
                            defaultMessage: 'Please fill in all the fields.',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
            }
        }

        try {
            await handleConfirmSignUpAttempt(email, code);
        } catch (err) {
            setErrorMessage({
                ...errorMessage,
                content: err.message,
                type: 'negative',
                header: 'Error',
            });
        }
    };

    return (
        <View style={confirmSignUpStyles.ViewParent} testID="data-confirmSignUpPage">
            <View style={confirmSignUpStyles.ViewForm} testID="data-confirmSignUpForm">
                <Text style={confirmSignUpStyles.TextHeader}>
                    <FormattedMessage
                        id="authentication.emailVerificationHeader"
                        defaultMessage="Check Your Email For A Verification Code"
                    />
                </Text>

                {errorMessage && (
                    <View style={confirmSignUpStyles.ViewErrorMessage}>
                        <Message errorMessage={errorMessage} />
                    </View>
                )}

                <View style={confirmSignUpStyles.ViewFormGroup}>
                    <Text>
                        <FormattedMessage
                            id="authentication.yourEmail"
                            defaultMessage="Your Email"
                        />
                    </Text>

                    <TextInput
                        type="text"
                        testID="data-emailInput"
                        onChange={handleEmailInputChange}
                        ariaLabel={intl.formatMessage({
                            id: 'authentication.yourEmail',
                            defaultMessage: 'Your Email',
                        })}
                        value={userDetails.email}
                    />
                </View>

                <View style={confirmSignUpStyles.ViewFormGroup}>
                    <Text>
                        <FormattedMessage
                            id="authentication.verificationCode"
                            defaultMessage="Verification Code"
                        />
                    </Text>

                    <TextInput
                        type="text"
                        testID="data-verificationCodeInput"
                        onChange={handleCodeInputChange}
                        ariaLabel={intl.formatMessage({
                            id: 'authentication.verificationCode',
                            defaultMessage: 'Verification Code',
                        })}
                        value={userDetails.code}
                    />
                </View>

                <View style={confirmSignUpStyles.ViewFormGroup}>
                    <Button
                        title={intl.formatMessage({
                            id: 'authentication.verifyEmail',
                            defaultMessage: 'Verify Email',
                        })}
                        onPress={handleConfirmSignUpFormSubmit}
                    />
                </View>

                <View style={confirmSignUpStyles.ViewRedirectionLinks}>
                    <Button
                        title={intl.formatMessage({
                            id: 'authentication.resendVerificationCodeLink',
                            defaultMessage: 'Resend Code',
                        })}
                        appearance="ghost"
                        onPress={handleRedirectResendCode}
                        testID="data-submitButton"
                    />

                    <Button
                        title={intl.formatMessage({
                            id: 'authentication.signUpLink',
                            defaultMessage: "Don't have an account? Create one now.",
                        })}
                        appearance="ghost"
                        onPress={handleRedirectSignUp}
                    />
                </View>
            </View>
        </View>
    );
};

const confirmSignUpStyles = authStyles;
