import React, { useContext } from 'react';
import { AuthContext } from './Components/Pages/Auth/AuthFunctions';

import { AuthRoutes } from './Components/Pages/Auth/AuthRoutes';
import { BaseRoutes } from './Components/Pages/BaseRoutes';

type RoutesProps = {};

export const Routes: React.FC<RoutesProps> = () => {
    const { isAuthenticated } = useContext(AuthContext);

    if (!isAuthenticated) {
        return <AuthRoutes />;
    }

    return <BaseRoutes />;
};
