import React from 'react';
import { ScrollView } from 'react-native';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { VisitManagementPage } from './VisitManagement';
import { VisitManagementDetailPage } from './VisitManagementDetailPage';

type VisitManagementRoutesProps = {};

export const VisitManagementRoutes: React.FC<VisitManagementRoutesProps> = () => {
    const { url } = useRouteMatch();
    const history = useHistory();
    const handleVisitManagementSelect = (visitId: string): void => {
        history.push(`/visitManagement/${visitId}/detail`);
    };

    return (
        <ScrollView>
            <Switch>
                <Route path={`${url}/:visitId/detail`} exact>
                    <VisitManagementDetailPage />
                </Route>

                <Route path={`${url}`}>
                    <VisitManagementPage
                        handleVisitManagementSelect={handleVisitManagementSelect}
                    />
                </Route>
            </Switch>
        </ScrollView>
    );
};
