import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Upload: any;
    DateTime: any;
};

export type Mutation = {
    __typename?: 'Mutation';
    _empty?: Maybe<Scalars['String']>;
    addCustomerAddress: AddCustomerAddressPayload;
    addCustomerPaymentMethod: AddCustomerPaymentMethodPayload;
    adminAddBuilding: AdminAddBuildingPayload;
    adminAddEmployee: AdminAddEmployeePayload;
    adminAddLocation: AdminAddLocationsPayload;
    adminAddPeriodToTerritory: AdminAddPeriodToTerritoryPayload;
    adminAddScheduleTemplatePeriod: AdminAddScheduleTemplatePeriodPayload;
    adminAddStorageContainer: AdminAddStorageContainerPayload;
    adminAddStorageContainerType: AdminAddStorageContainerTypePayload;
    adminAddStorageItemType: AdminAddStorageItemTypePayload;
    adminAddStorageItemTypeCategory: AdminAddStorageItemTypeCategoryPayload;
    adminAddTerritory: AdminAddTerritoryPayload;
    adminAddVehicle: AdminAddVehiclePayload;
    adminDeleteScheduleTemplatePeriod: AdminDeleteScheduleTemplatePeriodPayload;
    adminExcludePeriodFromTerritory: AdminExcludePeriodFromTerritoryPayload;
    adminUpdateArea: AdminUpdateAreaPayload;
    adminUpdateBuilding: AdminUpdateBuildingPayload;
    adminUpdateEmployee: AdminUpdateEmployeePayload;
    adminUpdateEmployeePassword: AdminUpdateEmployeePasswordPayload;
    adminUpdateLocation: AdminUpdateLocationPayload;
    adminUpdateScheduleTemplate: AdminUpdateScheduleTemplatePayload;
    adminUpdateScheduleTemplatePeriod: AdminUpdateScheduleTemplatePeriodPayload;
    adminUpdateStorageContainer: AdminUpdateStorageContainerPayload;
    adminUpdateStorageContainerType: AdminStorageContainerTypeload;
    adminUpdateStorageItemType: AdminUpdateStorageItemTypePayload;
    adminUpdateStorageItemTypeCategory: AdminStorageItemTypeCategoryload;
    adminUpdateTerritory: AdminUpdateTerritoryPayload;
    adminUpdateVehicle: AdminUpdateVehiclePayload;
    assignStorageContainer: AssignStorageContainerPayload;
    deliveryCompleteVisit: DeliveryCompleteVisitPayload;
    deliveryDeliverEmptyContainer: DeliveryDeliverEmptyContainerPayload;
    deliveryDeliverItem: DeliveryDeliverItemPayload;
    deliveryPickupEmptyContainer: DeliveryPickupEmptyContainerPayload;
    deliveryPickupItem: DeliveryPickupItemPayload;
    movingProcessItem: MovingProcessItemPayload;
    pickingCompleteList: PickingCompleteListPayload;
    pickingCreateList: PickingCreateListPayload;
    pickingDeleteList: PickingDeleteListPayload;
    pickingExcludeItem: PickingExcludeItemPayload;
    pickingPickItem: PickingPickItemPayload;
    pickingSortItem: PickingSortItemPayload;
    refilingCompleteList: RefilingCompleteListPayload;
    refilingCreateList: RefilingCreateListPayload;
    refilingProcessItem: RefilingProcessItemPayload;
    renameBook?: Maybe<Book>;
    requestAssignContainer: RequestAssignContainerPayload;
    routingAddStops: RoutingAddStopPayload;
    routingCreateRoute: RoutingCreateRoutePayload;
    routingRemoveStop: RoutingRemoveStopPayload;
    routingSetEmployees: RoutingSetEmployeesPayload;
    routingSetVehicle: RoutingSetVehiclePayload;
    routingUpdateStop: RoutingUpdateStopPayload;
    saveVisit: SaveVisitPayload;
    setStorageItemMetadata: SetStorageItemMetadataPayload;
    storageItemAssignBarcode: StorageItemAssignBarcodePayload;
    truckLoadingProcessConfirmRoute: TruckLoadingProcessConfirmRoutePayload;
    truckLoadingProcessEmptyContainers: TruckLoadingProcessEmptyContainersPayload;
    truckLoadingProcessItem: TruckLoadingProcessItemPayload;
    truckUnloadingProcessItem: TruckUnloadingProcessItemPayload;
    unassignStorageContainer: UnassignStorageContainerPayload;
    updateVisitPaymentMethod: UpdateVisitPaymentMethodPayload;
    uploadStorageItemTypeImage: UploadStorageItemTypeImagePayload;
    visitCancelVisit: VisitCancelVisitPayload;
    visitReviewApproveVisit: VisitReviewApproveVisitPayload;
    visitUpdateWindow: VisitUpdateWindowPayload;
};

export type MutationAddCustomerAddressArgs = {
    data: AddCustomerAddressInput;
};

export type MutationAddCustomerPaymentMethodArgs = {
    data: AddCustomerPaymentMethodInput;
};

export type MutationAdminAddBuildingArgs = {
    data: AdminAddBuildingInput;
};

export type MutationAdminAddEmployeeArgs = {
    data: AdminAddEmployeeInput;
};

export type MutationAdminAddLocationArgs = {
    data?: Maybe<Array<AdminAddLocationsInput>>;
};

export type MutationAdminAddPeriodToTerritoryArgs = {
    data: AdminAddPeriodToTerritoryInput;
};

export type MutationAdminAddScheduleTemplatePeriodArgs = {
    data: AdminAddScheduleTemplatePeriodInput;
};

export type MutationAdminAddStorageContainerArgs = {
    data: AdminAddStorageContainerInput;
};

export type MutationAdminAddStorageContainerTypeArgs = {
    data: AdminAddStorageContainerTypeInput;
};

export type MutationAdminAddStorageItemTypeArgs = {
    data: AdminAddStorageItemTypeInput;
};

export type MutationAdminAddStorageItemTypeCategoryArgs = {
    data: AdminAddStorageItemTypeCategoryInput;
};

export type MutationAdminAddTerritoryArgs = {
    data: AdminAddTerritoryInput;
};

export type MutationAdminAddVehicleArgs = {
    data: AdminAddVehicleInput;
};

export type MutationAdminDeleteScheduleTemplatePeriodArgs = {
    data: AdminDeleteScheduleTemplatePeriodInput;
};

export type MutationAdminExcludePeriodFromTerritoryArgs = {
    data: AdminExcludePeriodFromTerritoryInput;
};

export type MutationAdminUpdateAreaArgs = {
    data: AdminUpdateAreaInput;
};

export type MutationAdminUpdateBuildingArgs = {
    data: AdminUpdateBuildingInput;
};

export type MutationAdminUpdateEmployeeArgs = {
    data: AdminUpdateEmployeeInput;
};

export type MutationAdminUpdateEmployeePasswordArgs = {
    data: AdminUpdateEmployeePasswordInput;
};

export type MutationAdminUpdateLocationArgs = {
    data: AdminUpdateLocationInput;
};

export type MutationAdminUpdateScheduleTemplateArgs = {
    data: AdminUpdateScheduleTemplateInput;
};

export type MutationAdminUpdateScheduleTemplatePeriodArgs = {
    data: AdminUpdateScheduleTemplatePeriodInput;
};

export type MutationAdminUpdateStorageContainerArgs = {
    data: AdminUpdateStorageContainerInput;
};

export type MutationAdminUpdateStorageContainerTypeArgs = {
    data: AdminStorageContainerTypeInput;
};

export type MutationAdminUpdateStorageItemTypeArgs = {
    data: AdminStorageItemTypeInput;
};

export type MutationAdminUpdateStorageItemTypeCategoryArgs = {
    data: AdminStorageItemTypeCategoryInput;
};

export type MutationAdminUpdateTerritoryArgs = {
    data: AdminUpdateTerritoryInput;
};

export type MutationAdminUpdateVehicleArgs = {
    data: AdminUpdateVehicleInput;
};

export type MutationAssignStorageContainerArgs = {
    input: AssignStorageContainerInput;
};

export type MutationDeliveryCompleteVisitArgs = {
    data: DeliveryCompleteVisitInput;
};

export type MutationDeliveryDeliverEmptyContainerArgs = {
    data: DeliveryDeliverEmptyContainerInput;
};

export type MutationDeliveryDeliverItemArgs = {
    data: DeliveryDeliverItemInput;
};

export type MutationDeliveryPickupEmptyContainerArgs = {
    data: DeliveryPickupEmptyContainerInput;
};

export type MutationDeliveryPickupItemArgs = {
    data: DeliveryPickupItemInput;
};

export type MutationMovingProcessItemArgs = {
    data: MovingProcessItemInput;
};

export type MutationPickingCompleteListArgs = {
    data: PickingCompleteListInput;
};

export type MutationPickingCreateListArgs = {
    data: PickingCreateListInput;
};

export type MutationPickingDeleteListArgs = {
    data: PickingDeleteListInput;
};

export type MutationPickingExcludeItemArgs = {
    data: PickingExcludeItemInput;
};

export type MutationPickingPickItemArgs = {
    data: PickingPickItemInput;
};

export type MutationPickingSortItemArgs = {
    data: PickingSortItemInput;
};

export type MutationRefilingCompleteListArgs = {
    data: RefilingCompleteListInput;
};

export type MutationRefilingCreateListArgs = {
    data: RefilingCreateListInput;
};

export type MutationRefilingProcessItemArgs = {
    data: RefilingProcessItemInput;
};

export type MutationRenameBookArgs = {
    bookId: Scalars['Int'];
    title: Scalars['String'];
};

export type MutationRequestAssignContainerArgs = {
    data: RequestAssignContainerInput;
};

export type MutationRoutingAddStopsArgs = {
    data: RoutingAddStopInput;
};

export type MutationRoutingCreateRouteArgs = {
    data: RoutingCreateRouteInput;
};

export type MutationRoutingRemoveStopArgs = {
    data: RoutingRemoveStopInput;
};

export type MutationRoutingSetEmployeesArgs = {
    data: RoutingSetEmployeesInput;
};

export type MutationRoutingSetVehicleArgs = {
    data: RoutingSetVehicleInput;
};

export type MutationRoutingUpdateStopArgs = {
    data: RoutingUpdateStopInput;
};

export type MutationSaveVisitArgs = {
    data: SaveVisitInput;
};

export type MutationSetStorageItemMetadataArgs = {
    data: SetStorageItemMetadataInput;
};

export type MutationStorageItemAssignBarcodeArgs = {
    data: StorageItemAssignBarcodeInput;
};

export type MutationTruckLoadingProcessConfirmRouteArgs = {
    data: TruckLoadingProcessConfirmRouteInput;
};

export type MutationTruckLoadingProcessEmptyContainersArgs = {
    data: TruckLoadingProcessEmptyContainersInput;
};

export type MutationTruckLoadingProcessItemArgs = {
    data: TruckLoadingProcessItemInput;
};

export type MutationTruckUnloadingProcessItemArgs = {
    data: TruckUnloadingProcessItemInput;
};

export type MutationUnassignStorageContainerArgs = {
    input: UnassignStorageContainerInput;
};

export type MutationUpdateVisitPaymentMethodArgs = {
    data: UpdateVisitPaymentMethodInput;
};

export type MutationUploadStorageItemTypeImageArgs = {
    file: Scalars['Upload'];
};

export type MutationVisitCancelVisitArgs = {
    data: VisitCancelVisitInput;
};

export type MutationVisitReviewApproveVisitArgs = {
    data: VisitReviewApproveVisitInput;
};

export type MutationVisitUpdateWindowArgs = {
    data: VisitUpdateWindowInput;
};

export type AddCustomerAddressInput = {
    customerId?: Maybe<Scalars['String']>;
    addressType: CustomerAddressType;
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    city: Scalars['String'];
    zoneId?: Maybe<Scalars['String']>;
    countryId: Scalars['String'];
    zip?: Maybe<Scalars['String']>;
    longitude?: Maybe<Scalars['Float']>;
    latitude?: Maybe<Scalars['Float']>;
    phone?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
};

export type AddCustomerAddressPayload = {
    __typename?: 'AddCustomerAddressPayload';
    customerAddress?: Maybe<CustomerAddress>;
};

export type AddCustomerPaymentMethodInput = {
    description: Scalars['String'];
    isDefault: Scalars['Boolean'];
};

export type AddCustomerPaymentMethodPayload = {
    __typename?: 'AddCustomerPaymentMethodPayload';
    paymentMethod?: Maybe<PaymentMethod>;
};

export type AdminAddBuildingInput = {
    code: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    zoneId?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    areaId: Scalars['String'];
};

export type AdminAddBuildingPayload = {
    __typename?: 'AdminAddBuildingPayload';
    data: Building;
};

export type AdminAddEmployeeInput = {
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    homePhone?: Maybe<Scalars['String']>;
    cellPhone?: Maybe<Scalars['String']>;
    workPhone?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['DateTime']>;
    endDate?: Maybe<Scalars['DateTime']>;
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    zoneId?: Maybe<Scalars['String']>;
    countryId: Scalars['String'];
    zip?: Maybe<Scalars['String']>;
};

export type AdminAddEmployeePayload = {
    __typename?: 'AdminAddEmployeePayload';
    data: Employee;
};

export type AdminAddLocationsInput = {
    description: Scalars['String'];
    barcode: Scalars['String'];
    type: LocationType;
    buildingId: Scalars['String'];
};

export type AdminAddLocationsPayload = {
    __typename?: 'AdminAddLocationsPayload';
    data: Array<Maybe<Location>>;
};

export type AdminAddPeriodToTerritoryInput = {
    scheduleTemplatePeriodId: Scalars['String'];
    territoryId: Scalars['String'];
};

export type AdminAddPeriodToTerritoryPayload = {
    __typename?: 'AdminAddPeriodToTerritoryPayload';
    data: ScheduleTemplateWindow;
};

export type AdminAddScheduleTemplatePeriodInput = {
    scheduleTemplateId: Scalars['String'];
    startTime: Scalars['String'];
    endTime: Scalars['String'];
    week: Scalars['Int'];
    day: Scalars['Int'];
};

export type AdminAddScheduleTemplatePeriodPayload = {
    __typename?: 'AdminAddScheduleTemplatePeriodPayload';
    data: ScheduleTemplateWindow;
};

export type AdminAddStorageContainerInput = {
    status: StorageContainerStatus;
    barcode?: Maybe<Scalars['String']>;
    storageContainerTypeId: Scalars['String'];
};

export type AdminAddStorageContainerPayload = {
    __typename?: 'AdminAddStorageContainerPayload';
    data: StorageContainer;
};

export type AdminAddStorageContainerTypeInput = {
    description: Scalars['String'];
};

export type AdminAddStorageContainerTypePayload = {
    __typename?: 'AdminAddStorageContainerTypePayload';
    data: StorageContainerType;
};

export type AdminAddStorageItemTypeInput = {
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    multiplePeople: Scalars['Boolean'];
    maximumRequestQuantity: Scalars['Int'];
    categoryId: Scalars['String'];
};

export type AdminAddStorageItemTypePayload = {
    __typename?: 'AdminAddStorageItemTypePayload';
    data: StorageItemType;
};

export type AdminAddStorageItemTypeCategoryInput = {
    name: Scalars['String'];
};

export type AdminAddStorageItemTypeCategoryPayload = {
    __typename?: 'AdminAddStorageItemTypeCategoryPayload';
    data: StorageItemTypeCategory;
};

export type AdminAddTerritoryInput = {
    description: Scalars['String'];
    maximumRequestQuantity?: Maybe<Scalars['Int']>;
    deliveryCutoff?: Maybe<Scalars['Int']>;
    deliveryDelay?: Maybe<Scalars['Int']>;
    pickupCutoff?: Maybe<Scalars['Int']>;
    pickupDelay?: Maybe<Scalars['Int']>;
    areaId: Scalars['String'];
    zipCodes?: Maybe<Array<Scalars['String']>>;
};

export type AdminAddTerritoryPayload = {
    __typename?: 'AdminAddTerritoryPayload';
    data: Territory;
};

export type AdminAddVehicleInput = {
    description: Scalars['String'];
    model?: Maybe<Scalars['String']>;
    manufacture_year: Scalars['Int'];
    vin?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
};

export type AdminAddVehiclePayload = {
    __typename?: 'AdminAddVehiclePayload';
    data: Vehicle;
};

export type AdminDeleteScheduleTemplatePeriodInput = {
    id: Scalars['String'];
};

export type AdminDeleteScheduleTemplatePeriodPayload = {
    __typename?: 'AdminDeleteScheduleTemplatePeriodPayload';
    data: ScheduleTemplateWindow;
};

export type AdminExcludePeriodFromTerritoryInput = {
    scheduleTemplatePeriodId: Scalars['String'];
    territoryId: Scalars['String'];
};

export type AdminExcludePeriodFromTerritoryPayload = {
    __typename?: 'AdminExcludePeriodFromTerritoryPayload';
    data: ScheduleTemplateWindow;
};

export type AdminUpdateAreaInput = {
    areaId: Scalars['String'];
    code: Scalars['String'];
};

export type AdminUpdateAreaPayload = {
    __typename?: 'AdminUpdateAreaPayload';
    data: Area;
};

export type AdminUpdateBuildingInput = {
    id: Scalars['String'];
    code: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
    address?: Maybe<AdminUpdateBuildingAddressInput>;
    areaId: Scalars['String'];
};

export type AdminUpdateBuildingAddressInput = {
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    zoneId?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
};

export type AdminUpdateBuildingPayload = {
    __typename?: 'AdminUpdateBuildingPayload';
    data: Building;
};

export type AdminUpdateEmployeeInput = {
    id: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    homePhone?: Maybe<Scalars['String']>;
    cellPhone?: Maybe<Scalars['String']>;
    workPhone?: Maybe<Scalars['String']>;
    address?: Maybe<AdminUpdateEmployeeAddressInput>;
};

export type AdminUpdateEmployeeAddressInput = {
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    zoneId?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
};

export type AdminUpdateEmployeePayload = {
    __typename?: 'AdminUpdateEmployeePayload';
    data: Employee;
};

export type AdminUpdateEmployeePasswordInput = {
    id: Scalars['String'];
    password?: Maybe<Scalars['String']>;
};

export type AdminUpdateEmployeePasswordPayload = {
    __typename?: 'AdminUpdateEmployeePasswordPayload';
    data: Employee;
};

export type AdminUpdateLocationInput = {
    id: Scalars['String'];
    description: Scalars['String'];
    barcode?: Maybe<Scalars['String']>;
    type: LocationType;
    buildingId: Scalars['String'];
};

export type AdminUpdateLocationPayload = {
    __typename?: 'AdminUpdateLocationPayload';
    data: Location;
};

export type AdminUpdateScheduleTemplateInput = {
    id: Scalars['String'];
    startDate: Scalars['DateTime'];
    areaId: Scalars['String'];
};

export type AdminUpdateScheduleTemplatePayload = {
    __typename?: 'AdminUpdateScheduleTemplatePayload';
    data: ScheduleTemplate;
};

export type AdminUpdateScheduleTemplatePeriodInput = {
    id: Scalars['String'];
    startTime: Scalars['String'];
    endTime: Scalars['String'];
    week: Scalars['Int'];
    day: Scalars['Int'];
};

export type AdminUpdateScheduleTemplatePeriodPayload = {
    __typename?: 'AdminUpdateScheduleTemplatePeriodPayload';
    data: ScheduleTemplateWindow;
};

export type AdminUpdateStorageContainerInput = {
    id: Scalars['String'];
    status: StorageContainerStatus;
    barcode?: Maybe<Scalars['String']>;
    storageContainerTypeId: Scalars['String'];
};

export type AdminUpdateStorageContainerPayload = {
    __typename?: 'AdminUpdateStorageContainerPayload';
    data: StorageContainer;
};

export type AdminStorageContainerTypeInput = {
    id: Scalars['ID'];
    description: Scalars['String'];
};

export type AdminStorageContainerTypeload = {
    __typename?: 'AdminStorageContainerTypeload';
    data: StorageContainerType;
};

export type AdminStorageItemTypeInput = {
    id: Scalars['String'];
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    multiplePeople: Scalars['Boolean'];
    maximumRequestQuantity: Scalars['Int'];
    categoryId: Scalars['String'];
};

export type AdminUpdateStorageItemTypePayload = {
    __typename?: 'AdminUpdateStorageItemTypePayload';
    data: StorageItemType;
};

export type AdminStorageItemTypeCategoryInput = {
    id: Scalars['String'];
    name: Scalars['String'];
};

export type AdminStorageItemTypeCategoryload = {
    __typename?: 'AdminStorageItemTypeCategoryload';
    data: StorageItemTypeCategory;
};

export type AdminUpdateTerritoryInput = {
    id: Scalars['String'];
    description: Scalars['String'];
    maximumRequestQuantity?: Maybe<Scalars['Int']>;
    deliveryCutoff?: Maybe<Scalars['Int']>;
    deliveryDelay?: Maybe<Scalars['Int']>;
    pickupCutoff?: Maybe<Scalars['Int']>;
    pickupDelay?: Maybe<Scalars['Int']>;
    areaId: Scalars['String'];
    zipCodes?: Maybe<Array<Scalars['String']>>;
};

export type AdminUpdateTerritoryPayload = {
    __typename?: 'AdminUpdateTerritoryPayload';
    data: Territory;
};

export type AdminUpdateVehicleInput = {
    id: Scalars['String'];
    description: Scalars['String'];
    model?: Maybe<Scalars['String']>;
    manufacture_year: Scalars['Int'];
    vin?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
};

export type AdminUpdateVehiclePayload = {
    __typename?: 'AdminUpdateVehiclePayload';
    data: Vehicle;
};

export type AssignStorageContainerInput = {
    storageItemId: Scalars['ID'];
    storageContainerId: Scalars['ID'];
};

export type AssignStorageContainerPayload = {
    __typename?: 'AssignStorageContainerPayload';
    storageItem: StorageItem;
};

export type DeliveryCompleteVisitInput = {
    visitId: Scalars['String'];
    customerSignature: Scalars['String'];
};

export type DeliveryCompleteVisitPayload = {
    __typename?: 'DeliveryCompleteVisitPayload';
    data: Visit;
};

export type DeliveryDeliverEmptyContainerInput = {
    requestId: Scalars['String'];
    containerBarcode: Scalars['String'];
};

export type DeliveryDeliverEmptyContainerPayload = {
    __typename?: 'DeliveryDeliverEmptyContainerPayload';
    data?: Maybe<DeliverEmptyContainerRequest>;
};

export type DeliveryDeliverItemInput = {
    requestId: Scalars['String'];
};

export type DeliveryDeliverItemPayload = {
    __typename?: 'DeliveryDeliverItemPayload';
    data?: Maybe<DeliverStorageItemRequest>;
};

export type DeliveryPickupEmptyContainerInput = {
    visitId: Scalars['String'];
    containerBarcode: Scalars['String'];
};

export type DeliveryPickupEmptyContainerPayload = {
    __typename?: 'DeliveryPickupEmptyContainerPayload';
    data?: Maybe<PickupEmptyContainerRequest>;
};

export type DeliveryPickupItemInput = {
    requestId: Scalars['String'];
};

export type DeliveryPickupItemPayload = {
    __typename?: 'DeliveryPickupItemPayload';
    data?: Maybe<PickupStorageItemRequest>;
};

export type MovingProcessItemInput = {
    itemBarcode: Scalars['String'];
    locationId: Scalars['String'];
};

export type MovingProcessItemPayload = {
    __typename?: 'MovingProcessItemPayload';
    data: StorageItem;
};

export type PickingCompleteListInput = {
    pickListId: Scalars['String'];
};

export type PickingCompleteListPayload = {
    __typename?: 'PickingCompleteListPayload';
    pickList?: Maybe<PickList>;
};

export type PickingCreateListInput = {
    description?: Maybe<Scalars['String']>;
    filter: PickingCreateListFilterInput;
};

export type PickingCreateListFilterInput = {
    visitStartTime?: Maybe<Scalars['DateTime']>;
    visitEndTime?: Maybe<Scalars['DateTime']>;
    locations?: Maybe<Array<PickingCreateListLocationFilterInput>>;
    storageItemTypeIds?: Maybe<Array<Scalars['String']>>;
    maxQuantity?: Maybe<Scalars['Int']>;
    visitIds?: Maybe<Array<Scalars['String']>>;
};

export type PickingCreateListLocationFilterInput = {
    from?: Maybe<Scalars['String']>;
    to?: Maybe<Scalars['String']>;
};

export type PickingCreateListPayload = {
    __typename?: 'PickingCreateListPayload';
    pickList?: Maybe<PickList>;
};

export type PickingDeleteListInput = {
    pickListId: Scalars['String'];
};

export type PickingDeleteListPayload = {
    __typename?: 'PickingDeleteListPayload';
    pickList?: Maybe<PickList>;
};

export type PickingExcludeItemInput = {
    pickListItemId: Scalars['String'];
};

export type PickingExcludeItemPayload = {
    __typename?: 'PickingExcludeItemPayload';
    pickListItem?: Maybe<PickListItem>;
};

export type PickingPickItemInput = {
    pickListItemId: Scalars['String'];
    targetLocationName?: Maybe<Scalars['String']>;
};

export type PickingPickItemPayload = {
    __typename?: 'PickingPickItemPayload';
    pickListItem?: Maybe<PickListItem>;
};

export type PickingSortItemInput = {
    storageItemId: Scalars['String'];
    locationId: Scalars['String'];
};

export type PickingSortItemPayload = {
    __typename?: 'PickingSortItemPayload';
    storageItem?: Maybe<StorageItem>;
};

export type RefilingCompleteListInput = {
    refileListId: Scalars['String'];
};

export type RefilingCompleteListPayload = {
    __typename?: 'RefilingCompleteListPayload';
    data: RefileList;
};

export type RefilingCreateListInput = {
    storageItemIds?: Maybe<Array<Scalars['String']>>;
};

export type RefilingCreateListPayload = {
    __typename?: 'RefilingCreateListPayload';
    data?: Maybe<RefileList>;
};

export type RefilingProcessItemInput = {
    itemBarcode: Scalars['String'];
    locationId: Scalars['String'];
    refileListId: Scalars['String'];
};

export type RefilingProcessItemPayload = {
    __typename?: 'RefilingProcessItemPayload';
    data: RefileItem;
};

export type RequestAssignContainerInput = {
    requestId: Scalars['String'];
    barcode: Scalars['String'];
};

export type RequestAssignContainerPayload = {
    __typename?: 'RequestAssignContainerPayload';
    data?: Maybe<Request>;
};

export type RoutingAddStopVisitInput = {
    visitId: Scalars['ID'];
    startTime: Scalars['DateTime'];
    endTime: Scalars['DateTime'];
};

export type RoutingAddStopInput = {
    routeId: Scalars['ID'];
    visits: Array<RoutingAddStopVisitInput>;
};

export type RoutingAddStopPayload = {
    __typename?: 'RoutingAddStopPayload';
    routeStops: Array<RouteStop>;
    route: Route;
};

export type RoutingCreateRouteInput = {
    description?: Maybe<Scalars['String']>;
    startBuildingId?: Maybe<Scalars['String']>;
    endBuildingId?: Maybe<Scalars['String']>;
    employeeIds?: Maybe<Array<Scalars['String']>>;
    operationDate: Scalars['DateTime'];
};

export type RoutingCreateRoutePayload = {
    __typename?: 'RoutingCreateRoutePayload';
    route: Route;
};

export type RoutingRemoveStopInput = {
    routeStopId: Scalars['ID'];
};

export type RoutingRemoveStopPayload = {
    __typename?: 'RoutingRemoveStopPayload';
    visit: Visit;
    route: Route;
};

export type RoutingSetEmployeesInput = {
    routeId: Scalars['String'];
    employeeIds: Array<Scalars['String']>;
};

export type RoutingSetEmployeesPayload = {
    __typename?: 'RoutingSetEmployeesPayload';
    route: Route;
};

export type RoutingSetVehicleInput = {
    routeId: Scalars['String'];
    vehicleId?: Maybe<Scalars['String']>;
};

export type RoutingSetVehiclePayload = {
    __typename?: 'RoutingSetVehiclePayload';
    route: Route;
};

export type RoutingUpdateStopInput = {
    routeStopId: Scalars['ID'];
    startTime: Scalars['DateTime'];
};

export type RoutingUpdateStopPayload = {
    __typename?: 'RoutingUpdateStopPayload';
    routeStop: RouteStop;
    visit: Visit;
    route: Route;
};

export type SaveVisitInput = {
    customerId?: Maybe<Scalars['String']>;
    newItemPickupRequests?: Maybe<Array<RequestNewItemPickupRequestInput>>;
    existingItemPickupRequests?: Maybe<Array<RequestExistingItemPickupRequestInput>>;
    itemDeliveryRequests?: Maybe<Array<ItemDeliveryRequestInput>>;
    emptyContainerPickupRequests?: Maybe<Array<EmptyContainerPickupRequestInput>>;
    visitId?: Maybe<Scalars['String']>;
    customerAddressId?: Maybe<Scalars['String']>;
    customerPaymentMethodId?: Maybe<Scalars['String']>;
    startTime?: Maybe<Scalars['DateTime']>;
    endTime?: Maybe<Scalars['DateTime']>;
    containsContainers?: Maybe<Scalars['Boolean']>;
    containersOneVisit?: Maybe<Scalars['Boolean']>;
    secondVisitId?: Maybe<Scalars['String']>;
    secondCustomerAddressId?: Maybe<Scalars['String']>;
    secondCustomerPaymentMethodId?: Maybe<Scalars['String']>;
    secondStartTime?: Maybe<Scalars['DateTime']>;
    secondEndTime?: Maybe<Scalars['DateTime']>;
};

export type RequestNewItemPickupRequestInput = {
    storageItemTypeId: Scalars['String'];
    quantity: Scalars['Float'];
};

export type RequestExistingItemPickupRequestInput = {
    storageItemId: Scalars['String'];
};

export type ItemDeliveryRequestInput = {
    storageItemId: Scalars['String'];
};

export type EmptyContainerPickupRequestInput = {
    storageContainerId: Scalars['String'];
};

export type SaveVisitPayload = {
    __typename?: 'SaveVisitPayload';
    visit?: Maybe<Visit>;
    secondVisit?: Maybe<Visit>;
};

export type SetStorageItemMetadataInput = {
    storageItemId: Scalars['String'];
    values?: Maybe<Array<SetStorageItemMetadataValueInput>>;
};

export type SetStorageItemMetadataValueInput = {
    storageItemMetadataDefinitionId: Scalars['String'];
    value: Scalars['String'];
};

export type SetStorageItemMetadataPayload = {
    __typename?: 'SetStorageItemMetadataPayload';
    values?: Maybe<Array<StorageItemMetadataValue>>;
};

export type StorageItemAssignBarcodeInput = {
    storageItemId: Scalars['String'];
    barcode: Scalars['String'];
};

export type StorageItemAssignBarcodePayload = {
    __typename?: 'StorageItemAssignBarcodePayload';
    data?: Maybe<StorageItem>;
};

export type TruckLoadingProcessConfirmRouteInput = {
    routeId: Scalars['String'];
};

export type TruckLoadingProcessConfirmRoutePayload = {
    __typename?: 'TruckLoadingProcessConfirmRoutePayload';
    data: Route;
};

export type TruckLoadingProcessEmptyContainersInput = {
    routeId: Scalars['String'];
};

export type TruckLoadingProcessEmptyContainersPayload = {
    __typename?: 'TruckLoadingProcessEmptyContainersPayload';
    data: Route;
};

export type TruckLoadingProcessItemInput = {
    requestId: Scalars['String'];
};

export type TruckLoadingProcessItemPayload = {
    __typename?: 'TruckLoadingProcessItemPayload';
    data?: Maybe<DeliverStorageItemRequest>;
};

export type TruckUnloadingProcessItemInput = {
    routeIds: Array<Scalars['String']>;
    locationBarcode: Scalars['String'];
};

export type TruckUnloadingProcessItemPayload = {
    __typename?: 'TruckUnloadingProcessItemPayload';
    data: Scalars['Boolean'];
};

export type UnassignStorageContainerInput = {
    storageContainerId: Scalars['ID'];
};

export type UnassignStorageContainerPayload = {
    __typename?: 'UnassignStorageContainerPayload';
    storageItem: StorageItem;
};

export type UpdateVisitPaymentMethodInput = {
    visitId: Scalars['String'];
    customerPaymentMethodId: Scalars['String'];
};

export type UpdateVisitPaymentMethodPayload = {
    __typename?: 'UpdateVisitPaymentMethodPayload';
    visit?: Maybe<Visit>;
};

export type UploadStorageItemTypeImageResponse = {
    __typename?: 'UploadStorageItemTypeImageResponse';
    url: Scalars['String'];
};

export type UploadStorageItemTypeImagePayload = {
    __typename?: 'UploadStorageItemTypeImagePayload';
    data: UploadStorageItemTypeImageResponse;
};

export type VisitCancelVisitInput = {
    id: Scalars['String'];
};

export type VisitCancelVisitPayload = {
    __typename?: 'VisitCancelVisitPayload';
    data: Visit;
};

export type VisitReviewApproveVisitInput = {
    visitId: Scalars['String'];
    estimatedDuration: Scalars['Int'];
    scheduleWindowId?: Maybe<Scalars['String']>;
    scheduleWindowDate?: Maybe<Scalars['DateTime']>;
};

export type VisitReviewApproveVisitPayload = {
    __typename?: 'VisitReviewApproveVisitPayload';
    visit?: Maybe<Visit>;
};

export type VisitUpdateWindowInput = {
    visitId: Scalars['String'];
    startTime: Scalars['DateTime'];
    endTime: Scalars['DateTime'];
};

export type VisitUpdateWindowPayload = {
    __typename?: 'VisitUpdateWindowPayload';
    data: Visit;
};

export type FilterInput = {
    conditions?: Maybe<Array<Maybe<WhereCondition>>>;
    pageSize?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
};

export type WhereCondition = {
    field: Scalars['String'];
    value?: Maybe<Scalars['String']>;
    operator?: Maybe<Operators>;
};

export enum Operators {
    Greaterthan = 'Greaterthan',
    Lesserthan = 'Lesserthan',
    GreaterthanOrEqualto = 'GreaterthanOrEqualto',
    Lesserthanorequalto = 'Lesserthanorequalto',
    Equal = 'Equal',
    Notequal = 'Notequal',
}

export type OrderBy = {
    field: Scalars['String'];
    direction?: Maybe<Directions>;
};

export enum Directions {
    Asc = 'Asc',
    Desc = 'Desc',
}

export type PageInfo = {
    __typename?: 'PageInfo';
    cursor: PageCursor;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
};

export type PageCursor = {
    __typename?: 'PageCursor';
    beforeCursor?: Maybe<Scalars['String']>;
    afterCursor?: Maybe<Scalars['String']>;
};

export type Query = {
    __typename?: 'Query';
    _empty?: Maybe<Scalars['String']>;
    areaById?: Maybe<Area>;
    areas?: Maybe<Array<Area>>;
    books?: Maybe<Array<Maybe<Book>>>;
    buildingById?: Maybe<Building>;
    buildings: BuildingConnection;
    buildingsAll?: Maybe<Array<Building>>;
    currentCustomer?: Maybe<Customer>;
    customers: CustomerConnection;
    employeeById?: Maybe<Employee>;
    employees: EmployeeConnection;
    getCustomerById: Customer;
    locationByBarcode?: Maybe<Location>;
    locationById?: Maybe<Location>;
    locations: LocationConnection;
    me?: Maybe<User>;
    pickListById?: Maybe<PickList>;
    pickLists: PickListConnection;
    pickingListSummary: PickingListSummaryPayload;
    providerByDomain?: Maybe<Provider>;
    refileListById?: Maybe<RefileList>;
    refileLists: RefileListConnection;
    requestById?: Maybe<Request>;
    requestsByVisitId: RequestConnection;
    routeByVehicleId?: Maybe<Array<Route>>;
    routes: RouteConnection;
    scheduleTemplateById?: Maybe<ScheduleTemplate>;
    scheduleTemplates?: Maybe<Array<ScheduleTemplate>>;
    serviceProvider?: Maybe<ServiceProvider>;
    storageContainerByBarcode?: Maybe<StorageContainer>;
    storageContainerById?: Maybe<StorageContainer>;
    storageContainerType?: Maybe<Array<StorageContainerType>>;
    storageContainerTypeById?: Maybe<StorageContainerType>;
    storageContainers: StorageContainerConnection;
    storageItemByBarcode?: Maybe<StorageItem>;
    storageItemById?: Maybe<StorageItem>;
    storageItemByRequest?: Maybe<StorageItem>;
    storageItemTypeById?: Maybe<StorageItemType>;
    storageItemTypeCategory?: Maybe<Array<StorageItemTypeCategory>>;
    storageItemTypeCategoryById?: Maybe<StorageItemTypeCategory>;
    storageItemTypes: StorageItemTypeConnection;
    storageItems: StorageItemConnection;
    territories: TerritoryConnection;
    territoryById?: Maybe<Territory>;
    territoryByZipCode?: Maybe<Territory>;
    vehicleById?: Maybe<Vehicle>;
    vehicles: VehicleConnection;
    vehiclesByInProgressRoutes?: Maybe<Array<Vehicle>>;
    visitById?: Maybe<Visit>;
    visits: VisitConnection;
};

export type QueryAreaByIdArgs = {
    id: Scalars['String'];
};

export type QueryBuildingByIdArgs = {
    id: Scalars['String'];
};

export type QueryBuildingsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryCustomersArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryEmployeeByIdArgs = {
    id: Scalars['String'];
};

export type QueryEmployeesArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryGetCustomerByIdArgs = {
    id?: Maybe<Scalars['String']>;
};

export type QueryLocationByBarcodeArgs = {
    barcode: Scalars['String'];
};

export type QueryLocationByIdArgs = {
    id: Scalars['String'];
};

export type QueryLocationsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryPickListByIdArgs = {
    id: Scalars['String'];
};

export type QueryPickListsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryPickingListSummaryArgs = {
    data: PickingListSummaryFilterInput;
};

export type QueryProviderByDomainArgs = {
    domain: Scalars['String'];
};

export type QueryRefileListByIdArgs = {
    id: Scalars['String'];
};

export type QueryRefileListsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryRequestByIdArgs = {
    id: Scalars['String'];
};

export type QueryRequestsByVisitIdArgs = {
    visitId: Scalars['String'];
    filter: FilterInput;
};

export type QueryRouteByVehicleIdArgs = {
    vehicleId: Scalars['String'];
    routeStatus?: Maybe<RouteStatus>;
    fromDate?: Maybe<Scalars['DateTime']>;
    toDate?: Maybe<Scalars['DateTime']>;
};

export type QueryRoutesArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryScheduleTemplateByIdArgs = {
    id: Scalars['String'];
};

export type QueryStorageContainerByBarcodeArgs = {
    barcode: Scalars['String'];
};

export type QueryStorageContainerByIdArgs = {
    id: Scalars['String'];
};

export type QueryStorageContainerTypeByIdArgs = {
    id: Scalars['String'];
};

export type QueryStorageContainersArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryStorageItemByBarcodeArgs = {
    barcode: Scalars['String'];
};

export type QueryStorageItemByIdArgs = {
    id: Scalars['String'];
};

export type QueryStorageItemByRequestArgs = {
    requestId: Scalars['String'];
};

export type QueryStorageItemTypeByIdArgs = {
    id: Scalars['String'];
};

export type QueryStorageItemTypeCategoryByIdArgs = {
    id: Scalars['String'];
};

export type QueryStorageItemTypesArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryStorageItemsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryTerritoriesArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryTerritoryByIdArgs = {
    id: Scalars['String'];
};

export type QueryTerritoryByZipCodeArgs = {
    zipCode: Scalars['String'];
};

export type QueryVehicleByIdArgs = {
    id: Scalars['String'];
};

export type QueryVehiclesArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type QueryVisitByIdArgs = {
    visitId: Scalars['String'];
};

export type QueryVisitsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type Address = {
    __typename?: 'Address';
    id: Scalars['String'];
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    zoneId?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
    longitude?: Maybe<Scalars['Float']>;
    latitude?: Maybe<Scalars['Float']>;
    phone?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
};

export type AddressConnection = {
    __typename?: 'AddressConnection';
    pageInfo: PageInfo;
    edges: Array<AddressEdge>;
};

export type AddressEdge = {
    __typename?: 'AddressEdge';
    cursor: Scalars['String'];
    node: Address;
};

export type Area = {
    __typename?: 'Area';
    buildings?: Maybe<Array<Building>>;
    code: Scalars['String'];
    deliveryWindowsByDate?: Maybe<Array<ScheduleWindow>>;
    id: Scalars['ID'];
    scheduleTemplate?: Maybe<ScheduleTemplate>;
    territories?: Maybe<Array<Territory>>;
    visits?: Maybe<VisitConnection>;
};

export type AreaDeliveryWindowsByDateArgs = {
    date: Scalars['DateTime'];
};

export type AreaVisitsArgs = {
    data?: Maybe<VisitFilterInput>;
};

export type Building = {
    __typename?: 'Building';
    address: Address;
    area: Area;
    code: Scalars['String'];
    description: Scalars['String'];
    id: Scalars['ID'];
    isActive: Scalars['Boolean'];
};

export type Territory = {
    __typename?: 'Territory';
    area: Area;
    deliveryCutoff?: Maybe<Scalars['Int']>;
    deliveryDelay?: Maybe<Scalars['Int']>;
    description: Scalars['String'];
    excludedDeliveryWindows?: Maybe<Array<ScheduleTemplateWindow>>;
    id: Scalars['ID'];
    maximumRequestQuantity?: Maybe<Scalars['Int']>;
    pickupCutoff?: Maybe<Scalars['Int']>;
    pickupDelay?: Maybe<Scalars['Int']>;
    schedule?: Maybe<Array<ScheduleWindow>>;
    storageItemTypes?: Maybe<Array<StorageItemType>>;
    zipCodes?: Maybe<Array<Scalars['String']>>;
};

export type TerritoryScheduleArgs = {
    startDate: Scalars['DateTime'];
    endDate: Scalars['DateTime'];
    requestType: RequestType;
};

export type ScheduleTemplate = {
    __typename?: 'ScheduleTemplate';
    area: Area;
    id: Scalars['ID'];
    startDate?: Maybe<Scalars['DateTime']>;
    weeks: Array<ScheduleTemplateWeek>;
};

export type Author = {
    __typename?: 'Author';
    age?: Maybe<Scalars['Int']>;
    books?: Maybe<Array<Maybe<Book>>>;
    name?: Maybe<Scalars['String']>;
};

export type Book = {
    __typename?: 'Book';
    author?: Maybe<Author>;
    availableQty?: Maybe<Scalars['Int']>;
    price?: Maybe<Scalars['Float']>;
    rand?: Maybe<Scalars['Int']>;
    soldQty?: Maybe<Scalars['Int']>;
    test?: Maybe<Scalars['Int']>;
    title?: Maybe<Scalars['String']>;
};

export type BuildingConnection = {
    __typename?: 'BuildingConnection';
    pageInfo: PageInfo;
    edges: Array<BuildingEdge>;
};

export type BuildingEdge = {
    __typename?: 'BuildingEdge';
    cursor: Scalars['String'];
    node: Building;
};

export type Route = {
    __typename?: 'Route';
    description?: Maybe<Scalars['String']>;
    employees?: Maybe<Array<Employee>>;
    endBuilding: Building;
    id: Scalars['ID'];
    operationDate: Scalars['DateTime'];
    routeNumber: Scalars['Int'];
    startBuilding: Building;
    status: RouteStatus;
    stops?: Maybe<Array<RouteStop>>;
    vehicles?: Maybe<Array<Vehicle>>;
};

export type Location = {
    __typename?: 'Location';
    barcode: Scalars['String'];
    building: Building;
    description: Scalars['String'];
    id: Scalars['ID'];
    itemsToSort?: Maybe<Scalars['Int']>;
    sortedItems?: Maybe<Scalars['Int']>;
    type?: Maybe<LocationType>;
};

export type Customer = {
    __typename?: 'Customer';
    addresses: CustomerAddressConnection;
    cellPhone?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    firstName: Scalars['String'];
    homePhone?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lastName: Scalars['String'];
    paymentMethods: PaymentMethodConnection;
    storageContainers: StorageContainerConnection;
    storageItems: StorageItemConnection;
    type: Scalars['String'];
    visits: VisitConnection;
};

export type CustomerAddressesArgs = {
    filter: FilterInput;
};

export type CustomerPaymentMethodsArgs = {
    filter: FilterInput;
};

export type CustomerStorageContainersArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type CustomerStorageItemsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type CustomerVisitsArgs = {
    filter: FilterInput;
};

export type CustomerConnection = {
    __typename?: 'CustomerConnection';
    pageInfo: PageInfo;
    edges: Array<CustomerEdge>;
};

export type CustomerEdge = {
    __typename?: 'CustomerEdge';
    cursor: Scalars['String'];
    node: Customer;
};

export type Visit = {
    __typename?: 'Visit';
    completedDate?: Maybe<Scalars['DateTime']>;
    customer: Customer;
    customerAddress: CustomerAddress;
    customerSignature?: Maybe<Scalars['String']>;
    endTime?: Maybe<Scalars['DateTime']>;
    expectedDuration?: Maybe<Scalars['Float']>;
    id: Scalars['ID'];
    locations: Array<Location>;
    requests: RequestConnection;
    scheduleWindow: ScheduleWindow;
    startTime?: Maybe<Scalars['DateTime']>;
    status: VisitStatus;
    stop?: Maybe<RouteStop>;
    summary: VisitSummary;
    territory: Territory;
};

export type VisitRequestsArgs = {
    filter: FilterInput;
};

export type PickupStorageItemRequest = Request & {
    __typename?: 'PickupStorageItemRequest';
    container?: Maybe<StorageContainer>;
    customer: Customer;
    id: Scalars['ID'];
    requestType: RequestType;
    status: RequestStatus;
    storageItem?: Maybe<StorageItem>;
};

export type DeliverStorageItemRequest = Request & {
    __typename?: 'DeliverStorageItemRequest';
    customer: Customer;
    id: Scalars['ID'];
    requestType: RequestType;
    sortLocation?: Maybe<Array<Location>>;
    status: RequestStatus;
    storageItem?: Maybe<StorageItem>;
};

export type DeliverEmptyContainerRequest = Request & {
    __typename?: 'DeliverEmptyContainerRequest';
    container?: Maybe<StorageContainer>;
    customer: Customer;
    id: Scalars['ID'];
    requestType: RequestType;
    status: RequestStatus;
    storageItem?: Maybe<StorageItem>;
};

export type PickupEmptyContainerRequest = Request & {
    __typename?: 'PickupEmptyContainerRequest';
    container?: Maybe<StorageContainer>;
    customer: Customer;
    id: Scalars['ID'];
    requestType: RequestType;
    status: RequestStatus;
    storageItem?: Maybe<StorageItem>;
};

export type DriverPickupEmptyContainerRequest = Request & {
    __typename?: 'DriverPickupEmptyContainerRequest';
    customer: Customer;
    id: Scalars['ID'];
    requestType: RequestType;
    status: RequestStatus;
    storageItem?: Maybe<StorageItem>;
};

export type StorageContainer = {
    __typename?: 'StorageContainer';
    assignedStorageItem?: Maybe<StorageItem>;
    barcode?: Maybe<Scalars['String']>;
    containerType: StorageContainerType;
    customer?: Maybe<Customer>;
    id: Scalars['ID'];
    status: StorageContainerStatus;
};

export type CustomerAddress = {
    __typename?: 'CustomerAddress';
    address1: Scalars['String'];
    address2?: Maybe<Scalars['String']>;
    addressType?: Maybe<CustomerAddressType>;
    city?: Maybe<Scalars['String']>;
    countryId?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    lastName?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    phone?: Maybe<Scalars['String']>;
    territory: Territory;
    visits: VisitConnection;
    zip?: Maybe<Scalars['String']>;
    zoneId?: Maybe<Scalars['String']>;
};

export type CustomerAddressVisitsArgs = {
    filter: FilterInput;
};

export enum CustomerAddressType {
    Billing = 'BILLING',
    Delivery = 'DELIVERY',
}

export type CustomerAddressConnection = {
    __typename?: 'CustomerAddressConnection';
    pageInfo: PageInfo;
    edges: Array<CustomerAddressEdge>;
};

export type CustomerAddressEdge = {
    __typename?: 'CustomerAddressEdge';
    cursor: Scalars['String'];
    node: CustomerAddress;
};

export type PaymentMethod = {
    __typename?: 'PaymentMethod';
    id: Scalars['ID'];
    description: Scalars['String'];
    isDefault?: Maybe<Scalars['Boolean']>;
    order: Scalars['Int'];
};

export type PaymentMethodConnection = {
    __typename?: 'PaymentMethodConnection';
    pageInfo: PageInfo;
    edges: Array<PaymentMethodEdge>;
};

export type PaymentMethodEdge = {
    __typename?: 'PaymentMethodEdge';
    cursor: Scalars['String'];
    node: PaymentMethod;
};

export type Employee = {
    __typename?: 'Employee';
    address: Address;
    cellPhone?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    endDate?: Maybe<Scalars['DateTime']>;
    firstName: Scalars['String'];
    homePhone?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lastName: Scalars['String'];
    startDate?: Maybe<Scalars['DateTime']>;
    type: Scalars['String'];
    vehicles?: Maybe<Array<Vehicle>>;
    workPhone?: Maybe<Scalars['String']>;
};

export type PickList = {
    __typename?: 'PickList';
    created_at?: Maybe<Scalars['DateTime']>;
    description: Scalars['String'];
    endTime?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    items?: Maybe<PickListItemConnection>;
    locations?: Maybe<Array<LocationFilter>>;
    maxQuantity?: Maybe<Scalars['Int']>;
    pickListNumber: Scalars['Int'];
    pickers?: Maybe<Array<Employee>>;
    startTime?: Maybe<Scalars['DateTime']>;
    status: PickListStatus;
    storageItemTypes?: Maybe<Array<StorageItemType>>;
    summary: PickListSummary;
    visitEndTime?: Maybe<Scalars['DateTime']>;
    visitStartTime?: Maybe<Scalars['DateTime']>;
    visits?: Maybe<Array<Visit>>;
};

export type PickListItemsArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type EmployeeConnection = {
    __typename?: 'EmployeeConnection';
    pageInfo: PageInfo;
    edges: Array<EmployeeEdge>;
};

export type EmployeeEdge = {
    __typename?: 'EmployeeEdge';
    cursor: Scalars['String'];
    node: Employee;
};

export enum LocationType {
    Bin = 'BIN',
    Storage = 'STORAGE',
    Incoming = 'INCOMING',
}

export type StorageItem = {
    __typename?: 'StorageItem';
    barcode?: Maybe<Scalars['String']>;
    container?: Maybe<StorageContainer>;
    id: Scalars['ID'];
    latestVisit?: Maybe<Visit>;
    location?: Maybe<Location>;
    metadataValue?: Maybe<Array<StorageItemMetadataValue>>;
    requests?: Maybe<Array<Request>>;
    status: StorageItemStatus;
    storageItemType: StorageItemType;
    visits: VisitConnection;
};

export type StorageItemRequestsArgs = {
    filter: FilterInput;
};

export type StorageItemVisitsArgs = {
    filter: FilterInput;
};

export type LocationConnection = {
    __typename?: 'LocationConnection';
    pageInfo: PageInfo;
    edges: Array<LocationEdge>;
};

export type LocationEdge = {
    __typename?: 'LocationEdge';
    cursor: Scalars['String'];
    node: Location;
};

export enum PickListStatus {
    Created = 'CREATED',
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED',
}

export type PickListSummary = {
    __typename?: 'PickListSummary';
    toBePicked: Scalars['Int'];
    picked: Scalars['Int'];
    excluded: Scalars['Int'];
};

export type LocationFilter = {
    __typename?: 'LocationFilter';
    from?: Maybe<Scalars['String']>;
    to?: Maybe<Scalars['String']>;
};

export type PickListConnection = {
    __typename?: 'PickListConnection';
    pageInfo: PageInfo;
    edges: Array<PickListEdge>;
};

export type PickListEdge = {
    __typename?: 'PickListEdge';
    cursor: Scalars['String'];
    node: PickList;
};

export type PickListItem = {
    __typename?: 'PickListItem';
    id: Scalars['ID'];
    storageItem: StorageItem;
    request?: Maybe<Request>;
};

export type PickListItemConnection = {
    __typename?: 'PickListItemConnection';
    pageInfo: PageInfo;
    edges: Array<PickListItemtEdge>;
};

export type PickListItemtEdge = {
    __typename?: 'PickListItemtEdge';
    cursor: Scalars['String'];
    node: PickListItem;
};

export type PickingListSummary = {
    __typename?: 'PickingListSummary';
    storageItemType: StorageItemType;
    listTotalItems: Scalars['Float'];
    warehouseTotalItems: Scalars['Float'];
};

export type PickingListSummaryFilterInput = {
    visitStartTime?: Maybe<Scalars['DateTime']>;
    visitEndTime?: Maybe<Scalars['DateTime']>;
    locations?: Maybe<Array<PickingCreateListLocationFilterInput>>;
    storageItemTypeIds?: Maybe<Array<Scalars['String']>>;
    maxQuantity?: Maybe<Scalars['Int']>;
    visitIds?: Maybe<Array<Scalars['String']>>;
};

export type PickingListSummaryPayload = {
    __typename?: 'PickingListSummaryPayload';
    data?: Maybe<Array<PickingListSummary>>;
};

export type Provider = {
    __typename?: 'Provider';
    name: Scalars['String'];
    description: Scalars['String'];
    customUrl?: Maybe<Scalars['String']>;
    logo?: Maybe<Scalars['String']>;
    color?: Maybe<Scalars['String']>;
    customerUserPool?: Maybe<Scalars['String']>;
    employeeUserPool?: Maybe<Scalars['String']>;
};

export type RefileItem = {
    __typename?: 'RefileItem';
    id: Scalars['ID'];
    status?: Maybe<RefileItemStatus>;
    storageItem: StorageItem;
};

export enum RefileItemStatus {
    Created = 'CREATED',
    Refiled = 'REFILED',
}

export type RefileList = {
    __typename?: 'RefileList';
    created_at?: Maybe<Scalars['DateTime']>;
    endTime?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    items?: Maybe<Array<RefileItem>>;
    refileListNumber: Scalars['Int'];
    startTime?: Maybe<Scalars['DateTime']>;
    status: RefileListStatus;
};

export enum RefileListStatus {
    Created = 'CREATED',
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED',
}

export type RefileListConnection = {
    __typename?: 'RefileListConnection';
    pageInfo: PageInfo;
    edges: Array<RefileListEdge>;
};

export type RefileListEdge = {
    __typename?: 'RefileListEdge';
    cursor: Scalars['String'];
    node: RefileList;
};

export enum RequestStatus {
    Created = 'CREATED',
    InPicklist = 'IN_PICKLIST',
    PickingProcessed = 'PICKING_PROCESSED',
    Excluded = 'EXCLUDED',
    InTransitToCustomer = 'IN_TRANSIT_TO_CUSTOMER',
    InTransitToWarehouse = 'IN_TRANSIT_TO_WAREHOUSE',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED',
    Sorted = 'SORTED',
    Processed = 'PROCESSED',
    PickingCompleted = 'PICKING_COMPLETED',
}

export type Request = {
    id: Scalars['ID'];
    status: RequestStatus;
    storageItem?: Maybe<StorageItem>;
    requestType: RequestType;
};

export type RequestConnection = {
    __typename?: 'RequestConnection';
    pageInfo: PageInfo;
    edges: Array<RequestEdge>;
};

export type RequestEdge = {
    __typename?: 'RequestEdge';
    cursor: Scalars['String'];
    node: Request;
};

export enum RequestType {
    DeliverStorageItem = 'DELIVER_STORAGE_ITEM',
    PickupStorageItem = 'PICKUP_STORAGE_ITEM',
    PickupEmptyContainer = 'PICKUP_EMPTY_CONTAINER',
    DeliverEmptyContainer = 'DELIVER_EMPTY_CONTAINER',
    DriverPickupEmptyContainer = 'DRIVER_PICKUP_EMPTY_CONTAINER',
}

export enum RouteStatus {
    Created = 'CREATED',
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED',
    InTransit = 'IN_TRANSIT',
}

export type RouteConnection = {
    __typename?: 'RouteConnection';
    pageInfo: PageInfo;
    edges: Array<RouteEdge>;
};

export type RouteEdge = {
    __typename?: 'RouteEdge';
    cursor: Scalars['String'];
    node: Route;
};

export type RouteStop = {
    __typename?: 'RouteStop';
    endTime: Scalars['DateTime'];
    id: Scalars['ID'];
    route: Route;
    sequence?: Maybe<Scalars['Int']>;
    startTime: Scalars['DateTime'];
    visit: Visit;
};

export type ScheduleTemplateWindow = {
    __typename?: 'ScheduleTemplateWindow';
    id: Scalars['String'];
    startTime: Scalars['String'];
    endTime: Scalars['String'];
    week: Scalars['Int'];
    day: Scalars['Int'];
};

export type ScheduleTemplateWeek = {
    __typename?: 'ScheduleTemplateWeek';
    monday: Array<ScheduleTemplateWindow>;
    tuesday: Array<ScheduleTemplateWindow>;
    wednesday: Array<ScheduleTemplateWindow>;
    thursday: Array<ScheduleTemplateWindow>;
    friday: Array<ScheduleTemplateWindow>;
    saturday: Array<ScheduleTemplateWindow>;
    sunday: Array<ScheduleTemplateWindow>;
};

export type ScheduleWindow = {
    __typename?: 'ScheduleWindow';
    id: Scalars['ID'];
    startTime: Scalars['DateTime'];
    endTime: Scalars['DateTime'];
    isLocked: Scalars['Boolean'];
};

export type ServiceProvider = {
    __typename?: 'ServiceProvider';
    cognitoHostedDomain?: Maybe<Scalars['String']>;
    cognitoRegion: Scalars['String'];
    cognitoSocialSignInCallback?: Maybe<Scalars['String']>;
    cognitoSocialSignOutCallback?: Maybe<Scalars['String']>;
    cognitoWebApplicationId?: Maybe<Scalars['String']>;
    customerUserPool: Scalars['String'];
    employeeUserPool: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    storageItemCatgories?: Maybe<Array<StorageItemTypeCategory>>;
    storageItemTypes: StorageItemTypeConnection;
    territories: TerritoryConnection;
    theme?: Maybe<Scalars['String']>;
};

export type ServiceProviderStorageItemTypesArgs = {
    filter: FilterInput;
    sort?: Maybe<Array<Maybe<OrderBy>>>;
};

export type ServiceProviderTerritoriesArgs = {
    filter?: Maybe<FilterInput>;
};

export enum StorageContainerStatus {
    Available = 'AVAILABLE',
    InUse = 'IN_USE',
    Retired = 'RETIRED',
}

export type StorageContainerConnection = {
    __typename?: 'StorageContainerConnection';
    pageInfo: PageInfo;
    edges: Array<StorageContainerEdge>;
};

export type StorageContainerEdge = {
    __typename?: 'StorageContainerEdge';
    cursor: Scalars['String'];
    node: StorageContainer;
};

export type StorageContainerType = {
    __typename?: 'StorageContainerType';
    id: Scalars['ID'];
    description: Scalars['String'];
};

export type StorageItemType = {
    __typename?: 'StorageItemType';
    category: StorageItemTypeCategory;
    containerType?: Maybe<StorageContainerType>;
    description: Scalars['String'];
    id: Scalars['ID'];
    image: Scalars['String'];
    maximumRequestQuantity: Scalars['Int'];
    metadataDefinitions?: Maybe<Array<StorageItemMetadataDefinition>>;
    multiplePeople: Scalars['Boolean'];
    name: Scalars['String'];
};

export enum StorageItemStatus {
    Created = 'CREATED',
    InTransit = 'IN_TRANSIT',
    AtCustomerLocation = 'AT_CUSTOMER_LOCATION',
    InStorage = 'IN_STORAGE',
    Archived = 'ARCHIVED',
    Lost = 'LOST',
}

export type StorageItemConnection = {
    __typename?: 'StorageItemConnection';
    pageInfo: PageInfo;
    edges: Array<StorageItemEdge>;
};

export type StorageItemEdge = {
    __typename?: 'StorageItemEdge';
    cursor: Scalars['String'];
    node: StorageItem;
};

export enum PorpertyType {
    StringSingleLine = 'STRING_SINGLE_LINE',
    StringMultiLine = 'STRING_MULTI_LINE',
    ListDropdown = 'LIST_DROPDOWN',
    ListCheckbox = 'LIST_CHECKBOX',
    ListRadio = 'LIST_RADIO',
    Boolean = 'BOOLEAN',
    NumberInteger = 'NUMBER_INTEGER',
    NumberDecimal = 'NUMBER_DECIMAL',
}

export type StorageItemMetadataDefinition = {
    __typename?: 'StorageItemMetadataDefinition';
    id: Scalars['ID'];
    propertyName: Scalars['String'];
    isRequired: Scalars['Boolean'];
    propertyType?: Maybe<PorpertyType>;
    values?: Maybe<Array<Scalars['String']>>;
    isActive: Scalars['Boolean'];
    sortOrder: Scalars['Int'];
};

export type StorageItemMetadataValue = {
    __typename?: 'StorageItemMetadataValue';
    id: Scalars['ID'];
    value?: Maybe<Scalars['String']>;
    metadataDefinition?: Maybe<StorageItemMetadataDefinition>;
};

export type StorageItemTypeConnection = {
    __typename?: 'StorageItemTypeConnection';
    pageInfo: PageInfo;
    edges: Array<StorageItemTypeEdge>;
};

export type StorageItemTypeEdge = {
    __typename?: 'StorageItemTypeEdge';
    cursor: Scalars['String'];
    node: StorageItemType;
};

export type StorageItemTypeCategory = {
    __typename?: 'StorageItemTypeCategory';
    id: Scalars['ID'];
    name: Scalars['String'];
    parentId?: Maybe<Scalars['String']>;
    parentCategory?: Maybe<StorageItemTypeCategory>;
    subCategories?: Maybe<Array<StorageItemTypeCategory>>;
    types?: Maybe<Array<StorageItemType>>;
};

export type TerritoryConnection = {
    __typename?: 'TerritoryConnection';
    pageInfo: PageInfo;
    edges: Array<TerritoryEdge>;
};

export type TerritoryEdge = {
    __typename?: 'TerritoryEdge';
    cursor: Scalars['String'];
    node: Territory;
};

export type User = Customer | Employee;

export type Vehicle = {
    __typename?: 'Vehicle';
    id: Scalars['ID'];
    created_at: Scalars['DateTime'];
    description: Scalars['String'];
    model?: Maybe<Scalars['String']>;
    manufacture_year: Scalars['Int'];
    vin?: Maybe<Scalars['String']>;
    image?: Maybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
};

export type VehicleConnection = {
    __typename?: 'VehicleConnection';
    pageInfo: PageInfo;
    edges: Array<VehicleEdge>;
};

export type VehicleEdge = {
    __typename?: 'VehicleEdge';
    cursor: Scalars['String'];
    node: Vehicle;
};

export enum VisitStatus {
    Pending = 'PENDING',
    Created = 'CREATED',
    Reviewed = 'REVIEWED',
    InTransit = 'IN_TRANSIT',
    Completed = 'COMPLETED',
    Cancelled = 'CANCELLED',
    BeingPicked = 'BEING_PICKED',
    PickingProcessed = 'PICKING_PROCESSED',
}

export enum VisitType {
    Incoming = 'INCOMING',
    Outgoing = 'OUTGOING',
}

export type VisitSummary = {
    __typename?: 'VisitSummary';
    inComing: Scalars['Int'];
    outGoing: Scalars['Int'];
    multiplePeople: Scalars['Boolean'];
    firstVisit: Scalars['Boolean'];
};

export type VisitConnection = {
    __typename?: 'VisitConnection';
    pageInfo: PageInfo;
    edges: Array<VisitEdge>;
};

export type VisitEdge = {
    __typename?: 'VisitEdge';
    cursor: Scalars['String'];
    node: Visit;
};

export type VisitFilterInput = {
    otherFilter?: Maybe<FilterInput>;
    sorts?: Maybe<Array<OrderBy>>;
    fromDate?: Maybe<Scalars['DateTime']>;
    toDate?: Maybe<Scalars['DateTime']>;
    territoryId?: Maybe<Scalars['String']>;
    areaId?: Maybe<Scalars['String']>;
};

export type ServiceProviderContextQueryVariables = Exact<{ [key: string]: never }>;

export type ServiceProviderContextQuery = { __typename?: 'Query' } & {
    serviceProvider?: Maybe<
        { __typename?: 'ServiceProvider' } & Pick<
            ServiceProvider,
            | 'name'
            | 'customerUserPool'
            | 'cognitoRegion'
            | 'cognitoWebApplicationId'
            | 'cognitoHostedDomain'
            | 'cognitoSocialSignInCallback'
            | 'cognitoSocialSignOutCallback'
        >
    >;
};

export type HomeQueryVariables = Exact<{ [key: string]: never }>;

export type HomeQuery = { __typename?: 'Query' } & {
    serviceProvider?: Maybe<
        { __typename?: 'ServiceProvider' } & Pick<ServiceProvider, 'id' | 'name'>
    >;
    currentCustomer?: Maybe<
        { __typename?: 'Customer' } & Pick<Customer, 'id' | 'email'> & {
                addresses: { __typename?: 'CustomerAddressConnection' } & {
                    edges: Array<
                        { __typename?: 'CustomerAddressEdge' } & Pick<
                            CustomerAddressEdge,
                            'cursor'
                        > & {
                                node: { __typename?: 'CustomerAddress' } & Pick<
                                    CustomerAddress,
                                    | 'id'
                                    | 'address1'
                                    | 'address2'
                                    | 'city'
                                    | 'zoneId'
                                    | 'countryId'
                                    | 'zip'
                                    | 'longitude'
                                    | 'latitude'
                                    | 'firstName'
                                    | 'lastName'
                                    | 'addressType'
                                >;
                            }
                    >;
                    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
                };
            }
    >;
};

export type InventoryStorageContainerFragment = { __typename?: 'StorageContainer' } & Pick<
    StorageContainer,
    'id' | 'status' | 'barcode'
> & {
        assignedStorageItem?: Maybe<{ __typename?: 'StorageItem' } & Pick<StorageItem, 'id'>>;
        containerType: { __typename?: 'StorageContainerType' } & Pick<
            StorageContainerType,
            'id' | 'description'
        >;
    };

export type RetrieveStorageContainerQueryVariables = Exact<{
    itemId?: Maybe<Scalars['String']>;
}>;

export type RetrieveStorageContainerQuery = { __typename?: 'Query' } & {
    currentCustomer?: Maybe<
        { __typename?: 'Customer' } & Pick<Customer, 'id'> & {
                storageContainers: { __typename?: 'StorageContainerConnection' } & {
                    edges: Array<
                        { __typename?: 'StorageContainerEdge' } & {
                            node: { __typename?: 'StorageContainer' } & Pick<
                                StorageContainer,
                                'id'
                            > &
                                InventoryStorageContainerFragment;
                        }
                    >;
                };
            }
    >;
};

export type RetrieveStorageContainersQueryVariables = Exact<{
    pageSize?: Maybe<Scalars['Int']>;
    cursor?: Maybe<Scalars['String']>;
}>;

export type RetrieveStorageContainersQuery = { __typename?: 'Query' } & {
    currentCustomer?: Maybe<
        { __typename?: 'Customer' } & Pick<Customer, 'id'> & {
                storageContainers: { __typename?: 'StorageContainerConnection' } & {
                    edges: Array<
                        { __typename?: 'StorageContainerEdge' } & Pick<
                            StorageContainerEdge,
                            'cursor'
                        > & {
                                node: { __typename?: 'StorageContainer' } & Pick<
                                    StorageContainer,
                                    'id'
                                > &
                                    InventoryStorageContainerFragment;
                            }
                    >;
                    pageInfo: { __typename?: 'PageInfo' } & Pick<
                        PageInfo,
                        'hasNextPage' | 'hasPreviousPage'
                    > & {
                            cursor: { __typename?: 'PageCursor' } & Pick<
                                PageCursor,
                                'beforeCursor' | 'afterCursor'
                            >;
                        };
                };
            }
    >;
};

export type RetrieveStorageContainerItemQueryVariables = Exact<{
    itemId?: Maybe<Scalars['String']>;
}>;

export type RetrieveStorageContainerItemQuery = { __typename?: 'Query' } & {
    currentCustomer?: Maybe<
        { __typename?: 'Customer' } & Pick<Customer, 'id'> & {
                storageItems: { __typename?: 'StorageItemConnection' } & {
                    edges: Array<
                        { __typename?: 'StorageItemEdge' } & {
                            node: { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> &
                                InventoryStorageItemFragment;
                        }
                    >;
                };
            }
    >;
};

export type AssignStorageContainerMutationVariables = Exact<{
    storageItemId: Scalars['ID'];
    storageContainerId: Scalars['ID'];
}>;

export type AssignStorageContainerMutation = { __typename?: 'Mutation' } & {
    assignStorageContainer: { __typename?: 'AssignStorageContainerPayload' } & {
        storageItem: { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> &
            InventoryStorageItemFragment;
    };
};

export type UnassignStorageContainerMutationVariables = Exact<{
    storageContainerId: Scalars['ID'];
}>;

export type UnassignStorageContainerMutation = { __typename?: 'Mutation' } & {
    unassignStorageContainer: { __typename?: 'UnassignStorageContainerPayload' } & {
        storageItem: { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> &
            InventoryStorageItemFragment;
    };
};

export type InventoryStorageItemFragment = { __typename?: 'StorageItem' } & Pick<
    StorageItem,
    'id' | 'status'
> & {
        storageItemType: { __typename?: 'StorageItemType' } & Pick<
            StorageItemType,
            'id' | 'name' | 'description' | 'image'
        > & {
                metadataDefinitions?: Maybe<
                    Array<
                        { __typename?: 'StorageItemMetadataDefinition' } & Pick<
                            StorageItemMetadataDefinition,
                            | 'id'
                            | 'propertyName'
                            | 'isRequired'
                            | 'propertyType'
                            | 'values'
                            | 'isActive'
                            | 'sortOrder'
                        >
                    >
                >;
                containerType?: Maybe<
                    { __typename?: 'StorageContainerType' } & Pick<
                        StorageContainerType,
                        'id' | 'description'
                    >
                >;
            };
        metadataValue?: Maybe<
            Array<
                { __typename?: 'StorageItemMetadataValue' } & Pick<
                    StorageItemMetadataValue,
                    'id' | 'value'
                > & {
                        metadataDefinition?: Maybe<
                            { __typename?: 'StorageItemMetadataDefinition' } & Pick<
                                StorageItemMetadataDefinition,
                                'id' | 'propertyName'
                            >
                        >;
                    }
            >
        >;
        visits: { __typename?: 'VisitConnection' } & {
            edges: Array<
                { __typename?: 'VisitEdge' } & {
                    node: { __typename?: 'Visit' } & Pick<Visit, 'startTime' | 'endTime'>;
                }
            >;
        };
        container?: Maybe<{ __typename?: 'StorageContainer' } & Pick<StorageContainer, 'id'>>;
    };

export type RetrieveStorageItemQueryVariables = Exact<{
    itemId: Scalars['String'];
}>;

export type RetrieveStorageItemQuery = { __typename?: 'Query' } & {
    storageItemById?: Maybe<
        { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> & InventoryStorageItemFragment
    >;
};

export type SetStorageItemMetadataMutationVariables = Exact<{
    data: SetStorageItemMetadataInput;
}>;

export type SetStorageItemMetadataMutation = { __typename?: 'Mutation' } & {
    setStorageItemMetadata: { __typename?: 'SetStorageItemMetadataPayload' } & {
        values?: Maybe<
            Array<
                { __typename?: 'StorageItemMetadataValue' } & Pick<
                    StorageItemMetadataValue,
                    'id' | 'value'
                > & {
                        metadataDefinition?: Maybe<
                            { __typename?: 'StorageItemMetadataDefinition' } & Pick<
                                StorageItemMetadataDefinition,
                                'id' | 'propertyName'
                            >
                        >;
                    }
            >
        >;
    };
};

export type RetrieveStorageItemsQueryVariables = Exact<{
    pageSize?: Maybe<Scalars['Int']>;
    cursor?: Maybe<Scalars['String']>;
}>;

export type RetrieveStorageItemsQuery = { __typename?: 'Query' } & {
    currentCustomer?: Maybe<
        { __typename?: 'Customer' } & Pick<Customer, 'id'> & {
                storageItems: { __typename?: 'StorageItemConnection' } & {
                    edges: Array<
                        { __typename?: 'StorageItemEdge' } & Pick<StorageItemEdge, 'cursor'> & {
                                node: { __typename?: 'StorageItem' } & Pick<StorageItem, 'id'> &
                                    InventoryStorageItemFragment;
                            }
                    >;
                    pageInfo: { __typename?: 'PageInfo' } & Pick<
                        PageInfo,
                        'hasNextPage' | 'hasPreviousPage'
                    > & {
                            cursor: { __typename?: 'PageCursor' } & Pick<
                                PageCursor,
                                'beforeCursor' | 'afterCursor'
                            >;
                        };
                };
            }
    >;
};

export type AddressSelectionAddressFragmentFragment = { __typename?: 'CustomerAddress' } & Pick<
    CustomerAddress,
    | 'id'
    | 'address1'
    | 'address2'
    | 'city'
    | 'zoneId'
    | 'countryId'
    | 'zip'
    | 'longitude'
    | 'latitude'
    | 'phone'
    | 'firstName'
    | 'lastName'
>;

export type RetrieveAddressesQueryVariables = Exact<{
    pageSize?: Maybe<Scalars['Int']>;
    cursor?: Maybe<Scalars['String']>;
}>;

export type RetrieveAddressesQuery = { __typename?: 'Query' } & {
    currentCustomer?: Maybe<
        { __typename?: 'Customer' } & Pick<Customer, 'id'> & {
                addresses: { __typename?: 'CustomerAddressConnection' } & {
                    edges: Array<
                        { __typename?: 'CustomerAddressEdge' } & Pick<
                            CustomerAddressEdge,
                            'cursor'
                        > & {
                                node: { __typename?: 'CustomerAddress' } & Pick<
                                    CustomerAddress,
                                    'id'
                                > &
                                    AddressSelectionAddressFragmentFragment;
                            }
                    >;
                    pageInfo: { __typename?: 'PageInfo' } & Pick<
                        PageInfo,
                        'hasNextPage' | 'hasPreviousPage'
                    > & {
                            cursor: { __typename?: 'PageCursor' } & Pick<
                                PageCursor,
                                'beforeCursor' | 'afterCursor'
                            >;
                        };
                };
            }
    >;
};

export type AddAddressMutationVariables = Exact<{
    customerAddress: AddCustomerAddressInput;
}>;

export type AddAddressMutation = { __typename?: 'Mutation' } & {
    addCustomerAddress: { __typename?: 'AddCustomerAddressPayload' } & {
        customerAddress?: Maybe<
            { __typename?: 'CustomerAddress' } & Pick<
                CustomerAddress,
                | 'id'
                | 'address1'
                | 'address2'
                | 'city'
                | 'zoneId'
                | 'countryId'
                | 'zip'
                | 'longitude'
                | 'latitude'
                | 'phone'
                | 'firstName'
                | 'lastName'
            >
        >;
    };
};

export type PickupItemSelectionStorageItemTypeFragmentFragment = {
    __typename?: 'StorageItemType';
} & Pick<StorageItemType, 'id' | 'name' | 'description' | 'image' | 'maximumRequestQuantity'> & {
        containerType?: Maybe<
            { __typename?: 'StorageContainerType' } & Pick<StorageContainerType, 'id'>
        >;
    };

export type PickupStorageItemTypesQueryVariables = Exact<{
    cursor?: Maybe<Scalars['String']>;
    pageSize?: Maybe<Scalars['Int']>;
}>;

export type PickupStorageItemTypesQuery = { __typename?: 'Query' } & {
    serviceProvider?: Maybe<
        { __typename?: 'ServiceProvider' } & Pick<ServiceProvider, 'id'> & {
                storageItemTypes: { __typename?: 'StorageItemTypeConnection' } & {
                    edges: Array<
                        { __typename?: 'StorageItemTypeEdge' } & Pick<
                            StorageItemTypeEdge,
                            'cursor'
                        > & {
                                node: { __typename?: 'StorageItemType' } & Pick<
                                    StorageItemType,
                                    'id'
                                > &
                                    PickupItemSelectionStorageItemTypeFragmentFragment;
                            }
                    >;
                    pageInfo: { __typename?: 'PageInfo' } & Pick<
                        PageInfo,
                        'hasNextPage' | 'hasPreviousPage'
                    > & {
                            cursor: { __typename?: 'PageCursor' } & Pick<
                                PageCursor,
                                'beforeCursor' | 'afterCursor'
                            >;
                        };
                };
            }
    >;
};

export type AddPaymentMethodMutationVariables = Exact<{
    paymentMethod: AddCustomerPaymentMethodInput;
}>;

export type AddPaymentMethodMutation = { __typename?: 'Mutation' } & {
    addCustomerPaymentMethod: { __typename?: 'AddCustomerPaymentMethodPayload' } & {
        paymentMethod?: Maybe<
            { __typename?: 'PaymentMethod' } & Pick<
                PaymentMethod,
                'id' | 'description' | 'isDefault'
            >
        >;
    };
};

export type RetrievePaymentMethodsQueryVariables = Exact<{
    pageSize?: Maybe<Scalars['Int']>;
    cursor?: Maybe<Scalars['String']>;
}>;

export type RetrievePaymentMethodsQuery = { __typename?: 'Query' } & {
    currentCustomer?: Maybe<
        { __typename?: 'Customer' } & Pick<Customer, 'id'> & {
                paymentMethods: { __typename?: 'PaymentMethodConnection' } & {
                    edges: Array<
                        { __typename?: 'PaymentMethodEdge' } & Pick<PaymentMethodEdge, 'cursor'> & {
                                node: { __typename?: 'PaymentMethod' } & Pick<
                                    PaymentMethod,
                                    'id' | 'description' | 'isDefault'
                                >;
                            }
                    >;
                    pageInfo: { __typename?: 'PageInfo' } & Pick<
                        PageInfo,
                        'hasNextPage' | 'hasPreviousPage'
                    > & {
                            cursor: { __typename?: 'PageCursor' } & Pick<
                                PageCursor,
                                'beforeCursor' | 'afterCursor'
                            >;
                        };
                };
            }
    >;
};

export type SaveVisitMutationVariables = Exact<{
    saveVisitData: SaveVisitInput;
}>;

export type SaveVisitMutation = { __typename?: 'Mutation' } & {
    saveVisit: { __typename?: 'SaveVisitPayload' } & {
        visit?: Maybe<
            { __typename?: 'Visit' } & Pick<
                Visit,
                'id' | 'status' | 'startTime' | 'endTime' | 'expectedDuration'
            >
        >;
    };
};

export type RetrieveScheduleWindowsQueryVariables = Exact<{
    zipCode: Scalars['String'];
    startDate: Scalars['DateTime'];
    endDate: Scalars['DateTime'];
}>;

export type RetrieveScheduleWindowsQuery = { __typename?: 'Query' } & {
    territoryByZipCode?: Maybe<
        { __typename?: 'Territory' } & {
            schedule?: Maybe<
                Array<
                    { __typename?: 'ScheduleWindow' } & Pick<
                        ScheduleWindow,
                        'startTime' | 'endTime' | 'isLocked'
                    >
                >
            >;
        }
    >;
};

export type VisitSelectionVisitFragmentFragment = { __typename?: 'Visit' } & Pick<
    Visit,
    'id' | 'status' | 'startTime' | 'endTime' | 'expectedDuration'
>;

export type RetrieveVisitsQueryVariables = Exact<{
    pageSize?: Maybe<Scalars['Int']>;
    cursor?: Maybe<Scalars['String']>;
    customerAddressId?: Maybe<Scalars['String']>;
}>;

export type RetrieveVisitsQuery = { __typename?: 'Query' } & {
    currentCustomer?: Maybe<
        { __typename?: 'Customer' } & Pick<Customer, 'id'> & {
                visits: { __typename?: 'VisitConnection' } & {
                    edges: Array<
                        { __typename?: 'VisitEdge' } & Pick<VisitEdge, 'cursor'> & {
                                node: { __typename?: 'Visit' } & Pick<Visit, 'id'> &
                                    VisitSelectionVisitFragmentFragment;
                            }
                    >;
                    pageInfo: { __typename?: 'PageInfo' } & Pick<
                        PageInfo,
                        'hasNextPage' | 'hasPreviousPage'
                    > & {
                            cursor: { __typename?: 'PageCursor' } & Pick<
                                PageCursor,
                                'beforeCursor' | 'afterCursor'
                            >;
                        };
                };
            }
    >;
};

export type VisitManagementFragment = { __typename?: 'Visit' } & Pick<
    Visit,
    'id' | 'status' | 'startTime' | 'endTime' | 'expectedDuration'
>;

export type RetrieveAllVisitsQueryVariables = Exact<{
    pageSize?: Maybe<Scalars['Int']>;
    cursor?: Maybe<Scalars['String']>;
}>;

export type RetrieveAllVisitsQuery = { __typename?: 'Query' } & {
    currentCustomer?: Maybe<
        { __typename?: 'Customer' } & Pick<Customer, 'id'> & {
                visits: { __typename?: 'VisitConnection' } & {
                    edges: Array<
                        { __typename?: 'VisitEdge' } & Pick<VisitEdge, 'cursor'> & {
                                node: { __typename?: 'Visit' } & Pick<Visit, 'id'> &
                                    VisitManagementFragment;
                            }
                    >;
                    pageInfo: { __typename?: 'PageInfo' } & Pick<
                        PageInfo,
                        'hasNextPage' | 'hasPreviousPage'
                    > & {
                            cursor: { __typename?: 'PageCursor' } & Pick<
                                PageCursor,
                                'beforeCursor' | 'afterCursor'
                            >;
                        };
                };
            }
    >;
};

export type VisitManagementDetailFragment = { __typename?: 'Visit' } & Pick<
    Visit,
    'id' | 'status' | 'startTime' | 'endTime' | 'expectedDuration'
> & { customerAddress: { __typename?: 'CustomerAddress' } & Pick<CustomerAddress, 'zip'> };

export type GetVisitManagementByIdQueryVariables = Exact<{
    visitId: Scalars['String'];
}>;

export type GetVisitManagementByIdQuery = { __typename?: 'Query' } & {
    visitById?: Maybe<{ __typename?: 'Visit' } & VisitManagementDetailFragment>;
};

export type UpdateVisitManagementMutationVariables = Exact<{
    data: VisitUpdateWindowInput;
}>;

export type UpdateVisitManagementMutation = { __typename?: 'Mutation' } & {
    visitUpdateWindow: { __typename?: 'VisitUpdateWindowPayload' } & {
        data: { __typename?: 'Visit' } & VisitManagementDetailFragment;
    };
};

export type VisitCancelVisitMutationVariables = Exact<{
    data: VisitCancelVisitInput;
}>;

export type VisitCancelVisitMutation = { __typename?: 'Mutation' } & {
    visitCancelVisit: { __typename?: 'VisitCancelVisitPayload' } & {
        data: { __typename?: 'Visit' } & VisitManagementDetailFragment;
    };
};

export const InventoryStorageContainerFragmentDoc = gql`
    fragment InventoryStorageContainer on StorageContainer {
        id
        status
        barcode
        assignedStorageItem {
            id
        }
        containerType {
            id
            description
        }
    }
`;
export const InventoryStorageItemFragmentDoc = gql`
    fragment InventoryStorageItem on StorageItem {
        id
        status
        storageItemType {
            id
            name
            description
            image
            metadataDefinitions {
                id
                propertyName
                isRequired
                propertyType
                values
                isActive
                sortOrder
            }
            containerType {
                id
                description
            }
        }
        metadataValue {
            id
            value
            metadataDefinition {
                id
                propertyName
            }
        }
        visits(filter: {}) {
            edges {
                node {
                    startTime
                    endTime
                }
            }
        }
        container {
            id
        }
    }
`;
export const AddressSelectionAddressFragmentFragmentDoc = gql`
    fragment AddressSelectionAddressFragment on CustomerAddress {
        id
        address1
        address2
        city
        zoneId
        countryId
        zip
        longitude
        latitude
        phone
        firstName
        lastName
    }
`;
export const PickupItemSelectionStorageItemTypeFragmentFragmentDoc = gql`
    fragment PickupItemSelectionStorageItemTypeFragment on StorageItemType {
        id
        name
        description
        image
        maximumRequestQuantity
        containerType {
            id
        }
    }
`;
export const VisitSelectionVisitFragmentFragmentDoc = gql`
    fragment VisitSelectionVisitFragment on Visit {
        id
        status
        startTime
        endTime
        expectedDuration
    }
`;
export const VisitManagementFragmentDoc = gql`
    fragment VisitManagement on Visit {
        id
        status
        startTime
        endTime
        expectedDuration
    }
`;
export const VisitManagementDetailFragmentDoc = gql`
    fragment VisitManagementDetail on Visit {
        id
        status
        startTime
        endTime
        expectedDuration
        customerAddress {
            zip
        }
    }
`;
export const ServiceProviderContextDocument = gql`
    query serviceProviderContext {
        serviceProvider {
            name
            customerUserPool
            cognitoRegion
            cognitoWebApplicationId
            cognitoHostedDomain
            cognitoSocialSignInCallback
            cognitoSocialSignOutCallback
        }
    }
`;

/**
 * __useServiceProviderContextQuery__
 *
 * To run a query within a React component, call `useServiceProviderContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceProviderContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceProviderContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceProviderContextQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ServiceProviderContextQuery,
        ServiceProviderContextQueryVariables
    >,
) {
    return Apollo.useQuery<ServiceProviderContextQuery, ServiceProviderContextQueryVariables>(
        ServiceProviderContextDocument,
        baseOptions,
    );
}
export function useServiceProviderContextLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ServiceProviderContextQuery,
        ServiceProviderContextQueryVariables
    >,
) {
    return Apollo.useLazyQuery<ServiceProviderContextQuery, ServiceProviderContextQueryVariables>(
        ServiceProviderContextDocument,
        baseOptions,
    );
}
export type ServiceProviderContextQueryHookResult = ReturnType<
    typeof useServiceProviderContextQuery
>;
export type ServiceProviderContextLazyQueryHookResult = ReturnType<
    typeof useServiceProviderContextLazyQuery
>;
export type ServiceProviderContextQueryResult = Apollo.QueryResult<
    ServiceProviderContextQuery,
    ServiceProviderContextQueryVariables
>;
export const HomeDocument = gql`
    query Home {
        serviceProvider {
            id
            name
        }
        currentCustomer {
            id
            email
            addresses(filter: { pageSize: 100 }) {
                edges {
                    cursor
                    node {
                        id
                        address1
                        address2
                        city
                        zoneId
                        countryId
                        zip
                        longitude
                        latitude
                        firstName
                        lastName
                        addressType
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    }
`;

/**
 * __useHomeQuery__
 *
 * To run a query within a React component, call `useHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
    return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, baseOptions);
}
export function useHomeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>,
) {
    return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, baseOptions);
}
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const RetrieveStorageContainerDocument = gql`
    query RetrieveStorageContainer($itemId: String) {
        currentCustomer {
            id
            storageContainers(
                filter: { conditions: { field: "id", value: $itemId, operator: Equal } }
            ) {
                edges {
                    node {
                        id
                        ...InventoryStorageContainer
                    }
                }
            }
        }
    }
    ${InventoryStorageContainerFragmentDoc}
`;

/**
 * __useRetrieveStorageContainerQuery__
 *
 * To run a query within a React component, call `useRetrieveStorageContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveStorageContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveStorageContainerQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useRetrieveStorageContainerQuery(
    baseOptions?: Apollo.QueryHookOptions<
        RetrieveStorageContainerQuery,
        RetrieveStorageContainerQueryVariables
    >,
) {
    return Apollo.useQuery<RetrieveStorageContainerQuery, RetrieveStorageContainerQueryVariables>(
        RetrieveStorageContainerDocument,
        baseOptions,
    );
}
export function useRetrieveStorageContainerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrieveStorageContainerQuery,
        RetrieveStorageContainerQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        RetrieveStorageContainerQuery,
        RetrieveStorageContainerQueryVariables
    >(RetrieveStorageContainerDocument, baseOptions);
}
export type RetrieveStorageContainerQueryHookResult = ReturnType<
    typeof useRetrieveStorageContainerQuery
>;
export type RetrieveStorageContainerLazyQueryHookResult = ReturnType<
    typeof useRetrieveStorageContainerLazyQuery
>;
export type RetrieveStorageContainerQueryResult = Apollo.QueryResult<
    RetrieveStorageContainerQuery,
    RetrieveStorageContainerQueryVariables
>;
export const RetrieveStorageContainersDocument = gql`
    query RetrieveStorageContainers($pageSize: Int, $cursor: String) {
        currentCustomer {
            id
            storageContainers(
                filter: {
                    pageSize: $pageSize
                    after: $cursor
                    conditions: { field: "status", value: "IN_USE", operator: Equal }
                }
            ) {
                edges {
                    cursor
                    node {
                        id
                        ...InventoryStorageContainer
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${InventoryStorageContainerFragmentDoc}
`;

/**
 * __useRetrieveStorageContainersQuery__
 *
 * To run a query within a React component, call `useRetrieveStorageContainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveStorageContainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveStorageContainersQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useRetrieveStorageContainersQuery(
    baseOptions?: Apollo.QueryHookOptions<
        RetrieveStorageContainersQuery,
        RetrieveStorageContainersQueryVariables
    >,
) {
    return Apollo.useQuery<RetrieveStorageContainersQuery, RetrieveStorageContainersQueryVariables>(
        RetrieveStorageContainersDocument,
        baseOptions,
    );
}
export function useRetrieveStorageContainersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrieveStorageContainersQuery,
        RetrieveStorageContainersQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        RetrieveStorageContainersQuery,
        RetrieveStorageContainersQueryVariables
    >(RetrieveStorageContainersDocument, baseOptions);
}
export type RetrieveStorageContainersQueryHookResult = ReturnType<
    typeof useRetrieveStorageContainersQuery
>;
export type RetrieveStorageContainersLazyQueryHookResult = ReturnType<
    typeof useRetrieveStorageContainersLazyQuery
>;
export type RetrieveStorageContainersQueryResult = Apollo.QueryResult<
    RetrieveStorageContainersQuery,
    RetrieveStorageContainersQueryVariables
>;
export const RetrieveStorageContainerItemDocument = gql`
    query RetrieveStorageContainerItem($itemId: String) {
        currentCustomer {
            id
            storageItems(filter: { conditions: { field: "id", value: $itemId, operator: Equal } }) {
                edges {
                    node {
                        id
                        ...InventoryStorageItem
                    }
                }
            }
        }
    }
    ${InventoryStorageItemFragmentDoc}
`;

/**
 * __useRetrieveStorageContainerItemQuery__
 *
 * To run a query within a React component, call `useRetrieveStorageContainerItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveStorageContainerItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveStorageContainerItemQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useRetrieveStorageContainerItemQuery(
    baseOptions?: Apollo.QueryHookOptions<
        RetrieveStorageContainerItemQuery,
        RetrieveStorageContainerItemQueryVariables
    >,
) {
    return Apollo.useQuery<
        RetrieveStorageContainerItemQuery,
        RetrieveStorageContainerItemQueryVariables
    >(RetrieveStorageContainerItemDocument, baseOptions);
}
export function useRetrieveStorageContainerItemLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrieveStorageContainerItemQuery,
        RetrieveStorageContainerItemQueryVariables
    >,
) {
    return Apollo.useLazyQuery<
        RetrieveStorageContainerItemQuery,
        RetrieveStorageContainerItemQueryVariables
    >(RetrieveStorageContainerItemDocument, baseOptions);
}
export type RetrieveStorageContainerItemQueryHookResult = ReturnType<
    typeof useRetrieveStorageContainerItemQuery
>;
export type RetrieveStorageContainerItemLazyQueryHookResult = ReturnType<
    typeof useRetrieveStorageContainerItemLazyQuery
>;
export type RetrieveStorageContainerItemQueryResult = Apollo.QueryResult<
    RetrieveStorageContainerItemQuery,
    RetrieveStorageContainerItemQueryVariables
>;
export const AssignStorageContainerDocument = gql`
    mutation assignStorageContainer($storageItemId: ID!, $storageContainerId: ID!) {
        assignStorageContainer(
            input: { storageItemId: $storageItemId, storageContainerId: $storageContainerId }
        ) {
            storageItem {
                id
                ...InventoryStorageItem
            }
        }
    }
    ${InventoryStorageItemFragmentDoc}
`;
export type AssignStorageContainerMutationFn = Apollo.MutationFunction<
    AssignStorageContainerMutation,
    AssignStorageContainerMutationVariables
>;

/**
 * __useAssignStorageContainerMutation__
 *
 * To run a mutation, you first call `useAssignStorageContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignStorageContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignStorageContainerMutation, { data, loading, error }] = useAssignStorageContainerMutation({
 *   variables: {
 *      storageItemId: // value for 'storageItemId'
 *      storageContainerId: // value for 'storageContainerId'
 *   },
 * });
 */
export function useAssignStorageContainerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AssignStorageContainerMutation,
        AssignStorageContainerMutationVariables
    >,
) {
    return Apollo.useMutation<
        AssignStorageContainerMutation,
        AssignStorageContainerMutationVariables
    >(AssignStorageContainerDocument, baseOptions);
}
export type AssignStorageContainerMutationHookResult = ReturnType<
    typeof useAssignStorageContainerMutation
>;
export type AssignStorageContainerMutationResult = Apollo.MutationResult<AssignStorageContainerMutation>;
export type AssignStorageContainerMutationOptions = Apollo.BaseMutationOptions<
    AssignStorageContainerMutation,
    AssignStorageContainerMutationVariables
>;
export const UnassignStorageContainerDocument = gql`
    mutation unassignStorageContainer($storageContainerId: ID!) {
        unassignStorageContainer(input: { storageContainerId: $storageContainerId }) {
            storageItem {
                id
                ...InventoryStorageItem
            }
        }
    }
    ${InventoryStorageItemFragmentDoc}
`;
export type UnassignStorageContainerMutationFn = Apollo.MutationFunction<
    UnassignStorageContainerMutation,
    UnassignStorageContainerMutationVariables
>;

/**
 * __useUnassignStorageContainerMutation__
 *
 * To run a mutation, you first call `useUnassignStorageContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignStorageContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignStorageContainerMutation, { data, loading, error }] = useUnassignStorageContainerMutation({
 *   variables: {
 *      storageContainerId: // value for 'storageContainerId'
 *   },
 * });
 */
export function useUnassignStorageContainerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UnassignStorageContainerMutation,
        UnassignStorageContainerMutationVariables
    >,
) {
    return Apollo.useMutation<
        UnassignStorageContainerMutation,
        UnassignStorageContainerMutationVariables
    >(UnassignStorageContainerDocument, baseOptions);
}
export type UnassignStorageContainerMutationHookResult = ReturnType<
    typeof useUnassignStorageContainerMutation
>;
export type UnassignStorageContainerMutationResult = Apollo.MutationResult<UnassignStorageContainerMutation>;
export type UnassignStorageContainerMutationOptions = Apollo.BaseMutationOptions<
    UnassignStorageContainerMutation,
    UnassignStorageContainerMutationVariables
>;
export const RetrieveStorageItemDocument = gql`
    query RetrieveStorageItem($itemId: String!) {
        storageItemById(id: $itemId) {
            id
            ...InventoryStorageItem
        }
    }
    ${InventoryStorageItemFragmentDoc}
`;

/**
 * __useRetrieveStorageItemQuery__
 *
 * To run a query within a React component, call `useRetrieveStorageItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveStorageItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveStorageItemQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useRetrieveStorageItemQuery(
    baseOptions: Apollo.QueryHookOptions<
        RetrieveStorageItemQuery,
        RetrieveStorageItemQueryVariables
    >,
) {
    return Apollo.useQuery<RetrieveStorageItemQuery, RetrieveStorageItemQueryVariables>(
        RetrieveStorageItemDocument,
        baseOptions,
    );
}
export function useRetrieveStorageItemLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrieveStorageItemQuery,
        RetrieveStorageItemQueryVariables
    >,
) {
    return Apollo.useLazyQuery<RetrieveStorageItemQuery, RetrieveStorageItemQueryVariables>(
        RetrieveStorageItemDocument,
        baseOptions,
    );
}
export type RetrieveStorageItemQueryHookResult = ReturnType<typeof useRetrieveStorageItemQuery>;
export type RetrieveStorageItemLazyQueryHookResult = ReturnType<
    typeof useRetrieveStorageItemLazyQuery
>;
export type RetrieveStorageItemQueryResult = Apollo.QueryResult<
    RetrieveStorageItemQuery,
    RetrieveStorageItemQueryVariables
>;
export const SetStorageItemMetadataDocument = gql`
    mutation setStorageItemMetadata($data: SetStorageItemMetadataInput!) {
        setStorageItemMetadata(data: $data) {
            values {
                id
                value
                metadataDefinition {
                    id
                    propertyName
                }
            }
        }
    }
`;
export type SetStorageItemMetadataMutationFn = Apollo.MutationFunction<
    SetStorageItemMetadataMutation,
    SetStorageItemMetadataMutationVariables
>;

/**
 * __useSetStorageItemMetadataMutation__
 *
 * To run a mutation, you first call `useSetStorageItemMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStorageItemMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStorageItemMetadataMutation, { data, loading, error }] = useSetStorageItemMetadataMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetStorageItemMetadataMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SetStorageItemMetadataMutation,
        SetStorageItemMetadataMutationVariables
    >,
) {
    return Apollo.useMutation<
        SetStorageItemMetadataMutation,
        SetStorageItemMetadataMutationVariables
    >(SetStorageItemMetadataDocument, baseOptions);
}
export type SetStorageItemMetadataMutationHookResult = ReturnType<
    typeof useSetStorageItemMetadataMutation
>;
export type SetStorageItemMetadataMutationResult = Apollo.MutationResult<SetStorageItemMetadataMutation>;
export type SetStorageItemMetadataMutationOptions = Apollo.BaseMutationOptions<
    SetStorageItemMetadataMutation,
    SetStorageItemMetadataMutationVariables
>;
export const RetrieveStorageItemsDocument = gql`
    query RetrieveStorageItems($pageSize: Int, $cursor: String) {
        currentCustomer {
            id
            storageItems(filter: { pageSize: $pageSize, after: $cursor }) {
                edges {
                    cursor
                    node {
                        id
                        ...InventoryStorageItem
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${InventoryStorageItemFragmentDoc}
`;

/**
 * __useRetrieveStorageItemsQuery__
 *
 * To run a query within a React component, call `useRetrieveStorageItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveStorageItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveStorageItemsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useRetrieveStorageItemsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        RetrieveStorageItemsQuery,
        RetrieveStorageItemsQueryVariables
    >,
) {
    return Apollo.useQuery<RetrieveStorageItemsQuery, RetrieveStorageItemsQueryVariables>(
        RetrieveStorageItemsDocument,
        baseOptions,
    );
}
export function useRetrieveStorageItemsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrieveStorageItemsQuery,
        RetrieveStorageItemsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<RetrieveStorageItemsQuery, RetrieveStorageItemsQueryVariables>(
        RetrieveStorageItemsDocument,
        baseOptions,
    );
}
export type RetrieveStorageItemsQueryHookResult = ReturnType<typeof useRetrieveStorageItemsQuery>;
export type RetrieveStorageItemsLazyQueryHookResult = ReturnType<
    typeof useRetrieveStorageItemsLazyQuery
>;
export type RetrieveStorageItemsQueryResult = Apollo.QueryResult<
    RetrieveStorageItemsQuery,
    RetrieveStorageItemsQueryVariables
>;
export const RetrieveAddressesDocument = gql`
    query RetrieveAddresses($pageSize: Int, $cursor: String) {
        currentCustomer {
            id
            addresses(filter: { pageSize: $pageSize, after: $cursor }) {
                edges {
                    cursor
                    node {
                        id
                        ...AddressSelectionAddressFragment
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${AddressSelectionAddressFragmentFragmentDoc}
`;

/**
 * __useRetrieveAddressesQuery__
 *
 * To run a query within a React component, call `useRetrieveAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveAddressesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useRetrieveAddressesQuery(
    baseOptions?: Apollo.QueryHookOptions<RetrieveAddressesQuery, RetrieveAddressesQueryVariables>,
) {
    return Apollo.useQuery<RetrieveAddressesQuery, RetrieveAddressesQueryVariables>(
        RetrieveAddressesDocument,
        baseOptions,
    );
}
export function useRetrieveAddressesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrieveAddressesQuery,
        RetrieveAddressesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<RetrieveAddressesQuery, RetrieveAddressesQueryVariables>(
        RetrieveAddressesDocument,
        baseOptions,
    );
}
export type RetrieveAddressesQueryHookResult = ReturnType<typeof useRetrieveAddressesQuery>;
export type RetrieveAddressesLazyQueryHookResult = ReturnType<typeof useRetrieveAddressesLazyQuery>;
export type RetrieveAddressesQueryResult = Apollo.QueryResult<
    RetrieveAddressesQuery,
    RetrieveAddressesQueryVariables
>;
export const AddAddressDocument = gql`
    mutation addAddress($customerAddress: AddCustomerAddressInput!) {
        addCustomerAddress(data: $customerAddress) {
            customerAddress {
                id
                address1
                address2
                city
                zoneId
                countryId
                zip
                longitude
                latitude
                phone
                firstName
                lastName
            }
        }
    }
`;
export type AddAddressMutationFn = Apollo.MutationFunction<
    AddAddressMutation,
    AddAddressMutationVariables
>;

/**
 * __useAddAddressMutation__
 *
 * To run a mutation, you first call `useAddAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddressMutation, { data, loading, error }] = useAddAddressMutation({
 *   variables: {
 *      customerAddress: // value for 'customerAddress'
 *   },
 * });
 */
export function useAddAddressMutation(
    baseOptions?: Apollo.MutationHookOptions<AddAddressMutation, AddAddressMutationVariables>,
) {
    return Apollo.useMutation<AddAddressMutation, AddAddressMutationVariables>(
        AddAddressDocument,
        baseOptions,
    );
}
export type AddAddressMutationHookResult = ReturnType<typeof useAddAddressMutation>;
export type AddAddressMutationResult = Apollo.MutationResult<AddAddressMutation>;
export type AddAddressMutationOptions = Apollo.BaseMutationOptions<
    AddAddressMutation,
    AddAddressMutationVariables
>;
export const PickupStorageItemTypesDocument = gql`
    query PickupStorageItemTypes($cursor: String, $pageSize: Int) {
        serviceProvider {
            id
            storageItemTypes(filter: { pageSize: $pageSize, after: $cursor }) {
                edges {
                    cursor
                    node {
                        id
                        ...PickupItemSelectionStorageItemTypeFragment
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${PickupItemSelectionStorageItemTypeFragmentFragmentDoc}
`;

/**
 * __usePickupStorageItemTypesQuery__
 *
 * To run a query within a React component, call `usePickupStorageItemTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupStorageItemTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupStorageItemTypesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function usePickupStorageItemTypesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        PickupStorageItemTypesQuery,
        PickupStorageItemTypesQueryVariables
    >,
) {
    return Apollo.useQuery<PickupStorageItemTypesQuery, PickupStorageItemTypesQueryVariables>(
        PickupStorageItemTypesDocument,
        baseOptions,
    );
}
export function usePickupStorageItemTypesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PickupStorageItemTypesQuery,
        PickupStorageItemTypesQueryVariables
    >,
) {
    return Apollo.useLazyQuery<PickupStorageItemTypesQuery, PickupStorageItemTypesQueryVariables>(
        PickupStorageItemTypesDocument,
        baseOptions,
    );
}
export type PickupStorageItemTypesQueryHookResult = ReturnType<
    typeof usePickupStorageItemTypesQuery
>;
export type PickupStorageItemTypesLazyQueryHookResult = ReturnType<
    typeof usePickupStorageItemTypesLazyQuery
>;
export type PickupStorageItemTypesQueryResult = Apollo.QueryResult<
    PickupStorageItemTypesQuery,
    PickupStorageItemTypesQueryVariables
>;
export const AddPaymentMethodDocument = gql`
    mutation addPaymentMethod($paymentMethod: AddCustomerPaymentMethodInput!) {
        addCustomerPaymentMethod(data: $paymentMethod) {
            paymentMethod {
                id
                description
                isDefault
            }
        }
    }
`;
export type AddPaymentMethodMutationFn = Apollo.MutationFunction<
    AddPaymentMethodMutation,
    AddPaymentMethodMutationVariables
>;

/**
 * __useAddPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMethodMutation, { data, loading, error }] = useAddPaymentMethodMutation({
 *   variables: {
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useAddPaymentMethodMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AddPaymentMethodMutation,
        AddPaymentMethodMutationVariables
    >,
) {
    return Apollo.useMutation<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>(
        AddPaymentMethodDocument,
        baseOptions,
    );
}
export type AddPaymentMethodMutationHookResult = ReturnType<typeof useAddPaymentMethodMutation>;
export type AddPaymentMethodMutationResult = Apollo.MutationResult<AddPaymentMethodMutation>;
export type AddPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
    AddPaymentMethodMutation,
    AddPaymentMethodMutationVariables
>;
export const RetrievePaymentMethodsDocument = gql`
    query RetrievePaymentMethods($pageSize: Int, $cursor: String) {
        currentCustomer {
            id
            paymentMethods(filter: { pageSize: $pageSize, after: $cursor }) {
                edges {
                    cursor
                    node {
                        id
                        description
                        isDefault
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
`;

/**
 * __useRetrievePaymentMethodsQuery__
 *
 * To run a query within a React component, call `useRetrievePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrievePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrievePaymentMethodsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useRetrievePaymentMethodsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        RetrievePaymentMethodsQuery,
        RetrievePaymentMethodsQueryVariables
    >,
) {
    return Apollo.useQuery<RetrievePaymentMethodsQuery, RetrievePaymentMethodsQueryVariables>(
        RetrievePaymentMethodsDocument,
        baseOptions,
    );
}
export function useRetrievePaymentMethodsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrievePaymentMethodsQuery,
        RetrievePaymentMethodsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<RetrievePaymentMethodsQuery, RetrievePaymentMethodsQueryVariables>(
        RetrievePaymentMethodsDocument,
        baseOptions,
    );
}
export type RetrievePaymentMethodsQueryHookResult = ReturnType<
    typeof useRetrievePaymentMethodsQuery
>;
export type RetrievePaymentMethodsLazyQueryHookResult = ReturnType<
    typeof useRetrievePaymentMethodsLazyQuery
>;
export type RetrievePaymentMethodsQueryResult = Apollo.QueryResult<
    RetrievePaymentMethodsQuery,
    RetrievePaymentMethodsQueryVariables
>;
export const SaveVisitDocument = gql`
    mutation saveVisit($saveVisitData: SaveVisitInput!) {
        saveVisit(data: $saveVisitData) {
            visit {
                id
                status
                startTime
                endTime
                expectedDuration
            }
        }
    }
`;
export type SaveVisitMutationFn = Apollo.MutationFunction<
    SaveVisitMutation,
    SaveVisitMutationVariables
>;

/**
 * __useSaveVisitMutation__
 *
 * To run a mutation, you first call `useSaveVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVisitMutation, { data, loading, error }] = useSaveVisitMutation({
 *   variables: {
 *      saveVisitData: // value for 'saveVisitData'
 *   },
 * });
 */
export function useSaveVisitMutation(
    baseOptions?: Apollo.MutationHookOptions<SaveVisitMutation, SaveVisitMutationVariables>,
) {
    return Apollo.useMutation<SaveVisitMutation, SaveVisitMutationVariables>(
        SaveVisitDocument,
        baseOptions,
    );
}
export type SaveVisitMutationHookResult = ReturnType<typeof useSaveVisitMutation>;
export type SaveVisitMutationResult = Apollo.MutationResult<SaveVisitMutation>;
export type SaveVisitMutationOptions = Apollo.BaseMutationOptions<
    SaveVisitMutation,
    SaveVisitMutationVariables
>;
export const RetrieveScheduleWindowsDocument = gql`
    query RetrieveScheduleWindows($zipCode: String!, $startDate: DateTime!, $endDate: DateTime!) {
        territoryByZipCode(zipCode: $zipCode) {
            schedule(startDate: $startDate, endDate: $endDate, requestType: PICKUP_STORAGE_ITEM) {
                startTime
                endTime
                isLocked
            }
        }
    }
`;

/**
 * __useRetrieveScheduleWindowsQuery__
 *
 * To run a query within a React component, call `useRetrieveScheduleWindowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveScheduleWindowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveScheduleWindowsQuery({
 *   variables: {
 *      zipCode: // value for 'zipCode'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRetrieveScheduleWindowsQuery(
    baseOptions: Apollo.QueryHookOptions<
        RetrieveScheduleWindowsQuery,
        RetrieveScheduleWindowsQueryVariables
    >,
) {
    return Apollo.useQuery<RetrieveScheduleWindowsQuery, RetrieveScheduleWindowsQueryVariables>(
        RetrieveScheduleWindowsDocument,
        baseOptions,
    );
}
export function useRetrieveScheduleWindowsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrieveScheduleWindowsQuery,
        RetrieveScheduleWindowsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<RetrieveScheduleWindowsQuery, RetrieveScheduleWindowsQueryVariables>(
        RetrieveScheduleWindowsDocument,
        baseOptions,
    );
}
export type RetrieveScheduleWindowsQueryHookResult = ReturnType<
    typeof useRetrieveScheduleWindowsQuery
>;
export type RetrieveScheduleWindowsLazyQueryHookResult = ReturnType<
    typeof useRetrieveScheduleWindowsLazyQuery
>;
export type RetrieveScheduleWindowsQueryResult = Apollo.QueryResult<
    RetrieveScheduleWindowsQuery,
    RetrieveScheduleWindowsQueryVariables
>;
export const RetrieveVisitsDocument = gql`
    query RetrieveVisits($pageSize: Int, $cursor: String, $customerAddressId: String) {
        currentCustomer {
            id
            visits(
                filter: {
                    pageSize: $pageSize
                    after: $cursor
                    conditions: [
                        { field: "customerAddressId", value: $customerAddressId, operator: Equal }
                    ]
                }
            ) {
                edges {
                    cursor
                    node {
                        id
                        ...VisitSelectionVisitFragment
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${VisitSelectionVisitFragmentFragmentDoc}
`;

/**
 * __useRetrieveVisitsQuery__
 *
 * To run a query within a React component, call `useRetrieveVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveVisitsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *      customerAddressId: // value for 'customerAddressId'
 *   },
 * });
 */
export function useRetrieveVisitsQuery(
    baseOptions?: Apollo.QueryHookOptions<RetrieveVisitsQuery, RetrieveVisitsQueryVariables>,
) {
    return Apollo.useQuery<RetrieveVisitsQuery, RetrieveVisitsQueryVariables>(
        RetrieveVisitsDocument,
        baseOptions,
    );
}
export function useRetrieveVisitsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RetrieveVisitsQuery, RetrieveVisitsQueryVariables>,
) {
    return Apollo.useLazyQuery<RetrieveVisitsQuery, RetrieveVisitsQueryVariables>(
        RetrieveVisitsDocument,
        baseOptions,
    );
}
export type RetrieveVisitsQueryHookResult = ReturnType<typeof useRetrieveVisitsQuery>;
export type RetrieveVisitsLazyQueryHookResult = ReturnType<typeof useRetrieveVisitsLazyQuery>;
export type RetrieveVisitsQueryResult = Apollo.QueryResult<
    RetrieveVisitsQuery,
    RetrieveVisitsQueryVariables
>;
export const RetrieveAllVisitsDocument = gql`
    query RetrieveAllVisits($pageSize: Int, $cursor: String) {
        currentCustomer {
            id
            visits(filter: { pageSize: $pageSize, after: $cursor }) {
                edges {
                    cursor
                    node {
                        id
                        ...VisitManagement
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${VisitManagementFragmentDoc}
`;

/**
 * __useRetrieveAllVisitsQuery__
 *
 * To run a query within a React component, call `useRetrieveAllVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveAllVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveAllVisitsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useRetrieveAllVisitsQuery(
    baseOptions?: Apollo.QueryHookOptions<RetrieveAllVisitsQuery, RetrieveAllVisitsQueryVariables>,
) {
    return Apollo.useQuery<RetrieveAllVisitsQuery, RetrieveAllVisitsQueryVariables>(
        RetrieveAllVisitsDocument,
        baseOptions,
    );
}
export function useRetrieveAllVisitsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RetrieveAllVisitsQuery,
        RetrieveAllVisitsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<RetrieveAllVisitsQuery, RetrieveAllVisitsQueryVariables>(
        RetrieveAllVisitsDocument,
        baseOptions,
    );
}
export type RetrieveAllVisitsQueryHookResult = ReturnType<typeof useRetrieveAllVisitsQuery>;
export type RetrieveAllVisitsLazyQueryHookResult = ReturnType<typeof useRetrieveAllVisitsLazyQuery>;
export type RetrieveAllVisitsQueryResult = Apollo.QueryResult<
    RetrieveAllVisitsQuery,
    RetrieveAllVisitsQueryVariables
>;
export const GetVisitManagementByIdDocument = gql`
    query getVisitManagementById($visitId: String!) {
        visitById(visitId: $visitId) {
            ...VisitManagementDetail
        }
    }
    ${VisitManagementDetailFragmentDoc}
`;

/**
 * __useGetVisitManagementByIdQuery__
 *
 * To run a query within a React component, call `useGetVisitManagementByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitManagementByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitManagementByIdQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useGetVisitManagementByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetVisitManagementByIdQuery,
        GetVisitManagementByIdQueryVariables
    >,
) {
    return Apollo.useQuery<GetVisitManagementByIdQuery, GetVisitManagementByIdQueryVariables>(
        GetVisitManagementByIdDocument,
        baseOptions,
    );
}
export function useGetVisitManagementByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetVisitManagementByIdQuery,
        GetVisitManagementByIdQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetVisitManagementByIdQuery, GetVisitManagementByIdQueryVariables>(
        GetVisitManagementByIdDocument,
        baseOptions,
    );
}
export type GetVisitManagementByIdQueryHookResult = ReturnType<
    typeof useGetVisitManagementByIdQuery
>;
export type GetVisitManagementByIdLazyQueryHookResult = ReturnType<
    typeof useGetVisitManagementByIdLazyQuery
>;
export type GetVisitManagementByIdQueryResult = Apollo.QueryResult<
    GetVisitManagementByIdQuery,
    GetVisitManagementByIdQueryVariables
>;
export const UpdateVisitManagementDocument = gql`
    mutation updateVisitManagement($data: VisitUpdateWindowInput!) {
        visitUpdateWindow(data: $data) {
            data {
                ...VisitManagementDetail
            }
        }
    }
    ${VisitManagementDetailFragmentDoc}
`;
export type UpdateVisitManagementMutationFn = Apollo.MutationFunction<
    UpdateVisitManagementMutation,
    UpdateVisitManagementMutationVariables
>;

/**
 * __useUpdateVisitManagementMutation__
 *
 * To run a mutation, you first call `useUpdateVisitManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisitManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisitManagementMutation, { data, loading, error }] = useUpdateVisitManagementMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVisitManagementMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateVisitManagementMutation,
        UpdateVisitManagementMutationVariables
    >,
) {
    return Apollo.useMutation<
        UpdateVisitManagementMutation,
        UpdateVisitManagementMutationVariables
    >(UpdateVisitManagementDocument, baseOptions);
}
export type UpdateVisitManagementMutationHookResult = ReturnType<
    typeof useUpdateVisitManagementMutation
>;
export type UpdateVisitManagementMutationResult = Apollo.MutationResult<UpdateVisitManagementMutation>;
export type UpdateVisitManagementMutationOptions = Apollo.BaseMutationOptions<
    UpdateVisitManagementMutation,
    UpdateVisitManagementMutationVariables
>;
export const VisitCancelVisitDocument = gql`
    mutation visitCancelVisit($data: VisitCancelVisitInput!) {
        visitCancelVisit(data: $data) {
            data {
                ...VisitManagementDetail
            }
        }
    }
    ${VisitManagementDetailFragmentDoc}
`;
export type VisitCancelVisitMutationFn = Apollo.MutationFunction<
    VisitCancelVisitMutation,
    VisitCancelVisitMutationVariables
>;

/**
 * __useVisitCancelVisitMutation__
 *
 * To run a mutation, you first call `useVisitCancelVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitCancelVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitCancelVisitMutation, { data, loading, error }] = useVisitCancelVisitMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVisitCancelVisitMutation(
    baseOptions?: Apollo.MutationHookOptions<
        VisitCancelVisitMutation,
        VisitCancelVisitMutationVariables
    >,
) {
    return Apollo.useMutation<VisitCancelVisitMutation, VisitCancelVisitMutationVariables>(
        VisitCancelVisitDocument,
        baseOptions,
    );
}
export type VisitCancelVisitMutationHookResult = ReturnType<typeof useVisitCancelVisitMutation>;
export type VisitCancelVisitMutationResult = Apollo.MutationResult<VisitCancelVisitMutation>;
export type VisitCancelVisitMutationOptions = Apollo.BaseMutationOptions<
    VisitCancelVisitMutation,
    VisitCancelVisitMutationVariables
>;
