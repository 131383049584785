import React, { useState, useContext, useEffect } from 'react';
import { ApolloClient, gql, InMemoryCache, createHttpLink } from '@apollo/client';
import fetch from 'cross-fetch';
import AsyncStorage from '@react-native-community/async-storage';

import enMessages from '../en.json';
import frMessages from '../fr.json';

type ServiceProviderData = {
    name?: string;
    userPoolId?: string;
    userPoolWebClientId?: string;
    userPoolRegion?: string;
    userHostedDomain?: string;
    userSocialSignInCallback?: string;
    userSocialSignOutCallback?: string;
    supportedLanguages?: { code: string; description: string }[];
    defaultLanguage?: string;
};

type ApplicationContextType = ServiceProviderData & {
    loading: boolean;
    error?: string;
    currentLanguage: string;
    messages: {};
    changeLanguage: (newLanguage: string) => void;
};

const ApplicationContext = React.createContext<ApplicationContextType>({
    loading: false,
    currentLanguage: 'en',
    messages: {},
    changeLanguage: () => undefined,
});

const cache = new InMemoryCache();

const httpLink = createHttpLink({
    uri: '/graphql',
    fetch,
});

const applicationContextClient = new ApolloClient({
    cache,
    link: httpLink,
    credentials: 'include',
});

const SERVICE_PROVIDER_CONTEXT_QUERY = gql`
    query serviceProviderContext {
        serviceProvider {
            name
            customerUserPool
            cognitoRegion
            cognitoWebApplicationId
            cognitoHostedDomain
            cognitoSocialSignInCallback
            cognitoSocialSignOutCallback
        }
    }
`;

export const ApplicationContextProvider: React.FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [serviceProviderData, setServiceProviderData] = useState<ServiceProviderData>({});
    const [serviceProviderError, setServiceProviderError] = useState<string | undefined>();

    const [language, setLanguage] = useState<string>('en');

    useEffect(() => {
        applicationContextClient
            .query({ query: SERVICE_PROVIDER_CONTEXT_QUERY })
            .then((response) => {
                if (response.errors) {
                    setServiceProviderError(
                        response.errors.map((error) => error.message).join('<br/>'),
                    );
                } else {
                    setServiceProviderData({
                        userPoolId: response?.data?.serviceProvider?.customerUserPool,
                        userPoolWebClientId:
                            response?.data?.serviceProvider?.cognitoWebApplicationId,
                        userPoolRegion: response?.data?.serviceProvider?.cognitoRegion,
                        userHostedDomain: response?.data?.serviceProvider?.cognitoHostedDomain,
                        userSocialSignInCallback:
                            response?.data?.serviceProvider?.cognitoSocialSignInCallback,
                        userSocialSignOutCallback:
                            response?.data?.serviceProvider?.cognitoSocialSignOutCallback,
                        ...response.data,
                    });
                }
            })
            .catch((error) => setServiceProviderError(error.message))
            .then(() => setLoading(false));
    }, []);

    const changeLanguage = (newLanguage: string): void => {
        //TODO: validate language in supported
        setLanguage(newLanguage);

        AsyncStorage.setItem('language', newLanguage);
    };

    const messagesMap: { [lang: string]: { [lang: string]: string } } = {
        en: enMessages,
        fr: frMessages,
    };

    return (
        <ApplicationContext.Provider
            value={{
                ...serviceProviderData,
                loading,
                error: serviceProviderError,
                supportedLanguages: [
                    { code: 'en', description: 'English' },
                    { code: 'fr', description: 'French' },
                ],
                defaultLanguage: 'en',
                currentLanguage: language,
                changeLanguage,
                messages: messagesMap[language] ?? {},
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
};

export const useApplicationContext = (): ApplicationContextType =>
    useContext<ApplicationContextType>(ApplicationContext);
