import React, { useState, useEffect, useCallback } from 'react';
import AddressFormatter, { Country, Zone, FieldName } from '@shopify/address';
import postalCodes from 'postal-codes-js';

import { View, Text, StyleSheet } from 'react-native';
import { TextInput, Select, SelectItem, Message, ErrorMessage, Button } from '@valet/ui-components';
import { CountryCode, LocaleType } from '../AddressTypings';
import { useIntl, FormattedMessage } from 'react-intl';

type AddressDetails = {
    locale: LocaleType;
    countryCode: CountryCode;
};

type AddressSelectItem = {
    title: string;
    value: string;
};

type AddressFields = {
    fields: FieldName[][];
    countryDetails: Country;
    items: {
        zoneItems: AddressSelectItem[];
        countryItems: AddressSelectItem[];
    };
};

export type AddressInput = {
    company?: string;
    firstName?: string;
    lastName?: string;
    address1: string;
    address2: string;
    city: string;
    zoneId?: string;
    zip: string;
    countryId: string;
    phone?: string;
};

const addressInputMapping: { [key: string]: FieldName } = {
    firstName: FieldName.FirstName,
    lastName: FieldName.LastName,
    address1: FieldName.Address1,
    address2: FieldName.Address2,
    city: FieldName.City,
    province: FieldName.Zone,
    zip: FieldName.PostalCode,
    country: FieldName.Country,
    phone: FieldName.Phone,
};

const defaultAddressInput: AddressInput = {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    zoneId: '',
    zip: '',
    countryId: '',
    phone: '',
};

const requiredFields: FieldName[] = [FieldName.Address1, FieldName.City, FieldName.Country];

type NewAddressFormProps = {
    locale: LocaleType;
    countryCode: CountryCode;
    onSuccessfulSubmit: (addressInput: AddressInput) => void;
};

export const NewAddressForm: React.FC<NewAddressFormProps> = ({
    locale = 'en',
    countryCode = 'CA',
    onSuccessfulSubmit,
}) => {
    const intl = useIntl();

    const [addressInput, setAddressInput] = useState<AddressInput>({
        ...defaultAddressInput,
        countryId: countryCode,
    });
    const [addressDetails, setAddressDetails] = useState<AddressDetails>({
        locale: locale,
        countryCode: countryCode,
    });
    const [addressFields, setAddressFields] = useState<AddressFields | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);

    const getAddressFields = useCallback(async (locale: LocaleType, countryCode: CountryCode) => {
        const addressFormatter: AddressFormatter = new AddressFormatter(locale);
        const countries: Country[] = await addressFormatter.getCountries();
        const countryDetails: Country = await addressFormatter.getCountry(countryCode);
        const retrievedAddressFields: FieldName[][] = await addressFormatter
            .getOrderedFields(countryCode)
            .then((res: FieldName[][]) => {
                return res.filter(
                    (fieldGroup: FieldName[]) => !fieldGroup.includes(FieldName.Company),
                );
            });

        const zoneItems: AddressSelectItem[] = countryDetails.zones.map((zone: Zone) => ({
            title: zone.name,
            value: zone.code,
        }));

        const countryItems: AddressSelectItem[] = countries.map((country: Country) => ({
            title: country.name,
            value: country.code,
        }));

        setAddressInput((addressInput) => ({
            ...addressInput,
            zoneId: zoneItems.length > 0 ? zoneItems[0].value : undefined,
        }));

        return setAddressFields({
            fields: retrievedAddressFields,
            countryDetails: countryDetails,
            items: {
                zoneItems: zoneItems,
                countryItems: countryItems,
            },
        });
    }, []);

    useEffect(() => {
        getAddressFields(addressDetails.locale, addressDetails.countryCode);
    }, [addressDetails, getAddressFields]);

    const validateAddressInput = useCallback(
        (addressInput: AddressInput): ErrorMessage | undefined => {
            for (const [key, value] of Object.entries(addressInput)) {
                if (requiredFields.includes(addressInputMapping[key])) {
                    if (value === '') {
                        return {
                            header: '',
                            content: intl.formatMessage({
                                id: 'newAddress.errorFillInRequiredFields',
                                defaultMessage: 'Please fill in the required fields.',
                            }),
                            type: 'warning',
                        };
                    }
                }
            }

            // These countries' zip fields have discrepencies in the packages @shopify/address vs. postal-codes-js
            const countriesToSkip = ['AI', 'BO', 'JM', 'PA', 'TD', 'TT'];

            if (!countriesToSkip.includes(addressInput['countryId'])) {
                const country = addressInput['countryId'];
                const zip = addressInput['zip'] || 'placeholder'; // Empty string will not work

                const validPostalCode: string | true = postalCodes.validate(country, zip);

                if (validPostalCode !== true) {
                    let errorMessageContent = intl.formatMessage(
                        {
                            id: 'newAddress.errorIssueWithPostalCode',
                            defaultMessage:
                                'There was an issue with your postal code: {postalCode}',
                        },
                        {
                            postalCode: validPostalCode,
                        },
                    );

                    if (validPostalCode.includes('is not valid for country')) {
                        errorMessageContent = intl.formatMessage(
                            {
                                id: 'newAddress.errorPostalCodeNotValidForCountry',
                                defaultMessage:
                                    'The postal code {postalCode} is not valid for your country.',
                            },
                            {
                                postalCode: zip,
                            },
                        );
                    }

                    if (
                        validPostalCode.includes('Postal code placeholder is not valid for country')
                    ) {
                        errorMessageContent = intl.formatMessage({
                            id: 'newAddress.errorFillInPostalCode',
                            defaultMessage: 'Please fill in your postal/zip code.',
                        });
                    }

                    return {
                        header: '',
                        content: errorMessageContent,
                        type: 'warning',
                    };
                }
            }

            return undefined;
        },
        [intl],
    );

    const handleAddressFieldInputChange = useCallback(
        (value: string, fieldName: FieldName) => {
            const updatedAddressInput: AddressInput = {
                ...addressInput,
                [fieldName]: value,
            };

            // Translate 'province' into 'zoneId'
            if (fieldName === 'province') {
                const updatedAddressInput: AddressInput = {
                    ...addressInput,
                    zoneId: value,
                };

                return setAddressInput(updatedAddressInput);
            }

            if (fieldName === 'country') {
                const updatedCountryAddressInput: AddressInput = {
                    ...updatedAddressInput,
                    countryId: value,
                    zip: '',
                };

                setAddressDetails({
                    ...addressDetails,
                    countryCode: value as CountryCode,
                });

                return setAddressInput(updatedCountryAddressInput);
            }

            return setAddressInput(updatedAddressInput);
        },
        [addressInput, addressDetails],
    );

    const onSubmitPress = useCallback((): void => {
        const invalidAddress = validateAddressInput(addressInput);
        if (invalidAddress) {
            return setErrorMessage(invalidAddress);
        } else {
            setErrorMessage(undefined);
            return onSuccessfulSubmit(addressInput);
        }
    }, [onSuccessfulSubmit, validateAddressInput, addressInput]);

    if (!addressFields) {
        // TODO: Better loading interface
        return (
            <View testID="data-newAddressForm">
                <Text>
                    <FormattedMessage id="loading" defaultMessage="Loading..." />
                </Text>
            </View>
        );
    }

    return (
        <View testID="data-newAddressForm">
            <Text style={newAddressPageStyles.TextHeader}>
                <FormattedMessage
                    id="newAddress.newAddressHeader"
                    defaultMessage="Add New Address"
                />
            </Text>

            {errorMessage && (
                <Message
                    type={errorMessage.type}
                    header={errorMessage.header}
                    content={errorMessage.content}
                />
            )}

            {addressFields.fields.map((fieldGroup: FieldName[]) => (
                <View key={fieldGroup[0]}>
                    <AddressFieldsGroup
                        fieldGroup={fieldGroup}
                        details={{
                            countryDetails: addressFields.countryDetails,
                            items: addressFields.items,
                        }}
                        onAddressFieldInputChange={handleAddressFieldInputChange}
                        addressInput={addressInput}
                    />
                </View>
            ))}

            <Button
                title={intl.formatMessage({
                    id: 'newAddress.submitAddress',
                    defaultMessage: 'Submit Address',
                })}
                onPress={onSubmitPress}
                testID="data-submitAddressButton"
            />
        </View>
    );
};

type AddressFieldsGroupProps = {
    fieldGroup: FieldName[];
    details: {
        countryDetails: Country;
        items: {
            zoneItems: AddressSelectItem[];
            countryItems: AddressSelectItem[];
        };
    };
    onAddressFieldInputChange: (value: string, fieldName: FieldName) => void;
    addressInput: AddressInput;
};

const AddressFieldsGroup: React.FC<AddressFieldsGroupProps> = ({
    fieldGroup,
    details,
    onAddressFieldInputChange,
    addressInput,
}) => {
    const { zoneItems, countryItems } = details.items;

    return (
        <View style={newAddressFieldsGroupStyles.ViewParent}>
            {fieldGroup.map((field: FieldName) => (
                <View style={newAddressFieldsGroupStyles.ViewFields} key={field}>
                    {field === FieldName.Country ? (
                        <CountryAddressFieldItem
                            field={field}
                            value={addressInput['countryId'] || countryItems[0].value}
                            onAddressFieldInputChange={onAddressFieldInputChange}
                            countryItems={countryItems}
                        />
                    ) : field === FieldName.Zone ? (
                        <ZoneAddressFieldItem
                            field={field}
                            value={addressInput['zoneId'] || zoneItems[0].value}
                            onAddressFieldInputChange={onAddressFieldInputChange}
                            zoneItems={zoneItems}
                        />
                    ) : (
                        <AddressFieldItem
                            field={field}
                            label={`${
                                details.countryDetails?.labels[
                                    field === FieldName.PostalCode ? 'postalCode' : field
                                ]
                            }${requiredFields.includes(field) ? ' (required)' : ''}`}
                            value={addressInput[field] || ''}
                            onAddressFieldInputChange={onAddressFieldInputChange}
                        />
                    )}
                </View>
            ))}
        </View>
    );
};

type AddressFieldItemProps = {
    field: FieldName;
    label: string;
    value: string;
    onAddressFieldInputChange: (value: string, fieldName: FieldName) => void;
};

const AddressFieldItem: React.FC<AddressFieldItemProps> = React.memo(
    ({ field, label, value, onAddressFieldInputChange }) => {
        return (
            <TextInput
                type="text"
                ariaLabel={label}
                value={value}
                onChange={(e) => onAddressFieldInputChange(e, field)}
                placeholder={label}
            />
        );
    },
);

type CountryAddressFieldItemProps = {
    field: FieldName;
    value: string;
    onAddressFieldInputChange: (value: string, fieldName: FieldName) => void;
    countryItems: AddressSelectItem[];
};

const CountryAddressFieldItem: React.FC<CountryAddressFieldItemProps> = React.memo(
    ({ field, value, onAddressFieldInputChange, countryItems }) => {
        const countryIndex = countryItems.findIndex((item) => item.value === value);

        const [selected, setSelected] = useState<number | undefined>(
            countryIndex > -1 ? countryIndex : 0,
        );

        const handleSelectChange = (index: number): void => {
            setSelected(index);
            onAddressFieldInputChange(countryItems[index].value, field);
        };

        return (
            <Select
                currentIndex={selected}
                onSelect={handleSelectChange}
                itemsDisplayValues={countryItems.map((item) => item.title)}
            >
                {countryItems.map((country: AddressSelectItem, index: number) => (
                    <SelectItem title={country.title} key={country.title} />
                ))}
            </Select>
        );
    },
);

type ZoneAddressFieldItemProps = {
    field: FieldName;
    value: string;
    onAddressFieldInputChange: (value: string, fieldName: FieldName) => void;
    zoneItems: AddressSelectItem[];
};

const ZoneAddressFieldItem: React.FC<ZoneAddressFieldItemProps> = React.memo(
    ({ field, value, onAddressFieldInputChange, zoneItems }) => {
        const zoneIndex = zoneItems.findIndex((item) => item.value === value);

        const [selected, setSelected] = useState<number | undefined>(
            zoneIndex > -1 ? zoneIndex : 0,
        );

        const handleSelectChange = (index: number): void => {
            setSelected(index);
            onAddressFieldInputChange(zoneItems[index].value, field);
        };

        return (
            <Select
                currentIndex={selected}
                onSelect={handleSelectChange}
                itemsDisplayValues={zoneItems.map((item) => item.title)}
            >
                {zoneItems.map((zone: AddressSelectItem, index: number) => (
                    <SelectItem title={zone.title} key={zone.title} />
                ))}
            </Select>
        );
    },
);

const newAddressPageStyles = StyleSheet.create({
    TextHeader: {
        fontSize: 20,
        fontWeight: '700',
    },
});

const newAddressFieldsGroupStyles = StyleSheet.create({
    ViewParent: {
        marginTop: 5,
        marginBottom: 5,
        width: '100%',
        flexDirection: 'row',
        alignContent: 'space-between',
    },
    ViewFields: {
        flex: 1,
    },
});
