import React from 'react';

import { ScrollView, Text } from 'react-native';

type VisitsProps = {};

export const Visits: React.FC<VisitsProps> = () => {
    return (
        <ScrollView testID="data-visitsPage">
            <Text>Visits Page Here</Text>
        </ScrollView>
    );
};
