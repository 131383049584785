import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

import { View, Text } from 'react-native';
import { FormattedMessage } from 'react-intl';

type SocialSignInProps = {
    handleRedirectEmailExists: () => void;
    handleRedirectSignIn: () => void;
};

export const SocialSignIn: React.FC<SocialSignInProps> = ({
    handleRedirectEmailExists,
    handleRedirectSignIn,
}) => {
    const history = useHistory();
    const query = qs.parse(history.location.search);

    useEffect(() => {
        if (query.error_description) {
            if (
                query.error_description.includes(
                    'PreSignUp failed with error - a user with the same email address exists.',
                )
            ) {
                handleRedirectEmailExists();
            }
        } else {
            handleRedirectSignIn();
        }
    }, [handleRedirectEmailExists, handleRedirectSignIn, query.error_description]);

    return (
        <View testID="data-socialSignInPage">
            <Text>
                <FormattedMessage
                    id="authentication.verifyingIdentity"
                    defaultMessage="Verifying your identity..."
                />
            </Text>
        </View>
    );
};
