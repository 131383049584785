import React, { useCallback } from 'react';

import { View } from 'react-native';
import { ScheduleWindowSelectionPage, ScheduleWindow } from './NewVisit/ScheduleWindowSelection';
import { VisitStatus, VisitSelectionVisitFragmentFragment } from '../../../../../schema-types';

const visitTemplate: VisitSelectionVisitFragmentFragment = {
    id: '',
    status: VisitStatus.Created,
    expectedDuration: 0,
    startTime: '',
    endTime: '',
};

type NewVisitPageProps = {
    onGoToNextPage: () => void;
    currentState: 'scheduleWindowSelection';
    newVisitDetails?: VisitSelectionVisitFragmentFragment;
    onClearNewVisitDate: () => void;
    onScheduleWindowSelection: (scheduleWindow: ScheduleWindow) => void;
    zip?: string;
};

export const NewVisitPage: React.FC<NewVisitPageProps> = ({
    onGoToNextPage,
    currentState,
    newVisitDetails = visitTemplate,
    onClearNewVisitDate,
    onScheduleWindowSelection,
    zip,
}) => {
    const handleScheduleWindowSelection = useCallback(
        (scheduleWindow: ScheduleWindow) => {
            onScheduleWindowSelection(scheduleWindow);
            onGoToNextPage();
        },
        [onScheduleWindowSelection, onGoToNextPage],
    );

    return (
        <View testID="data-newVisitPage">
            {currentState === 'scheduleWindowSelection' && (
                <ScheduleWindowSelectionPage
                    newVisitDetails={newVisitDetails}
                    onClearNewVisitDate={onClearNewVisitDate}
                    onScheduleWindowSelection={handleScheduleWindowSelection}
                    zip={zip}
                />
            )}
        </View>
    );
};
