import React, { useState } from 'react';

import { View, Text } from 'react-native';
import { TextInput, Button, Message, ErrorMessage } from '@valet/ui-components';

import { HandleAuthFormInputChange, HandleAuthFormSubmit } from './AuthTypes';
import { authStyles } from './SignIn';
import { useIntl, FormattedMessage } from 'react-intl';

type ResetPasswordProps = {
    handleResetPasswordAttempt: (email: string, code: string, password: string) => void;
    handleRedirectSignUp: () => void;
};

export const ResetPassword: React.FC<ResetPasswordProps> = ({
    handleResetPasswordAttempt,
    handleRedirectSignUp,
}) => {
    const intl = useIntl();

    type UserDetails = {
        email: string;
        code: string;
        password: string;
    };

    const [userDetails, setUserDetails] = useState<UserDetails>({
        email: '',
        code: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);

    const handleEmailInputChange: HandleAuthFormInputChange = (value: string) => {
        setUserDetails({ ...userDetails, email: value });
    };

    const handleCodeInputChange: HandleAuthFormInputChange = (value: string) => {
        setUserDetails({ ...userDetails, code: value });
    };

    const handlePasswordInputChange: HandleAuthFormInputChange = (value: string) => {
        setUserDetails({ ...userDetails, password: value });
    };

    const handleResetPasswordFormSubmit: HandleAuthFormSubmit = async () => {
        setErrorMessage({ content: '', type: 'info', header: '' });

        const { email, password, code } = userDetails;

        if (email === '' || password === '' || code === '') {
            enum UserKeys {
                email = 'email',
                code = 'code',
                password = 'password',
            }

            interface TmpUserDetails {
                [key: string]: UserKeys[keyof UserKeys];
            }

            const tmpUserDetails: TmpUserDetails = userDetails;
            const userKeys: string[] = Object.keys(tmpUserDetails);
            let emptyField;

            for (let i = 0; i < userKeys.length; i++) {
                if (tmpUserDetails[userKeys[i]] === '') {
                    emptyField = userKeys[i];
                    break;
                }
            }

            switch (emptyField) {
                case 'email':
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorEnterEmail',
                            defaultMessage: 'Please enter your email address',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
                case 'password':
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorEnterNewPassword',
                            defaultMessage: 'Please enter a new password',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
                case 'code':
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorEnterResetCode',
                            defaultMessage: 'Please enter your reset code',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
                default:
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorFillInAllFields',
                            defaultMessage: 'Please fill in all the fields.',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
            }
        }

        try {
            await handleResetPasswordAttempt(email, code, password);
        } catch (err) {
            setErrorMessage({
                ...errorMessage,
                content: err.message,
                type: 'negative',
                header: 'Error',
            });
        }
    };

    return (
        <View style={resetPasswordStyles.ViewParent} testID="data-resetPasswordPage">
            <View style={resetPasswordStyles.ViewForm} testID="data-resetPasswordForm">
                <Text style={resetPasswordStyles.TextHeader}>
                    <FormattedMessage
                        id="authentication.resetPasswordHeader"
                        defaultMessage="Change your password"
                    />
                </Text>

                {errorMessage && (
                    <View style={resetPasswordStyles.ViewErrorMessage}>
                        <Message errorMessage={errorMessage} />
                    </View>
                )}

                <View style={resetPasswordStyles.ViewFormGroup}>
                    <Text>
                        <FormattedMessage
                            id="authentication.yourEmail"
                            defaultMessage="Your Email"
                        />
                    </Text>
                    <TextInput
                        type="text"
                        testID="data-emailInput"
                        onChange={handleEmailInputChange}
                        ariaLabel={intl.formatMessage({
                            id: 'authentication.yourEmail',
                            defaultMessage: 'Your Email',
                        })}
                        value={userDetails.email}
                    />
                </View>

                <View style={resetPasswordStyles.ViewFormGroup}>
                    <Text>
                        <FormattedMessage
                            id="authentication.yourResetCode"
                            defaultMessage="Your Reset Code"
                        />
                    </Text>

                    <TextInput
                        type="text"
                        testID="data-resetCodeInput"
                        onChange={handleCodeInputChange}
                        ariaLabel={intl.formatMessage({
                            id: 'authentication.yourResetCode',
                            defaultMessage: 'Your Reset Code',
                        })}
                        value={userDetails.code}
                    />
                </View>

                <View style={resetPasswordStyles.ViewFormGroup}>
                    <Text>
                        <FormattedMessage
                            id="authentication.yourNewPassword"
                            defaultMessage="Your New Password"
                        />
                    </Text>

                    <TextInput
                        type="password"
                        testID="data-newPasswordInput"
                        onChange={handlePasswordInputChange}
                        ariaLabel={intl.formatMessage({
                            id: 'authentication.yourNewPassword',
                            defaultMessage: 'Your New Password',
                        })}
                        value={userDetails.password}
                    />
                </View>

                <View style={resetPasswordStyles.ViewFormGroup}>
                    <Button
                        title={intl.formatMessage({
                            id: 'authentication.resetPasswordButton',
                            defaultMessage: 'Reset Password',
                        })}
                        onPress={handleResetPasswordFormSubmit}
                        testID="data-submitButton"
                    />
                </View>

                <View style={resetPasswordStyles.ViewRedirectionLinks}>
                    <Button
                        title={intl.formatMessage({
                            id: 'authentication.signUpLink',
                            defaultMessage: "Don't have an account? Create one now.",
                        })}
                        appearance="ghost"
                        onPress={handleRedirectSignUp}
                    />
                </View>
            </View>
        </View>
    );
};

const resetPasswordStyles = authStyles;
