import React, { useState } from 'react';

import { View, Text } from 'react-native';
import { GoogleButton, FacebookButton, authStyles } from './SignIn';
import { TextInput, Button, Message, ErrorMessage } from '@valet/ui-components';

import { HandleAuthFormInputChange, HandleAuthFormSubmit } from './AuthTypes';
import { useIntl, FormattedMessage } from 'react-intl';

type SignUpProps = {
    handleSignUpAttempt: (email: string, password: string) => void;
    handleRedirectSignIn: () => void;
};

export const SignUp: React.FC<SignUpProps> = ({ handleSignUpAttempt, handleRedirectSignIn }) => {
    const intl = useIntl();

    type UserDetails = {
        email: string;
        password: string;
    };

    const [userDetails, setUserDetails] = useState<UserDetails>({
        email: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);

    const handleEmailInputChange: HandleAuthFormInputChange = (value: string) => {
        setUserDetails({ ...userDetails, email: value });
    };

    const handlePasswordInputChange: HandleAuthFormInputChange = (value: string) => {
        setUserDetails({ ...userDetails, password: value });
    };

    const handleSignUpFormSubmit: HandleAuthFormSubmit = async () => {
        setErrorMessage({ content: '', type: 'info', header: '' });

        const { email, password } = userDetails;

        if (email === '' || password === '') {
            enum UserKeys {
                email = 'email',
                password = 'password',
            }

            interface TmpUserDetails {
                [key: string]: UserKeys[keyof UserKeys];
            }

            const tmpUserDetails: TmpUserDetails = userDetails;
            const userKeys: string[] = Object.keys(tmpUserDetails);
            let emptyField;

            for (let i = 0; i < userKeys.length; i++) {
                if (tmpUserDetails[userKeys[i]] === '') {
                    emptyField = userKeys[i];
                    break;
                }
            }

            switch (emptyField) {
                case 'email':
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorEnterEmail',
                            defaultMessage: 'Please enter your email address',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
                case 'password':
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorEnterPassword',
                            defaultMessage: 'Please enter your password',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
                default:
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorFillInAllFields',
                            defaultMessage: 'Please fill in all the fields.',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
            }
        }

        try {
            await handleSignUpAttempt(email, password);
        } catch (err) {
            setErrorMessage({
                ...errorMessage,
                content: err.message,
                type: 'negative',
                header: intl.formatMessage({
                    id: 'authentication.errorHeader',
                    defaultMessage: 'Error',
                }),
            });
        }
    };

    return (
        <View style={signUpStyles.ViewParent} testID="data-signUpPage">
            <View style={signUpStyles.ViewForm} testID="data-signUpForm">
                <Text style={signUpStyles.TextHeader}>
                    <FormattedMessage
                        id="authentication.signUpHeader"
                        defaultMessage="Create an account"
                    />
                </Text>

                {errorMessage && (
                    <View style={signUpStyles.ViewErrorMessage}>
                        <Message errorMessage={errorMessage} />
                    </View>
                )}

                <View style={signUpStyles.ViewFormGroup}>
                    <Text>
                        <FormattedMessage id="authentication.email" defaultMessage="Email" />
                    </Text>

                    <TextInput
                        type="text"
                        testID="data-emailInput"
                        onChange={handleEmailInputChange}
                        ariaLabel={intl.formatMessage({
                            id: 'authentication.email',
                            defaultMessage: 'Email',
                        })}
                        value={userDetails.email}
                    />
                </View>

                <View style={signUpStyles.ViewFormGroup}>
                    <Text>
                        <FormattedMessage id="authentication.password" defaultMessage="Password" />
                    </Text>

                    <TextInput
                        type="password"
                        testID="data-passwordInput"
                        onChange={handlePasswordInputChange}
                        ariaLabel={intl.formatMessage({
                            id: 'authentication.password',
                            defaultMessage: 'Password',
                        })}
                        value={userDetails.password}
                    />
                </View>

                <View style={signUpStyles.ViewFormGroup}>
                    <Button
                        title={intl.formatMessage({
                            id: 'authentication.signUp',
                            defaultMessage: 'Create Account',
                        })}
                        onPress={handleSignUpFormSubmit}
                        testID="data-submitButton"
                    />
                </View>

                <View style={signUpStyles.ViewRedirectionLinks}>
                    <Button
                        title={intl.formatMessage({
                            id: 'authentication.signInLink',
                            defaultMessage: 'Already have an account? Sign in',
                        })}
                        appearance="ghost"
                        onPress={handleRedirectSignIn}
                    />
                </View>

                <View style={signUpStyles.ViewDivider}></View>

                <View style={signUpStyles.ViewSocialSignIn}>
                    <Text style={signUpStyles.TextHeader}>
                        <FormattedMessage
                            id="authentication.signUpWithSocialHeader"
                            defaultMessage="Sign up with your social account"
                        />
                    </Text>

                    <GoogleButton />
                    <FacebookButton />
                </View>
            </View>
        </View>
    );
};

const signUpStyles = authStyles;
