import React from 'react';

import { View, Text } from 'react-native';
import { ReviewDetails, RequestReview } from '../../../../Organisms/RequestReview/RequestReview';
import { CustomerPaymentMethod } from '../PaymentSelection/PaymentSelection';
import { CustomerAddress } from '../AddressSelection/AddressSelection';
import { VisitSelectionVisitFragmentFragment } from '../../../../../schema-types';

type DeliveryRequestReviewPageProps = {
    selectedItems?: {
        delivery: { storageItemId: string; storageItemTypeName: string; description: string }[];
        pickup: { storageItemId: string; storageItemTypeName: string; description: string }[];
        emptyContainers: { storageContainerId: string }[];
    };
    selectedVisit?: VisitSelectionVisitFragmentFragment;
    newVisit: boolean;
    selectedAddress?: CustomerAddress;
    selectedPaymentMethod?: CustomerPaymentMethod;
    containsContainers: boolean;
    onSuccessfulRequestSubmit: () => void;
};

// TODO: Get total cost from API
const totalCost = 300.04;
// TODO: Get monthly cost from API
const monthlyCost = 15.24;

export const DeliveryRequestReviewPage: React.FC<DeliveryRequestReviewPageProps> = ({
    selectedItems = undefined,
    selectedVisit = undefined,
    newVisit,
    selectedAddress = undefined,
    selectedPaymentMethod = undefined,
    containsContainers,
    onSuccessfulRequestSubmit,
}) => {
    if (!selectedItems || !selectedVisit || !selectedAddress || !selectedPaymentMethod) {
        // TODO: Provide better error message
        return (
            <View testID="data-deliveryRequestReviewPage">
                <Text>There was an issue; please restart the delivery request</Text>
            </View>
        );
    }

    const reviewDetails: ReviewDetails = {
        newVisit: newVisit,
        selectedItems: {
            existingItems: {
                delivery: selectedItems.delivery,
                pickup: selectedItems.pickup,
            },
            emptyContainers: selectedItems.emptyContainers,
        },

        selectedVisit: selectedVisit,
        selectedAddress: selectedAddress,
        selectedPaymentMethod: selectedPaymentMethod,
        containsContainers: containsContainers,
        totalCost: totalCost,
        monthlyCost: monthlyCost,
    };

    return (
        <View testID="data-deliveryRequestReviewPage">
            <RequestReview
                requestType="delivery"
                reviewDetails={reviewDetails}
                onSuccessfulRequestSubmit={onSuccessfulRequestSubmit}
                onBackToItemSelectionPagePress={() => null}
            />
        </View>
    );
};
