import React, { useState, useCallback, useEffect } from 'react';
import { gql } from '@apollo/client';

import { View, Text, StyleSheet } from 'react-native';
import { Button } from '@valet/ui-components';
import { OrderLoader } from '../OrderLoader';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    useRetrieveVisitsQuery,
    VisitSelectionVisitFragmentFragment,
} from '../../../../../schema-types';
import { NewVisitItem, VisitItem } from './VisitSharedComponents';

type VisitSelectionPageProps = {
    onGoToNextPage: () => void;
    customerAddressId: string | undefined;
    selectedVisit?: VisitSelectionVisitFragmentFragment;
    onVisitItemSelection: (visit: VisitSelectionVisitFragmentFragment) => void;
    onNewVisitSelection: () => void;
    onNoLoadedVisits: () => void;
};

const VISIT_SELECTION_VISIT_FRAGMENT = gql`
    fragment VisitSelectionVisitFragment on Visit {
        id
        status
        startTime
        endTime
        expectedDuration        
    }
`;

export const PICKUP_RETRIEVE_VISITS_QUERY = gql`
    query RetrieveVisits($pageSize: Int, $cursor: String, $customerAddressId: String) {
        currentCustomer {
            id
            visits(
                filter: {
                    pageSize: $pageSize
                    after: $cursor
                    conditions: [
                        { field: "customerAddressId", value: $customerAddressId, operator: Equal }
                    ]
                }
            ) {
                edges {
                    cursor
                    node {
                        id
                        ...VisitSelectionVisitFragment
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${VISIT_SELECTION_VISIT_FRAGMENT}
`;

export const visitPageSize = 50;

export const VisitSelectionPage: React.FC<VisitSelectionPageProps> = ({
    onGoToNextPage,
    customerAddressId = '',
    selectedVisit,
    onVisitItemSelection,
    onNewVisitSelection,
    onNoLoadedVisits,
}) => {
    const intl = useIntl();

    const { data, loading, error, fetchMore } = useRetrieveVisitsQuery({
        variables: { pageSize: visitPageSize, cursor: '', customerAddressId: customerAddressId },
    });
    const visitEdges = data?.currentCustomer?.visits?.edges ?? [];
    const visits = visitEdges.map((visitEdge) => visitEdge?.node);
    const hasNextPage = data?.currentCustomer?.visits?.pageInfo?.hasNextPage ?? true;
    const endCursor = data?.currentCustomer?.visits?.pageInfo?.cursor?.afterCursor;
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const handleEndOfPageScroll = useCallback(() => {
        if (hasNextPage) {
            setIsFetching(true);
            fetchMore({
                variables: {
                    pageSize: visitPageSize,
                    cursor: `${endCursor}`,
                    customerAddressId: customerAddressId,
                },
            }).then(() => setIsFetching(false));
        }
    }, [endCursor, hasNextPage, customerAddressId, fetchMore]);

    useEffect(() => {
        const windowHeight = window.innerHeight;
        const pageElement = document.querySelector('[data-testid="data-visitSelectionPage"]');

        if (pageElement) {
            const pageWindow = parseInt(
                window.getComputedStyle(pageElement).height.replace('px', ''),
            );

            if (windowHeight > pageWindow) {
                if (data && !isFetching) {
                    handleEndOfPageScroll();
                }
            }
        }
    }, [data, handleEndOfPageScroll, isFetching]);

    useEffect(() => {
        if (data && visits.length === 0) {
            onNoLoadedVisits();
            return;
        }
    }, [data, onNoLoadedVisits, visits]);

    if (loading) {
        return (
            <View testID="data-visitSelectionPage">
                <OrderLoader />
            </View>
        );
    }

    if (error) {
        return (
            <View testID="data-visitSelectionPage">
                <Text>
                    <FormattedMessage
                        id="errorLoading"
                        defaultMessage="There was an error. Please reload this page."
                    />
                </Text>
            </View>
        );
    }

    if (data && visits.length > 0) {
        return (
            <View testID="data-visitSelectionPage">
                <Text style={visitSelectionPageStyles.TextHeader}>
                    <FormattedMessage
                        id="pickupRequest.selectVisitHeader"
                        defaultMessage="Select Visit"
                    />
                </Text>

                <>
                    {visits.length === 0 ? (
                        <Text>
                            <FormattedMessage
                                id="pickupRequest.noUpcomingVisits"
                                defaultMessage="No upcoming visits."
                            />
                        </Text>
                    ) : (
                        <>
                            <View>
                                <Button
                                    title={intl.formatMessage({
                                        id: 'pickupRequest.next',
                                        defaultMessage: 'Next',
                                    })}
                                    onPress={onGoToNextPage}
                                    disabled={!selectedVisit}
                                    testID="data-nextButton"
                                />
                            </View>

                            {visits.map((visit) => (
                                <VisitItem
                                    key={visit.id}
                                    details={visit}
                                    selected={selectedVisit === visit}
                                    onVisitItemSelection={onVisitItemSelection}
                                />
                            ))}
                        </>
                    )}
                    <NewVisitItem onNewVisitSelection={onNewVisitSelection} />
                </>
            </View>
        );
    }

    return (
        <Text>
            <FormattedMessage
                id="pickupRequest.noUpcomingVisits"
                defaultMessage="No upcoming visits."
            />
        </Text>
    );
};

const visitSelectionPageStyles = StyleSheet.create({
    TextHeader: {
        fontSize: 20,
        fontWeight: '700',
    },
    TextContentHeader: {
        marginVertical: 5,
        fontSize: 18,
        fontWeight: '700',
    },
});
