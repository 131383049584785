import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client';

import { View, Text, StyleSheet } from 'react-native';
import { Button, TextInput, Icon, Message } from '@valet/ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    useRetrieveStorageItemQuery,
    useSetStorageItemMetadataMutation,
} from '../../../../schema-types';

export const INVENTORY_STORAGE_ITEM_FRAGMENT = gql`
    fragment InventoryStorageItem on StorageItem {
        id
        status
        storageItemType {
            id
            name
            description
            image
            metadataDefinitions {
                id
                propertyName
                isRequired
                propertyType
                values
                isActive
                sortOrder
            }
            containerType {
                id
                description
            }
        }
        metadataValue {
            id
            value
            metadataDefinition {
                id
                propertyName
            }
        }
        visits(filter: {}) {
            edges {
                node {
                    startTime
                    endTime
                }
            }
        }
        container {
            id
        }
    }
`;

export const RETRIEVE_STORAGE_ITEM_QUERY = gql`
    query RetrieveStorageItem($itemId: String!) {
        storageItemById(id: $itemId) {
            id
            ...InventoryStorageItem
        }
    }
    ${INVENTORY_STORAGE_ITEM_FRAGMENT}
`;

export const SET_STORAGE_ITEM_METADATA_MUTATION = gql`
    mutation setStorageItemMetadata($data: SetStorageItemMetadataInput!) {
        setStorageItemMetadata(data: $data) {
            values {
                id
                value
                metadataDefinition {
                    id
                    propertyName
                }
            }
        }
    }
`;

type DetailsType = { [key: string]: { values?: string[]; value?: string } };

type InventoryItemDetailProps = {
    itemId: string;
};

export const InventoryItemDetail: React.FC<InventoryItemDetailProps> = ({ itemId }) => {
    const intl = useIntl();

    const [details, setDetails] = useState<DetailsType>({});

    const history = useHistory();

    const handleGoBackPress = useCallback((): void => {
        history.push('/inventory');
    }, [history]);

    const { data, loading, error } = useRetrieveStorageItemQuery({
        variables: { itemId: itemId },
    });

    const storageItemStatusTextMapping: { [key: string]: string } = {
        CREATED: intl.formatMessage({
            id: 'inventory.statusCreated',
            defaultMessage: 'This item has been recently added.',
        }),
        IN_TRANSIT: intl.formatMessage({
            id: 'inventory.statusInTransit',
            defaultMessage: 'This item is in transit.',
        }),
        AT_CUSTOMER_LOCATION: intl.formatMessage({
            id: 'inventory.statusAtCustomerLocation',
            defaultMessage: 'This item is not in storage.',
        }),
        IN_STORAGE: intl.formatMessage({
            id: 'inventory.statusInStorage',
            defaultMessage: 'This item is in storage.',
        }),
        ARCHIVED: intl.formatMessage({
            id: 'inventory.statusArchived',
            defaultMessage: 'This item is no longer active.',
        }),
    };

    const [
        setStorageItemMetadataMutation,
        {
            loading: setStorageItemMetadataMutationLoading,
            error: setStorageItemMetadataMutationError,
        },
    ] = useSetStorageItemMetadataMutation({
        onCompleted({ setStorageItemMetadata }) {
            if (setStorageItemMetadata) {
                handleGoBackPress();
            }
        },
    });

    const item = data?.storageItemById ?? undefined;
    const { status, storageItemType, visits } = item ?? {};
    const { edges: itemVisit } = visits ?? {};

    useEffect(() => {
        const detailsFields: DetailsType = {};

        storageItemType?.metadataDefinitions?.forEach((data) => {
            // TODO: Support other types of data:
            //      STRING_SINGLE_LINE,
            //      STRING_MULTI_LINE,
            //      LIST_DROPDOWN,
            //      LIST_CHECKBOX,
            //      LIST_RADIO,
            //      BOOLEAN,
            //      NUMBER_INTEGER,
            //      NUMBER_DECIMAL,

            detailsFields[data.propertyName] = {
                values: data.values ?? undefined,
                value:
                    item?.metadataValue?.find((m) => m.metadataDefinition?.id === data.id)?.value ??
                    undefined,
            };
        });

        setDetails(detailsFields);
    }, [item, storageItemType, setDetails]);

    const handleSaveDetailsPress = useCallback(async () => {
        if (item?.id) {
            try {
                await setStorageItemMetadataMutation({
                    variables: {
                        data: {
                            storageItemId: item?.id,
                            values:
                                item?.storageItemType.metadataDefinitions?.map((md) => {
                                    return {
                                        storageItemMetadataDefinitionId: md.id,
                                        value: details ? details[md.propertyName].value ?? '' : '',
                                    };
                                }) ?? undefined,
                        },
                    },
                });
            } catch (ignore) {
                //ignore
            }
        }
    }, [details, item, setStorageItemMetadataMutation]);

    if (loading) {
        return (
            <View testID="data-inventoryDetailPage">
                <FormattedMessage id="inventoryDetail.loading" defaultMessage="Loading..." />
            </View>
        );
    }

    return (
        <View style={styles.ViewParent} testID="data-inventoryDetailPage">
            <View style={styles.ViewTopBar}>
                <Text onPress={handleGoBackPress}>
                    <Icon icon="back" />{' '}
                    <FormattedMessage id="inventoryDetail.back" defaultMessage="Back" />
                </Text>
            </View>

            {error && (
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'inventoryItemDetail.errorCouldNotFindItem',
                        defaultMessage: 'Could not find storage item - please try again',
                    })}
                    content={error.message}
                    testID="data-errorCouldNotFindItem"
                />
            )}

            {setStorageItemMetadataMutationError && (
                <Message
                    type="negative"
                    header={intl.formatMessage({
                        id: 'inventoryItemDetail.errorUnableToSaveInformation',
                        defaultMessage: 'Unable to save information - please try again',
                    })}
                    content={setStorageItemMetadataMutationError.message}
                    testID="data-errorUnableToSaveInformation"
                />
            )}

            {storageItemType?.metadataDefinitions?.map((metadata) => (
                <View style={styles.ViewContent} key={metadata.propertyName}>
                    <Text style={styles.TextHeader}>{metadata.propertyName}</Text>

                    <TextInput
                        type="text"
                        value={details[metadata.propertyName]?.value ?? ''}
                        ariaLabel={details[metadata.propertyName]?.value || ''}
                        onChange={(value: string) =>
                            setDetails({
                                ...details,
                                [metadata.propertyName]: {
                                    values: metadata.values ?? undefined,
                                    value,
                                },
                            })
                        }
                    />
                </View>
            ))}

            <View style={styles.ViewContent}>
                <Text style={styles.TextHeader}>
                    <FormattedMessage id="inventoryDetail.statusHeader" defaultMessage="Status" />
                </Text>
                <Text>{storageItemStatusTextMapping[`${status}`]}</Text>
            </View>

            <View style={styles.ViewContent}>
                <Text style={styles.TextHeader}>
                    <FormattedMessage
                        id="inventoryDetail.scheduleDetailsHeader"
                        defaultMessage="Schedule Details"
                    />
                </Text>
                {itemVisit && itemVisit.length && (
                    <Text>
                        <FormattedMessage
                            id="inventoryDetail.scheduleDetailsText"
                            defaultMessage="Scheduled between {startTime} and {endTime} on {date}."
                            values={{
                                startTime: intl.formatTime(new Date(itemVisit[0].node.startTime), {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                }),
                                endTime: intl.formatTime(new Date(itemVisit[0].node.endTime), {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                }),
                                date: intl.formatDate(new Date(itemVisit[0].node.startTime), {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                }),
                            }}
                        />
                    </Text>
                )}
            </View>

            <View style={styles.ViewContent}>
                <Button
                    title={intl.formatMessage({
                        id: 'inventoryDetail.saveDetails',
                        defaultMessage: 'Save Details',
                    })}
                    disabled={setStorageItemMetadataMutationLoading}
                    onPress={handleSaveDetailsPress}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    ViewTopBar: {
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#d4d4d5',
    },
    ViewContent: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
});
