import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect } from 'react-router-dom';

import { BaseLayout, MenuItemData } from '../Layouts/BaseLayout';
import { Home } from './Home/Home';
import { InventorySelectedItemsType } from './Inventory/Inventory';
import { InventoryRoutes } from './Inventory/InventoryRoutes';
import { DeliveryRequest } from './Request/Delivery/DeliveryRequest';
import { PickupRequest } from './Request/Pickup/PickupRequest';
import { Visits } from './Visits/Visits';
import { Account } from './Account/Account';
import { VisitManagementRoutes } from './Visit-Management/VisitManagementRoutes';

type BaseRoutesProps = {};

export const BaseRoutes: React.FC<BaseRoutesProps> = () => {
    const intl = useIntl();

    const menuItems: MenuItemData[] = [
        {
            key: 0,
            label: intl.formatMessage({ id: 'navigation.home', defaultMessage: 'Home' }),
            icon: 'menuHome',
            route: '/home',
            display: true,
        },
        {
            key: 1,
            label: intl.formatMessage({ id: 'navigation.inventory', defaultMessage: 'Inventory' }),
            icon: 'menuInventory',
            route: '/inventory',
            display: true,
        },
        {
            key: 2,
            label: intl.formatMessage({
                id: 'navigation.newPickup',
                defaultMessage: 'New Request',
            }),
            icon: 'menuPickup',
            route: '/pickup',
            display: true,
        },
        {
            key: 2,
            label: intl.formatMessage({
                id: 'navigation.newPickup',
                defaultMessage: 'New Request',
            }),
            icon: 'menuPickup',
            route: '/delivery',
            display: false,
        },
        {
            key: 3,
            label: intl.formatMessage({
                id: 'navigation.upcomingVisits',
                defaultMessage: 'Upcoming Visits',
            }),
            icon: 'menuVisits',
            route: '/visits',
            display: true,
        },
        {
            key: 4,
            label: intl.formatMessage({
                id: 'navigation.accountAndBilling',
                defaultMessage: 'Account & Billing',
            }),
            icon: 'menuAccount',
            route: '/account',
            display: true,
        },
        {
            key: 5,
            label: intl.formatMessage({
                id: 'navigation.visitManagement',
                defaultMessage: 'Visit Management',
            }),
            icon: 'menuVisitReview',
            route: '/visitManagement',
            display: true,
        },
    ];

    const [page, setPage] = useState('');
    const [inventorySelectedItems, setInventorySelectedItems] = useState<
        InventorySelectedItemsType | undefined
    >(undefined);

    const handleScheduleVisit = (selectedItems: InventorySelectedItemsType): void => {
        setInventorySelectedItems(selectedItems);
    };

    const handleUpdatePageState = (page: string): void => {
        setPage(page);
    };

    return (
        <Router>
            <PageHandler page={page} handleUpdatePageState={handleUpdatePageState} />
            <BaseLayout menuItemsData={menuItems}>
                <Switch>
                    <Route path="/inventory">
                        <InventoryRoutes onScheduleVisit={handleScheduleVisit} />
                    </Route>

                    <Route path="/delivery" exact>
                        {inventorySelectedItems ? (
                            <DeliveryRequest selectedItems={inventorySelectedItems} />
                        ) : (
                            <Redirect to="/inventory" push />
                        )}
                    </Route>

                    <Route path="/pickup" exact>
                        <PickupRequest />
                    </Route>

                    <Route path="/visits" exact>
                        <Visits />
                    </Route>

                    <Route path="/account" exact>
                        <Account />
                    </Route>
                    <Route path="/visitManagement">
                        <VisitManagementRoutes />
                    </Route>

                    <Route path="/home">
                        <Home />
                    </Route>
                </Switch>
            </BaseLayout>
        </Router>
    );
};

type PageHandlerProps = {
    page: string;
    handleUpdatePageState: (page: string) => void;
};

export const PageHandler: React.FC<PageHandlerProps> = ({ page, handleUpdatePageState }) => {
    const history = useHistory();
    const firstUpdate = useRef(true);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        history.push(`/${page}`);
    }, [page, history]);

    useEffect(() => {
        const currentPage = history.location.pathname.substr(1);
        if (page !== currentPage) {
            handleUpdatePageState(currentPage);
        }
    }, [history.location, page, handleUpdatePageState]);

    return null;
};
