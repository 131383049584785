import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';

import { View, Text, FlatList, Image, StyleSheet } from 'react-native';
import { Button, Card, Checkbox } from '@valet/ui-components';
import { InventorySelectedItemsType, inventoryStyles } from '../Inventory';

import {
    useRetrieveStorageItemsQuery,
    InventoryStorageItemFragment,
} from '../../../../schema-types';
import { INVENTORY_STORAGE_ITEM_FRAGMENT } from './InventoryItemDetail';

export const RETRIEVE_STORAGE_ITEMS_QUERY = gql`
    query RetrieveStorageItems($pageSize: Int, $cursor: String) {
        currentCustomer {
            id
            storageItems(filter: { pageSize: $pageSize, after: $cursor }) {
                edges {
                    cursor
                    node {
                        id
                        ...InventoryStorageItem
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${INVENTORY_STORAGE_ITEM_FRAGMENT}
`;

export const inventoryItemsPageSize = 50;

type InventoryItemsProps = {
    searchValue: string;
    selectedItems: InventorySelectedItemsType;
    onItemSelectionChange: (item: InventoryStorageItemFragment) => void;
    onEditItemPress: (id: string, container: boolean) => void;
};

export const InventoryItems: React.FC<InventoryItemsProps> = ({
    searchValue,
    selectedItems,
    onItemSelectionChange,
    onEditItemPress,
}) => {
    const { data, loading, error, fetchMore } = useRetrieveStorageItemsQuery({
        variables: { pageSize: inventoryItemsPageSize, cursor: '' },
    });

    const itemEdges: Array<{ node: InventoryStorageItemFragment }> =
        data?.currentCustomer?.storageItems?.edges ?? [];
    const hasNextPage = data?.currentCustomer?.storageItems?.pageInfo?.hasNextPage ?? true;
    const endCursor = data?.currentCustomer?.storageItems?.pageInfo?.cursor?.afterCursor;
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const filteredItems: InventoryStorageItemFragment[] = useMemo(() => {
        if (searchValue === '') {
            return itemEdges.map(
                (itemEdge: { node: InventoryStorageItemFragment }) => itemEdge?.node,
            );
        } else {
            const filteredResults: InventoryStorageItemFragment[] = itemEdges
                .map((itemEdge: { node: InventoryStorageItemFragment }) => itemEdge?.node)
                .filter((node: InventoryStorageItemFragment) => {
                    if (
                        node.storageItemType.name.toUpperCase().includes(searchValue.toUpperCase())
                    ) {
                        return true;
                    }

                    const filteredItems =
                        node.storageItemType.metadataDefinitions?.filter((item) => {
                            if (item.values === null) {
                                return false;
                            }

                            const filteredValues =
                                item.values?.filter((value: string) =>
                                    value.toUpperCase().includes(searchValue.toUpperCase()),
                                ) ?? [];

                            return filteredValues.length > 0;
                        }) ?? [];

                    return filteredItems.length > 0;
                });

            return filteredResults;
        }
    }, [searchValue, itemEdges]);

    const handleEndOfPageScroll = useCallback(() => {
        if (hasNextPage) {
            setIsFetching(true);
            fetchMore({
                variables: {
                    pageSize: inventoryItemsPageSize,
                    cursor: `${endCursor}`,
                },
            }).then(() => setIsFetching(false));
        }
    }, [endCursor, hasNextPage, fetchMore]);

    useEffect(() => {
        const windowHeight = window.innerHeight;
        const pageElement = document.querySelector('[data-testid="data-inventoryPage"]');

        if (pageElement) {
            const pageWindow = parseInt(
                window.getComputedStyle(pageElement).height.replace('px', ''),
            );

            if (windowHeight > pageWindow) {
                if (data && !isFetching) {
                    handleEndOfPageScroll();
                }
            }
        }
    }, [data, handleEndOfPageScroll, isFetching]);

    if (loading) {
        return (
            <View testID="data-inventoryItemsPage">
                <Text>
                    <FormattedMessage id="inventoryItems.loading" defaultMessage="Loading..." />
                </Text>
            </View>
        );
    }

    if (error) {
        return (
            <View testID="data-inventoryItemsPage">
                <Text>
                    <FormattedMessage
                        id="inventoryItems.error"
                        defaultMessage="There was an error - please refresh this page."
                    />
                </Text>
            </View>
        );
    }

    const itemsInStorage: InventoryStorageItemFragment[] = filteredItems.filter(
        (item) => item.status === 'IN_STORAGE',
    );
    const itemsNotInStorage: InventoryStorageItemFragment[] = filteredItems
        .filter((item) => item.status !== 'ARCHIVED')
        .filter((item) => item.status !== 'IN_STORAGE');
    const itemsArchived: InventoryStorageItemFragment[] = filteredItems.filter(
        (item) => item.status === 'ARCHIVED',
    );

    return (
        <View>
            <View style={inventoryStyles.ViewGroupStatus}>
                <Text style={inventoryStyles.TextGroupStatusHeader}>
                    <FormattedMessage
                        id="inventory.itemsInStorage"
                        defaultMessage="Items in storage"
                    />
                </Text>

                {itemsInStorage.length > 0 ? (
                    <FlatList
                        data={itemsInStorage}
                        renderItem={({ item }) => (
                            <InventoryItem
                                item={item}
                                onItemSelectionChange={onItemSelectionChange}
                                onEditPress={() =>
                                    onEditItemPress(
                                        item.id,
                                        item.storageItemType.containerType !== null,
                                    )
                                }
                                selected={
                                    selectedItems.pickup.includes(item) ||
                                    selectedItems.delivery.includes(item)
                                }
                                key={item.id}
                            />
                        )}
                        keyExtractor={(item) => item.id.toString()}
                    />
                ) : (
                    <FormattedMessage id="inventory.noItemsToDisplay" defaultMessage="None" />
                )}
            </View>

            <View>
                <View style={inventoryStyles.ViewGroupStatus}>
                    <Text style={inventoryStyles.TextGroupStatusHeader}>
                        <FormattedMessage
                            id="inventory.itemsNotInStorage"
                            defaultMessage="Items not in storage"
                        />
                    </Text>

                    {itemsNotInStorage.length > 0 ? (
                        <FlatList
                            data={itemsNotInStorage}
                            renderItem={({ item }) => (
                                <InventoryItem
                                    item={item}
                                    onItemSelectionChange={onItemSelectionChange}
                                    onEditPress={() =>
                                        onEditItemPress(
                                            item.id,
                                            item.storageItemType.containerType !== null,
                                        )
                                    }
                                    selected={
                                        selectedItems.pickup.includes(item) ||
                                        selectedItems.delivery.includes(item)
                                    }
                                    key={item.id}
                                />
                            )}
                            keyExtractor={(item) => item.id.toString()}
                        />
                    ) : (
                        <FormattedMessage id="inventory.noItemsToDisplay" defaultMessage="None" />
                    )}
                </View>
            </View>

            <View>
                <View style={inventoryStyles.ViewGroupStatus}>
                    <Text style={inventoryStyles.TextGroupStatusHeader}>
                        <FormattedMessage
                            id="inventory.itemsArchived"
                            defaultMessage="Items archived"
                        />
                    </Text>

                    {itemsArchived.length > 0 ? (
                        <FlatList
                            data={itemsArchived}
                            renderItem={({ item }) => (
                                <InventoryItem
                                    item={item}
                                    onItemSelectionChange={onItemSelectionChange}
                                    onEditPress={() =>
                                        onEditItemPress(
                                            item.id,
                                            item.storageItemType.containerType !== null,
                                        )
                                    }
                                    selected={
                                        selectedItems.pickup.includes(item) ||
                                        selectedItems.delivery.includes(item)
                                    }
                                    key={item.id}
                                />
                            )}
                            keyExtractor={(item) => item.id.toString()}
                        />
                    ) : (
                        <FormattedMessage id="inventory.noItemsToDisplay" defaultMessage="None" />
                    )}
                </View>
            </View>
        </View>
    );
};

type InventoryItemProps = {
    item: InventoryStorageItemFragment;
    onItemSelectionChange: (item: InventoryStorageItemFragment) => void;
    onEditPress: (id: string) => void;
    selected: boolean;
};

const InventoryItem: React.FC<InventoryItemProps> = ({
    item,
    onItemSelectionChange,
    onEditPress,
    selected,
}) => {
    const intl = useIntl();

    const {
        id,
        storageItemType: { name: storageItemTypeName, image: storageItemTypeImage },
        status,
    } = item;

    const storageItemStatusTextMapping: { [key: string]: string } = {
        CREATED: intl.formatMessage({
            id: 'inventory.statusCreated',
            defaultMessage: 'This item has been recently added.',
        }),
        IN_TRANSIT: intl.formatMessage({
            id: 'inventory.statusInTransit',
            defaultMessage: 'This item is in transit.',
        }),
        AT_CUSTOMER_LOCATION: intl.formatMessage({
            id: 'inventory.statusAtCustomerLocation',
            defaultMessage: 'This item is not in storage.',
        }),
        IN_STORAGE: intl.formatMessage({
            id: 'inventory.statusInStorage',
            defaultMessage: 'This item is in storage.',
        }),
        ARCHIVED: intl.formatMessage({
            id: 'inventory.statusArchived',
            defaultMessage: 'This item is no longer active.',
        }),
    };

    return (
        <Card
            disabled
            footer={() => (
                <View style={inventoryItemStyles.ViewActions}>
                    {status !== 'IN_STORAGE' && (
                        <View style={[inventoryItemStyles.ViewActionButton, { marginRight: 3 }]}>
                            {status === 'ARCHIVED' ? (
                                <Button
                                    title={intl.formatMessage({
                                        id: 'inventory.unarchiveAction',
                                        defaultMessage: 'Show',
                                    })}
                                    onPress={() => console.log(`Pressed Show For ${id}`)}
                                    size="small"
                                />
                            ) : (
                                <Button
                                    title={intl.formatMessage({
                                        id: 'inventory.archiveAction',
                                        defaultMessage: 'Hide',
                                    })}
                                    onPress={() => console.log(`Pressed Hide For ${id}`)}
                                    size="small"
                                />
                            )}
                        </View>
                    )}

                    <View style={[inventoryItemStyles.ViewActionButton, { marginLeft: 3 }]}>
                        <Button
                            title={intl.formatMessage({
                                id: 'inventory.edit',
                                defaultMessage: 'Edit',
                            })}
                            onPress={() => onEditPress(id)}
                            size="small"
                        />
                    </View>
                </View>
            )}
            style={inventoryItemStyles.CardParent}
        >
            <View
                style={{ flex: 1, display: 'flex', flexDirection: 'row' }}
                testID="data-inventoryItem"
            >
                <View style={inventoryItemStyles.ViewCheckbox}>
                    <Checkbox
                        disabled={
                            status !== 'CREATED' &&
                            status !== 'AT_CUSTOMER_LOCATION' &&
                            status !== 'IN_STORAGE'
                        }
                        checked={selected}
                        onValueChange={(value: boolean) => onItemSelectionChange(item)}
                        testID="data-inventoryItemCheckbox"
                    />
                </View>

                <View style={inventoryItemStyles.ViewImage}>
                    {storageItemTypeImage ? (
                        <Image
                            style={{ height: 80, width: 120 }}
                            source={{ uri: storageItemTypeImage }}
                        />
                    ) : (
                        <Text>
                            <FormattedMessage
                                id="inventory.inventoryItemNoLoadedImage"
                                defaultMessage="No image loaded."
                            />
                        </Text>
                    )}
                </View>

                <View style={inventoryItemStyles.ViewInfo}>
                    <View style={inventoryItemStyles.ViewDetails}>
                        {storageItemTypeName && <Text>{storageItemTypeName}</Text>}
                        <Text>{storageItemStatusTextMapping[status]}</Text>
                    </View>
                </View>
            </View>
        </Card>
    );
};

const inventoryItemStyles = StyleSheet.create({
    CardParent: {
        marginHorizontal: 2,
        marginVertical: 5,
    },
    ViewCheckbox: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ViewImage: {
        paddingHorizontal: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ViewDetails: {
        paddingVertical: 5,
    },
    ViewInfo: {
        flex: 1,
        paddingHorizontal: 5,
    },
    ViewActions: {
        paddingVertical: 5,
        // borderTopColor: '#d4d4d5',
        // borderTopWidth: 1,
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    ViewActionButton: {
        flex: 1,
    },
});
