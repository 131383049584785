import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { gql } from '@apollo/client';

import { Button, Card, Icon, Modal, Select, SelectItem } from '@valet/ui-components';
import { View, Text, StyleSheet } from 'react-native';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    InventoryStorageContainerFragment,
    InventoryStorageItemFragment,
    RetrieveStorageContainerDocument,
    useAssignStorageContainerMutation,
    useRetrieveStorageContainerQuery,
    useRetrieveStorageItemsQuery,
    useUnassignStorageContainerMutation,
} from '../../../../schema-types';

export const INVENTORY_STORAGE_CONTAINER_FRAGMENT = gql`
    fragment InventoryStorageContainer on StorageContainer {
        id
        status
        barcode
        assignedStorageItem {
            id
        }
        containerType {
            id
            description
        }
    }
`;

export const RETRIEVE_STORAGE_CONTAINER_QUERY = gql`
    query RetrieveStorageContainer($itemId: String) {
        currentCustomer {
            id
            storageContainers(
                filter: { conditions: { field: "id", value: $itemId, operator: Equal } }
            ) {
                edges {
                    node {
                        id
                        ...InventoryStorageContainer
                    }
                }
            }
        }
    }
    ${INVENTORY_STORAGE_CONTAINER_FRAGMENT}
`;

export const inventoryContainerDetailsStorageItemsPageSize = 200;

type InventoryContainerDetailProps = {};

export const InventoryContainerDetail: React.FC<InventoryContainerDetailProps> = () => {
    const intl = useIntl();

    const history = useHistory();
    const match = useRouteMatch();

    const params: { item?: string } = match.params;
    const itemId = params.item;

    const handleGoBackPress = useCallback((): void => {
        history.push('/inventory');
    }, [history]);

    useEffect(() => {
        if (!itemId) {
            handleGoBackPress();
        }
    }, [itemId, handleGoBackPress]);

    const [selectIndex, setSelectIndex] = useState<number>(0);
    const [displayModal, setDisplayModal] = useState<boolean>(false);

    const { data, loading, error } = useRetrieveStorageContainerQuery({
        variables: { itemId: itemId },
    });
    const container: InventoryStorageContainerFragment | undefined =
        data?.currentCustomer?.storageContainers?.edges[0].node;

    const {
        data: storageItemsData,
        loading: storageItemsLoading,
        error: storageItemsError,
        fetchMore: storageItemsFetchMore,
    } = useRetrieveStorageItemsQuery({
        variables: {
            cursor: '',
            pageSize: inventoryContainerDetailsStorageItemsPageSize,
        },
    });

    const hasNextPage =
        storageItemsData?.currentCustomer?.storageItems?.pageInfo?.hasNextPage ?? false;
    const endCursor =
        storageItemsData?.currentCustomer?.storageItems?.pageInfo?.cursor?.afterCursor;
    const [isFetching, setIsFetching] = useState<boolean>(false);

    useEffect(() => {
        if (hasNextPage && !isFetching) {
            setIsFetching(true);
            storageItemsFetchMore({
                variables: {
                    pageSize: inventoryContainerDetailsStorageItemsPageSize,
                    cursor: `${endCursor}`,
                },
            }).then(() => setIsFetching(false));
        }
    }, [endCursor, hasNextPage, isFetching, storageItemsFetchMore]);

    const [
        assignStorageContainer,
        {
            // data: assignStorageContainerData,
            loading: assignStorageContainerLoading,
            error: assignStorageContainerError,
        },
    ] = useAssignStorageContainerMutation();

    const [
        unassignStorageContainer,
        {
            // data: unassignStorageContainerData,
            loading: unassignStorageContainerLoading,
            error: unassignStorageContainerError,
        },
    ] = useUnassignStorageContainerMutation();

    const storageItems: InventoryStorageItemFragment[] =
        storageItemsData?.currentCustomer?.storageItems.edges.map((item) => item.node) ?? [];

    if (loading || storageItemsLoading) {
        return (
            <View testID="data-inventoryContainerDetailPage">
                <FormattedMessage
                    id="inventoryContainerDetail.loading"
                    defaultMessage="Loading..."
                />
            </View>
        );
    }

    if (
        error ||
        storageItemsError ||
        !container ||
        assignStorageContainerError ||
        unassignStorageContainerError
    ) {
        return (
            <View testID="data-inventoryContainerDetailPage">
                <Text>
                    <FormattedMessage
                        id="inventoryContainerDetail.error"
                        defaultMessage="There was an error - please refresh this page."
                    />
                </Text>
            </View>
        );
    }

    const storageItemsWithContainerType = storageItems
        .filter((item) => item.storageItemType.containerType)
        .filter((item) => !item.container);

    const sortedStorageItems: {
        matchesContainer: InventoryStorageItemFragment[];
        doesNotMatchContainer: InventoryStorageItemFragment[];
    } = {
        matchesContainer: storageItemsWithContainerType.filter(
            (storageItem) =>
                storageItem.storageItemType.containerType?.id === container.containerType?.id,
        ),
        doesNotMatchContainer: storageItemsWithContainerType.filter(
            (storageItem) =>
                storageItem.storageItemType.containerType?.id !== container.containerType?.id,
        ),
    };

    const selectItems: { id: string; title: string }[] = [
        ...sortedStorageItems.matchesContainer.map((item) => ({
            id: item.id,
            title: `${item.id} - ${item.storageItemType.name}`,
        })),
        ...sortedStorageItems.doesNotMatchContainer.map((item) => ({
            id: item.id,
            title: `${item.id} - ${item.storageItemType.name} (Warning: Does not match container type)`,
        })),
    ];

    const handleSaveDetailsSuccess = async (storageItemId: string): Promise<void> => {
        const storageContainerId = container.id;

        await assignStorageContainer({
            variables: {
                storageItemId: storageItemId,
                storageContainerId: storageContainerId,
            },
            refetchQueries: [
                {
                    query: RetrieveStorageContainerDocument,
                    variables: {
                        itemId: itemId,
                    },
                },
            ],
        });
    };

    const handleReleaseContainer = async (): Promise<void> => {
        if (container) {
            await unassignStorageContainer({
                variables: {
                    storageContainerId: container.id,
                },
                refetchQueries: [
                    {
                        query: RetrieveStorageContainerDocument,
                        variables: {
                            itemId: itemId,
                        },
                    },
                ],
            });
        }
    };

    const handleSaveDetailsPress = (): void => {
        if (
            sortedStorageItems.doesNotMatchContainer
                .map((storageItem) => storageItem.id)
                .includes(selectItems[selectIndex].id)
        ) {
            setDisplayModal(true);
        } else {
            handleSaveDetailsSuccess(selectItems[selectIndex].id);
        }
    };

    return (
        <View style={styles.ViewParent} testID="data-inventoryDetailPage">
            <Modal
                visible={displayModal}
                onBackdropPress={() => setDisplayModal(false)}
                testID="data-inventoryContainerDetailConfirmationModal"
            >
                <Card disabled style={{ margin: 10 }}>
                    <View style={{ marginBottom: 5 }}>
                        <Text>
                            <FormattedMessage
                                id="inventoryContainerDetail.warningContainerSizeContent"
                                defaultMessage="Storage container size does not match size of this item. Do you want to continue?"
                            />
                        </Text>
                    </View>

                    <View
                        style={{
                            marginTop: 5,
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <View style={{ flex: 1, marginRight: 5 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'inventoryContainerDetail.confirmationApprove',
                                    defaultMessage: 'Yes, save details',
                                })}
                                onPress={() => {
                                    setDisplayModal(false);
                                    handleSaveDetailsSuccess(selectItems[selectIndex].id);
                                }}
                                status="primary"
                            />
                        </View>

                        <View
                            style={{
                                flex: 1,
                                marginLeft: 5,
                            }}
                        >
                            <Button
                                title={intl.formatMessage({
                                    id: 'inventoryContainerDetail.confirmationDeny',
                                    defaultMessage: 'No, go back',
                                })}
                                onPress={() => setDisplayModal(false)}
                                status="warning"
                            />
                        </View>
                    </View>
                </Card>
            </Modal>
            <View style={styles.ViewTopBar}>
                <Text onPress={handleGoBackPress}>
                    <Icon icon="back" />{' '}
                    <FormattedMessage id="inventoryContainerDetail.back" defaultMessage="Back" />
                </Text>
            </View>

            <View style={styles.ViewContent}>
                <Text style={styles.TextHeader}>
                    <FormattedMessage
                        id="inventoryContainerDetail.associateContainerDetailsHeader"
                        defaultMessage="Associate container to a storage item"
                    />
                </Text>

                <View style={styles.ViewContentChild}>
                    {!container.assignedStorageItem ? (
                        <Select
                            currentIndex={selectIndex}
                            itemsDisplayValues={selectItems.map((items) => items.title)}
                            onSelect={(index) => setSelectIndex(index)}
                        >
                            {selectItems.map((item) => (
                                <SelectItem title={item.title} key={item.id} />
                            ))}
                        </Select>
                    ) : (
                        <Button
                            title={intl.formatMessage({
                                id: 'inventoryContainerDetail.disassociateContainerButton',
                                defaultMessage: 'Release container',
                            })}
                            onPress={handleReleaseContainer}
                            appearance="outline"
                        />
                    )}
                </View>
            </View>

            <View style={styles.ViewContent}>
                <Button
                    title={intl.formatMessage({
                        id: 'inventoryContainerDetail.saveDetails',
                        defaultMessage: 'Save Details',
                    })}
                    onPress={handleSaveDetailsPress}
                    disabled={assignStorageContainerLoading || unassignStorageContainerLoading}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    ViewTopBar: {
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#d4d4d5',
    },
    ViewContent: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        marginVertical: 5,
    },
    ViewContentChild: {
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
});
