import React from 'react';

import { ScrollView, Text } from 'react-native';
import { Button } from '@valet/ui-components';

import { SignOutFunction } from '../Auth/AuthFunctions';
import { useIntl } from 'react-intl';

type AccountProps = {};

export const Account: React.FC<AccountProps> = () => {
    const intl = useIntl();

    return (
        <ScrollView testID="data-accountPage">
            <Text>Account Page Here</Text>
            <Button
                title={intl.formatMessage({
                    id: 'account.signOutButton',
                    defaultMessage: 'Click Here To Sign Out',
                })}
                onPress={() => SignOutFunction()}
            />
        </ScrollView>
    );
};
