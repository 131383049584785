import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { State } from 'xstate';

import {
    backButtonEnabled,
    PickupMachineContext,
    PickupMachineEvent,
    PickupMachine,
} from './PickupStateMachine';

import { View, ScrollView, StyleSheet } from 'react-native';
import { Button, Message, ErrorMessage } from '@valet/ui-components';
import { OrderLoader } from '../Components/OrderLoader';

import { ItemSelectionPage } from '../Components/ItemSelection/ItemSelection';
import { VisitSelectionPage } from '../Components/VisitSelection/VisitSelection';
import { NewVisitPage } from '../Components/VisitSelection/NewVisit';
import {
    AddressSelectionPage,
    CustomerAddress,
} from '../Components/AddressSelection/AddressSelection';
import { NewAddressPage } from '../Components/AddressSelection/NewAddress';
import {
    PaymentSelectionPage,
    CustomerPaymentMethod,
} from '../Components/PaymentSelection/PaymentSelection';
import { NewPaymentPage } from '../Components/PaymentSelection/NewPayment';
import { ScheduleWindow } from '../Components/VisitSelection/NewVisit/ScheduleWindowSelection';
import { PickupRequestReviewPage } from '../Components/RequestReview/PickupRequestReview';
import { RequestSubmittedPage } from '../../Request/Components/RequestSubmitted/RequestSubmitted';
import { ContainerVisitsSelection } from '../Components/VisitSelection/ContainerVisitsSelection';
import { SecondVisitSelectionPage } from '../Components/VisitSelection/SecondVisitSelection';
import { SecondAddressSelectionPage } from '../Components/AddressSelection/SecondAddressSelection';
import { SecondNewAddressPage } from '../Components/AddressSelection/SecondNewAddress';
import { SecondNewVisitPage } from '../Components/VisitSelection/SecondNewVisit';

import { useIntl } from 'react-intl';

type PickupRequestProps = {};

export const PickupRequest: React.FC<PickupRequestProps> = () => {
    const [state, send] = useMachine<PickupMachineContext, PickupMachineEvent>(PickupMachine);

    const history = useHistory();

    const handleGoToNextPage = useCallback(() => {
        send({ type: 'NEXT' });
    }, [send]);

    const handleGoToPreviousPage = useCallback(() => send({ type: 'BACK' }), [send]);

    const handleCounterChange = useCallback(
        (id: string, name: string, value: number, container: boolean) =>
            send({
                type: 'ADD_ITEM',
                payload: {
                    storageItemTypeId: id,
                    storageItemTypeName: name,
                    quantity: value,
                    container: container,
                },
            }),
        [send],
    );

    const handleAddressItemSelection = useCallback(
        (address: CustomerAddress) => {
            send({
                type: 'ADD_ADDRESS',
                payload: {
                    address: address,
                },
            });
        },
        [send],
    );

    const handleSecondAddressItemSelection = useCallback(
        (address: CustomerAddress) => {
            send({
                type: 'ADD_SECOND_ADDRESS',
                payload: {
                    address: address,
                },
            });
        },
        [send],
    );

    const handleNoLoadedAddresses = useCallback(() => {
        send({ type: 'NO_LOADED_ADDRESSES' });
    }, [send]);

    const handleNewAddressSelection = useCallback(() => {
        send({ type: 'NEW_ADDRESS' });
    }, [send]);

    const handleSecondNewAddressSelection = useCallback(() => {
        send({ type: 'NEW_SECOND_ADDRESS' });
    }, [send]);

    const handleNewAddressSubmit = useCallback(
        (newAddress: CustomerAddress) => {
            send({
                type: 'ADD_ADDRESS',
                payload: {
                    address: newAddress,
                },
            });
        },
        [send],
    );

    const handleSecondNewAddressSubmit = useCallback(
        (newAddress: CustomerAddress) => {
            send({
                type: 'ADD_SECOND_ADDRESS',
                payload: {
                    address: newAddress,
                },
            });
        },
        [send],
    );

    const handleVisitItemSelection = useCallback<
        React.ComponentProps<typeof VisitSelectionPage>['onVisitItemSelection']
    >(
        (visit) => {
            send({ type: 'ADD_VISIT', payload: { visit: visit } });

            send({ type: 'NEXT' });
        },
        [send],
    );

    const handleSecondVisitItemSelection = useCallback<
        React.ComponentProps<typeof VisitSelectionPage>['onVisitItemSelection']
    >(
        (visit) => {
            send({ type: 'ADD_SECOND_VISIT', payload: { visit: visit } });

            send({ type: 'NEXT' });
        },
        [send],
    );

    const handleOneVisitSelection = useCallback<
        React.ComponentProps<typeof ContainerVisitsSelection>['onOneVisitSelection']
    >(() => {
        send({ type: 'ONE_VISIT' });
    }, [send]);

    const handleTwoVisitsSelection = useCallback<
        React.ComponentProps<typeof ContainerVisitsSelection>['onTwoVisitsSelection']
    >(() => {
        send({ type: 'TWO_VISITS' });
    }, [send]);

    const handleLaterPress = useCallback<
        React.ComponentProps<typeof ContainerVisitsSelection>['onLaterPress']
    >(() => {
        send({ type: 'FIGURE_OUT_LATER' });
    }, [send]);

    const handleNewVisitSelection = useCallback(() => send({ type: 'NEW_VISIT' }), [send]);

    const handleSecondNewVisitSelection = useCallback(() => send({ type: 'NEW_SECOND_VISIT' }), [
        send,
    ]);

    const handleNoLoadedVisits = useCallback(() => send({ type: 'NO_LOADED_VISITS' }), [send]);

    const handleClearNewVisitDate = useCallback(() => send({ type: 'CLEAR_SCHEDULE_WINDOW' }), [
        send,
    ]);

    const handleSecondClearNewVisitDate = useCallback(
        () => send({ type: 'CLEAR_SECOND_SCHEDULE_WINDOW' }),
        [send],
    );

    const handleScheduleWindowSelection = useCallback(
        (scheduleWindow: ScheduleWindow) => {
            if (scheduleWindow.isLocked === false) {
                send({
                    type: 'ADD_SCHEDULE_WINDOW',
                    payload: {
                        scheduleWindow: scheduleWindow,
                    },
                });
            }
        },
        [send],
    );

    const handleSecondScheduleWindowSelection = useCallback(
        (scheduleWindow: ScheduleWindow) => {
            if (scheduleWindow.isLocked === false) {
                send({
                    type: 'ADD_SECOND_SCHEDULE_WINDOW',
                    payload: {
                        scheduleWindow: scheduleWindow,
                    },
                });
            }
        },
        [send],
    );

    const handleNoLoadedPaymentMethods = useCallback(() => {
        send({ type: 'NO_LOADED_PAYMENT_METHODS' });
    }, [send]);

    const handlePaymentMethodSelection = useCallback(
        (paymentMethod: CustomerPaymentMethod): void => {
            send({
                type: 'ADD_PAYMENT_METHOD',
                payload: paymentMethod,
            });
        },
        [send],
    );

    const handleNewPaymentSelection = useCallback(() => {
        send({ type: 'NEW_PAYMENT_METHOD' });
    }, [send]);

    const handleNewPaymentMethodSubmit = useCallback(
        (newPaymentMethod: CustomerPaymentMethod) => {
            send({ type: 'ADD_PAYMENT_METHOD', payload: newPaymentMethod });
        },
        [send],
    );

    const handleBackToItemSelectionPagePress = useCallback(() => {
        send({ type: 'GO_TO_ITEM_SELECTION' });
    }, [send]);

    const handleSuccessfulRequestSubmit = useCallback(() => {
        send({ type: 'REQUEST_SUBMITTED' });
    }, [send]);

    const handleGoToInventoryPress = useCallback(() => {
        history.push('/inventory');
    }, [history]);

    const handleGoToEditMetadataPress = useCallback(() => {
        console.log('Pressed go to edit metadata ');
    }, []);

    if (!state) {
        return <OrderLoader />;
    }

    const newVisitStateMapping: {
        [key: string]: React.ComponentProps<typeof NewVisitPage>['currentState'];
    } = {
        'newVisit.scheduleWindowSelection': 'scheduleWindowSelection',
    };

    const newVisitState = newVisitStateMapping[`${state?.toStrings(state?.value).pop() ?? ''}`];

    const secondNewVisitStateMapping: {
        [key: string]: React.ComponentProps<typeof SecondNewVisitPage>['currentState'];
    } = {
        'secondNewVisit.secondScheduleWindowSelection': 'secondScheduleWindowSelection',
    };

    const secondNewVisitState =
        secondNewVisitStateMapping[`${state?.toStrings(state?.value).pop() ?? ''}`];

    return (
        <ScrollView testID="data-pickupRequestPage">
            <PickupNavigation state={state} handleGoToPreviousPage={handleGoToPreviousPage} />

            <View style={pickupPageStyles.ViewParent}>
                {state.matches('itemSelection') ? (
                    <ItemSelectionPage
                        onGoToNextPage={handleGoToNextPage}
                        selectedItems={state.context.selectedItems}
                        onCounterChange={handleCounterChange}
                    />
                ) : state.matches('visitSelection') ? (
                    <VisitSelectionPage
                        onGoToNextPage={handleGoToNextPage}
                        customerAddressId={state.context.selectedAddress?.id}
                        selectedVisit={state.context.selectedVisit}
                        onVisitItemSelection={handleVisitItemSelection}
                        onNewVisitSelection={handleNewVisitSelection}
                        onNoLoadedVisits={handleNoLoadedVisits}
                    />
                ) : state.matches('addressSelection') ? (
                    <AddressSelectionPage
                        onGoToNextPage={handleGoToNextPage}
                        onAddressItemSelection={handleAddressItemSelection}
                        onNewAddressSelection={handleNewAddressSelection}
                        selectedAddress={state.context.selectedAddress}
                        onNoLoadedAddresses={handleNoLoadedAddresses}
                    />
                ) : state.matches('newAddress') ? (
                    <NewAddressPage
                        onGoToNextPage={handleGoToNextPage}
                        customerLocale={'en'} // TODO: Retrieve this from backend
                        customerCountryCode={'CA'} // TODO: Retrieve this from backend
                        onNewAddressSubmit={handleNewAddressSubmit}
                    />
                ) : newVisitState ? (
                    <NewVisitPage
                        onGoToNextPage={handleGoToNextPage}
                        currentState={newVisitState}
                        newVisitDetails={state.context.newVisit}
                        onClearNewVisitDate={handleClearNewVisitDate}
                        onScheduleWindowSelection={handleScheduleWindowSelection}
                        zip={state.context.selectedAddress?.zip || undefined}
                    />
                ) : state.matches('containerVisitsSelection') ? (
                    <ContainerVisitsSelection
                        onOneVisitSelection={handleOneVisitSelection}
                        onTwoVisitsSelection={handleTwoVisitsSelection}
                        onLaterPress={handleLaterPress}
                    />
                ) : state.matches('secondVisitSelection') ? (
                    <SecondVisitSelectionPage
                        onGoToNextPage={handleGoToNextPage}
                        customerAddressId={state.context.secondSelectedAddress?.id}
                        selectedVisit={state.context.secondSelectedVisit}
                        onVisitItemSelection={handleSecondVisitItemSelection}
                        onNewVisitSelection={handleSecondNewVisitSelection}
                        onNoLoadedVisits={handleNoLoadedVisits}
                    />
                ) : state.matches('secondAddressSelection') ? (
                    <SecondAddressSelectionPage
                        onGoToNextPage={handleGoToNextPage}
                        onAddressItemSelection={handleSecondAddressItemSelection}
                        onNewAddressSelection={handleSecondNewAddressSelection}
                        selectedAddress={state.context.secondSelectedAddress}
                        onNoLoadedAddresses={handleNoLoadedAddresses}
                    />
                ) : state.matches('secondNewAddress') ? (
                    <SecondNewAddressPage
                        onGoToNextPage={handleGoToNextPage}
                        customerLocale={'en'} // TODO: Retrieve this from backend
                        customerCountryCode={'CA'} // TODO: Retrieve this from backend
                        onNewAddressSubmit={handleSecondNewAddressSubmit}
                    />
                ) : secondNewVisitState ? (
                    <SecondNewVisitPage
                        onGoToNextPage={handleGoToNextPage}
                        currentState={secondNewVisitState}
                        newVisitDetails={state.context.secondNewVisit}
                        onClearNewVisitDate={handleSecondClearNewVisitDate}
                        onScheduleWindowSelection={handleSecondScheduleWindowSelection}
                        zip={state.context.secondSelectedAddress?.zip || undefined}
                    />
                ) : state.matches('paymentSelection') ? (
                    <PaymentSelectionPage
                        onGoToNextPage={handleGoToNextPage}
                        onPaymentMethodSelection={handlePaymentMethodSelection}
                        selectedPaymentMethod={state.context.selectedPaymentMethod}
                        handleNewPaymentSelection={handleNewPaymentSelection}
                        onNoLoadedPaymentMethods={handleNoLoadedPaymentMethods}
                    />
                ) : state.matches('newPayment') ? (
                    <NewPaymentPage
                        onGoToNextPage={handleGoToNextPage}
                        onNewPaymentMethodSubmit={handleNewPaymentMethodSubmit}
                    />
                ) : state.matches('requestReview') ? (
                    <PickupRequestReviewPage
                        selectedItems={state.context.selectedItems}
                        selectedVisit={state.context.selectedVisit ?? state.context.newVisit}
                        newVisit={!!state.context.newVisit}
                        selectedAddress={state.context.selectedAddress}
                        selectedPaymentMethod={state.context.selectedPaymentMethod}
                        containsContainers={
                            Object.values(state.context.selectedItems)
                                .map((item) => item.container)
                                .filter((container) => container === true).length > 0
                        }
                        containersFigureOutLater={state.context.containersFigureOutLater}
                        secondNewVisit={!!state.context.secondNewVisit}
                        secondSelectedVisit={
                            state.context.secondSelectedVisit
                                ? state.context.secondSelectedVisit
                                : state.context.secondNewVisit
                                ? state.context.secondNewVisit
                                : undefined
                        }
                        secondSelectedAddress={state.context.secondSelectedAddress}
                        onSuccessfulRequestSubmit={handleSuccessfulRequestSubmit}
                        onBackToItemSelectionPagePress={handleBackToItemSelectionPagePress}
                    />
                ) : state.matches('requestSubmitted') ? (
                    <RequestSubmittedPage
                        onGoToInventoryPress={handleGoToInventoryPress}
                        onGoToEditMetadataPress={handleGoToEditMetadataPress}
                    />
                ) : (
                    <OrderLoader />
                )}
            </View>
        </ScrollView>
    );
};

type PickupNavigationProps = {
    state: State<PickupMachineContext, PickupMachineEvent>;
    handleGoToPreviousPage: () => void;
};

const PickupNavigation: React.FC<PickupNavigationProps> = ({ state, handleGoToPreviousPage }) => {
    const intl = useIntl();

    if (!state) {
        return <OrderLoader />;
    }

    return (
        <>
            <View
                style={pickupNavigationStyles.ViewNavigationButtonsParent}
                testID="data-pickupRequestNavigation"
            >
                {backButtonEnabled(state) && (
                    <View style={pickupNavigationStyles.ViewNavigationButton}>
                        <Button
                            title={intl.formatMessage({ id: 'backButton', defaultMessage: 'Back' })}
                            onPress={handleGoToPreviousPage}
                            disabled={!backButtonEnabled(state)}
                        />
                    </View>
                )}
            </View>

            {state.context.errorMessage && (
                <View
                    style={pickupNavigationStyles.ViewErrorMessageParent}
                    testID="data-errorMessage"
                >
                    <PickupErrorMessage errorMessage={state.context.errorMessage} />
                </View>
            )}
        </>
    );
};

type PickupErrorMessageProps = {
    errorMessage: ErrorMessage;
};

const PickupErrorMessage: React.FC<PickupErrorMessageProps> = ({ errorMessage }) => {
    return <Message errorMessage={errorMessage} />;
};

const pickupPageStyles = StyleSheet.create({
    ViewParent: {
        paddingTop: 5,
        paddingBottom: 5,
    },
});

const pickupNavigationStyles = StyleSheet.create({
    ViewNavigationButtonsParent: {
        flexDirection: 'row',
    },
    ViewNavigationButton: {
        flex: 1,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});
