import React from 'react';

import { View, Text } from 'react-native';
import { Button } from '@valet/ui-components';
import { FormattedMessage, useIntl } from 'react-intl';

type ContainerVisitsSelectionProps = {
    onOneVisitSelection: () => void;
    onTwoVisitsSelection: () => void;
    onLaterPress: () => void;
};

export const ContainerVisitsSelection: React.FC<ContainerVisitsSelectionProps> = ({
    onOneVisitSelection,
    onTwoVisitsSelection,
    onLaterPress,
}) => {
    const intl = useIntl();

    return (
        <View>
            <Text style={{ marginVertical: 5, fontWeight: '700', fontSize: 18 }}>
                <FormattedMessage
                    id="containerVisitsSelection.header"
                    defaultMessage="How many visits do you require for your containers?"
                />
            </Text>

            <View style={{ marginVertical: 5 }}>
                <Button
                    title={intl.formatMessage({
                        id: 'containerVisitsSelection.oneVisit',
                        defaultMessage: 'One visit',
                    })}
                    onPress={onOneVisitSelection}
                />
            </View>

            <View style={{ marginVertical: 5 }}>
                <Button
                    title={intl.formatMessage({
                        id: 'containerVisitsSelection.twoVisits',
                        defaultMessage: 'Two visits',
                    })}
                    onPress={onTwoVisitsSelection}
                />
            </View>

            <View style={{ marginVertical: 5 }}>
                <Button
                    title={intl.formatMessage({
                        id: 'containerVisitsSelection.figureOutLater',
                        defaultMessage: 'Figure it out later',
                    })}
                    onPress={onLaterPress}
                />
            </View>
        </View>
    );
};
