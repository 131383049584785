import React, { useState, useCallback, useEffect } from 'react';
import { gql } from '@apollo/client';

import { View, Text, StyleSheet } from 'react-native';
import { OrderLoader } from '../OrderLoader';
import { FormattedMessage } from 'react-intl';
import {
    useRetrieveAddressesQuery,
    AddressSelectionAddressFragmentFragment,
} from '../../../../../schema-types';
import { AddressItem, NewAddressItem } from './AddressSharedComponents';

export type CustomerAddress = AddressSelectionAddressFragmentFragment;

type SecondAddressSelectionPageProps = {
    onGoToNextPage: () => void;
    onAddressItemSelection: (address: AddressSelectionAddressFragmentFragment) => void;
    selectedAddress?: AddressSelectionAddressFragmentFragment;
    onNewAddressSelection: () => void;
    onNoLoadedAddresses: () => void;
};

const ADDRESS_SELECTION_ADDRESS_FRAGMENT = gql`
    fragment AddressSelectionAddressFragment on CustomerAddress {
        id
        address1
        address2
        city
        zoneId
        countryId
        zip
        longitude
        latitude
        phone
        firstName
        lastName
    }
`;

export const PICKUP_RETRIEVE_ADDRESS_QUERY = gql`
    query RetrieveAddresses($pageSize: Int, $cursor: String) {
        currentCustomer {
            id
            addresses(filter: { pageSize: $pageSize, after: $cursor }) {
                edges {
                    cursor
                    node {
                        id
                        ...AddressSelectionAddressFragment
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${ADDRESS_SELECTION_ADDRESS_FRAGMENT}
`;

export const addressPageSize = 50;

export const SecondAddressSelectionPage: React.FC<SecondAddressSelectionPageProps> = ({
    onGoToNextPage,
    onAddressItemSelection,
    selectedAddress,
    onNewAddressSelection,
    onNoLoadedAddresses,
}) => {
    const { data, loading, error, fetchMore } = useRetrieveAddressesQuery({
        variables: { pageSize: addressPageSize, cursor: '' },
    });
    const addressEdges = data?.currentCustomer?.addresses?.edges ?? [];
    const addresses = addressEdges.map((addressEdge) => addressEdge.node);
    const hasNextPage = data?.currentCustomer?.addresses?.pageInfo?.hasNextPage ?? true;
    const endCursor = data?.currentCustomer?.addresses?.pageInfo?.cursor?.afterCursor;
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const handleEndOfPageScroll = useCallback(() => {
        if (hasNextPage) {
            setIsFetching(true);
            fetchMore({
                variables: {
                    pageSize: addressPageSize,
                    cursor: `${endCursor}`,
                },
            }).then(() => setIsFetching(false));
        }
    }, [endCursor, hasNextPage, fetchMore]);

    useEffect(() => {
        const windowHeight = window.innerHeight;
        const pageElement = document.querySelector('[data-testid="data-addressSelectionPage"]');

        if (pageElement) {
            const pageWindow = parseInt(
                window.getComputedStyle(pageElement).height.replace('px', ''),
            );

            if (windowHeight > pageWindow) {
                if (data && !isFetching) {
                    handleEndOfPageScroll();
                }
            }
        }
    }, [data, handleEndOfPageScroll, isFetching]);

    useEffect(() => {
        if (data && addresses.length === 0) {
            onNoLoadedAddresses();
            return;
        }
    }, [data, onNoLoadedAddresses, addresses]);

    const handleAddressItemSelection = (address: AddressSelectionAddressFragmentFragment): void => {
        onAddressItemSelection(address);
        onGoToNextPage();
    };

    if (loading) {
        return (
            <View testID="data-addressSelectionPage">
                <OrderLoader />
            </View>
        );
    }

    if (error) {
        return (
            <View testID="data-addressSelectionPage">
                <Text>
                    <FormattedMessage
                        id="errorLoading"
                        defaultMessage="There was an error. Please reload this page."
                    />
                </Text>
            </View>
        );
    }

    if (addresses && addresses.length > 0) {
        return (
            <View testID="data-addressSelectionPage">
                <Text style={addressSelectionPageStyles.TextContentHeader}>
                    <FormattedMessage
                        id="pickupRequest.selectSecondVisit"
                        defaultMessage="Select the second visit"
                    />
                </Text>

                <Text style={addressSelectionPageStyles.TextHeader}>
                    <FormattedMessage
                        id="addressSelection.addressSelectionHeader"
                        defaultMessage="Select address"
                    />
                </Text>

                {addresses.map((address) => (
                    <AddressItem
                        key={address.id}
                        details={address}
                        selected={selectedAddress === address}
                        onAddressItemSelection={handleAddressItemSelection}
                    />
                ))}

                <NewAddressItem onNewAddressSelection={onNewAddressSelection} />
            </View>
        );
    }

    return <></>;
};

const addressSelectionPageStyles = StyleSheet.create({
    TextHeader: {
        fontSize: 20,
        fontWeight: '700',
    },
    TextContentHeader: {
        marginVertical: 5,
        fontSize: 18,
        fontWeight: '700',
    },
});
