export type PossibleTypesResultData = {
    possibleTypes: {
        Request: [
            'PickupStorageItemRequest',
            'DeliverStorageItemRequest',
            'DeliverEmptyContainerRequest',
            'PickupEmptyContainerRequest',
            'DriverPickupEmptyContainerRequest',
        ];
        User: ['Customer', 'Employee'];
    };
};
const result: PossibleTypesResultData = {
    possibleTypes: {
        Request: [
            'PickupStorageItemRequest',
            'DeliverStorageItemRequest',
            'DeliverEmptyContainerRequest',
            'PickupEmptyContainerRequest',
            'DriverPickupEmptyContainerRequest',
        ],
        User: ['Customer', 'Employee'],
    },
};
export default result;
