import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { SideMenu, HamburgerMenu, MenuItemType } from '@valet/ui-components';
import { View, Dimensions, ScaledSize } from 'react-native';

export interface MenuItemData extends MenuItemType {
    route: string;
    display?: boolean;
}

type BaseLayoutProps = {
    menuItemsData: MenuItemData[];
};

export const BaseLayout: React.FC<BaseLayoutProps> = ({ children, menuItemsData }) => {
    const menuItems: MenuItemType[] = menuItemsData
        .filter((item: MenuItemData) => item.display === true)
        .map((item: MenuItemData) => {
            return { key: item.key, label: item.label, icon: item.icon };
        });

    const history = useHistory();

    const findIndexOfPath = menuItemsData.findIndex((menuItem: MenuItemData) => {
        return history.location.pathname.includes(menuItem.route);
    });

    const initialIndex = findIndexOfPath > -1 ? findIndexOfPath : menuItems[0].key;

    const [initialMenuIndex, setInitialMenuIndex] = useState<number>(initialIndex);
    const [mobileView, setMobileView] = useState<boolean>(false);
    const [windowHeight, setWindowHeight] = useState<number>(Dimensions.get('window').height);

    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);

        function updateSize(): void {
            const isMobileView = window.innerWidth < 700;
            if (mobileView !== isMobileView) {
                setMobileView(isMobileView);
            }
        }
    }, [mobileView]);

    useEffect(() => {
        // Ensure active menu is correctly selected in the UI
        const currentMenuIndex = menuItemsData.findIndex((menuItem: MenuItemData) => {
            return history.location.pathname.includes(menuItem.route);
        });

        if (currentMenuIndex === -1) {
            history.push('/home');
            setInitialMenuIndex(menuItems[0].key);
            return;
        }

        setInitialMenuIndex(menuItems[currentMenuIndex]?.key);
        return;
    }, [history, menuItems, menuItemsData]);

    const handleMenuItemClick = (index: number): void => {
        const menuItemIndex = menuItemsData.findIndex((menuItem: MenuItemData) => {
            return menuItem.key === index;
        });
        history.push(menuItemsData[menuItemIndex].route);
    };

    const onChange = ({ window }: { window: ScaledSize }): void => {
        setWindowHeight(window.height);
    };

    useEffect(() => {
        Dimensions.addEventListener('change', onChange);
        return () => {
            Dimensions.removeEventListener('change', onChange);
        };
    });

    return (
        <View
            style={{
                height: windowHeight,
                display: 'flex',
                flex: 1,
                flexDirection: mobileView ? ('column' as 'column') : ('row' as 'row'),
            }}
            testID="data-baseLayout"
        >
            {mobileView ? (
                <HamburgerMenu
                    initialIndex={initialMenuIndex}
                    onMenuItemClick={handleMenuItemClick}
                    menuItems={menuItems}
                />
            ) : (
                <SideMenu
                    initialIndex={initialMenuIndex}
                    onMenuItemClick={handleMenuItemClick}
                    menuItems={menuItems}
                />
            )}

            <View style={{ padding: 10, flex: 1 }}>{children}</View>
        </View>
    );
};
