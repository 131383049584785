import React, { useState, useCallback, useEffect } from 'react';
import { gql } from '@apollo/client';

import { View, Text, StyleSheet } from 'react-native';
import { OrderLoader } from '../OrderLoader';
import { FormattedMessage } from 'react-intl';
import { Card } from '@valet/ui-components';
import { useRetrievePaymentMethodsQuery } from '../../../../../schema-types';

export type CustomerPaymentMethod = {
    id: string;
    description: string;
};

type PaymentSelectionProps = {
    onGoToNextPage: () => void;
    onPaymentMethodSelection: (paymentMethod: CustomerPaymentMethod) => void;
    selectedPaymentMethod?: CustomerPaymentMethod;
    handleNewPaymentSelection: () => void;
    onNoLoadedPaymentMethods: () => void;
};

export const PICKUP_RETRIEVE_PAYMENT_METHODS_QUERY = gql`
    query RetrievePaymentMethods($pageSize: Int, $cursor: String) {
        currentCustomer {
            id
            paymentMethods(filter: { pageSize: $pageSize, after: $cursor }) {
                edges {
                    cursor
                    node {
                        id
                        description
                        isDefault
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
`;

export const paymentMethodsPageSize = 50;

export const PaymentSelectionPage: React.FC<PaymentSelectionProps> = ({
    onGoToNextPage,
    onPaymentMethodSelection,
    selectedPaymentMethod,
    handleNewPaymentSelection,
    onNoLoadedPaymentMethods,
}) => {
    const { data, loading, error, fetchMore } = useRetrievePaymentMethodsQuery({
        variables: { pageSize: paymentMethodsPageSize, cursor: '' },
    });
    const paymentMethodsEdges = data?.currentCustomer?.paymentMethods?.edges ?? [];
    const paymentMethods: CustomerPaymentMethod[] = paymentMethodsEdges.map(
        (paymentMethodsEdges: { node: CustomerPaymentMethod }) => paymentMethodsEdges?.node,
    );
    const hasNextPage = data?.currentCustomer?.paymentMethods?.pageInfo?.hasNextPage ?? true;
    const endCursor = data?.currentCustomer?.paymentMethods?.pageInfo?.cursor?.afterCursor;
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const handleEndOfPageScroll = useCallback(() => {
        if (hasNextPage) {
            setIsFetching(true);
            fetchMore({
                variables: {
                    pageSize: paymentMethodsPageSize,
                    cursor: `${endCursor}`,
                },
            }).then(() => setIsFetching(false));
        }
    }, [endCursor, hasNextPage, fetchMore]);

    useEffect(() => {
        const windowHeight = window.innerHeight;
        const pageElement = document.querySelector('[data-testid="data-paymentSelectionPage"]');

        if (pageElement) {
            const pageWindow = parseInt(
                window.getComputedStyle(pageElement).height.replace('px', ''),
            );

            if (windowHeight > pageWindow) {
                if (data && !isFetching) {
                    handleEndOfPageScroll();
                }
            }
        }
    }, [data, handleEndOfPageScroll, isFetching]);

    useEffect(() => {
        if (data && paymentMethods.length === 0) {
            onNoLoadedPaymentMethods();
            return;
        }
    }, [data, onNoLoadedPaymentMethods, paymentMethods]);

    const handlePaymentMethodSelection = (paymentMethod: CustomerPaymentMethod): void => {
        onPaymentMethodSelection(paymentMethod);
        onGoToNextPage();
    };

    if (loading) {
        return (
            <View testID="data-paymentSelectionPage">
                <OrderLoader />
            </View>
        );
    }

    if (error) {
        return (
            <View testID="data-paymentSelectionPage">
                <FormattedMessage
                    id="errorLoading"
                    defaultMessage="There was an error. Please reload this page."
                />
            </View>
        );
    }

    if (data && paymentMethods.length > 0) {
        return (
            <View testID="data-paymentSelectionPage">
                <Text style={paymentSelectionPageStyles.TextHeader}>
                    <FormattedMessage
                        id="paymentSelection.paymentSelectionHeader"
                        defaultMessage="Select a payment method"
                    />
                </Text>

                {paymentMethods.length === 0 ? (
                    <Text>
                        <FormattedMessage
                            id="paymentSelection.noSavedPaymentMethods"
                            defaultMessage="No saved payment methods."
                        />
                    </Text>
                ) : (
                    paymentMethods.map((paymentMethod: CustomerPaymentMethod) => (
                        <PaymentMethodItem
                            key={paymentMethod.id}
                            onPaymentMethodSelection={handlePaymentMethodSelection}
                            details={paymentMethod}
                            selected={selectedPaymentMethod === paymentMethod}
                        />
                    ))
                )}

                <NewPaymentItem handleNewPaymentSelection={handleNewPaymentSelection} />
            </View>
        );
    }

    return <></>;
};

type PaymentMethodItemProps = {
    onPaymentMethodSelection: (paymentMethod: CustomerPaymentMethod) => void;
    details: CustomerPaymentMethod;
    selected: boolean;
};

export const PaymentMethodItem: React.FC<PaymentMethodItemProps> = ({
    onPaymentMethodSelection,
    details,
    selected,
}) => {
    const { description } = details;

    return (
        <View style={paymentMethodStyles.ViewParent}>
            <Card
                onPress={() => onPaymentMethodSelection(details)}
                testID="data-paymentMethodItem"
                status={selected ? 'success' : undefined}
            >
                <Text>{description}</Text>
            </Card>
        </View>
    );
};

type NewPaymentItemProps = {
    handleNewPaymentSelection: () => void;
};

const NewPaymentItem: React.FC<NewPaymentItemProps> = ({ handleNewPaymentSelection }) => {
    return (
        <View style={newPaymentStyles.ViewParent} testID="data-newPaymentItem">
            <Card onPress={handleNewPaymentSelection} testID="data-newPaymentItemTouchable">
                <Text testID="data-newPaymentItemText">
                    <FormattedMessage
                        id="paymentSelection.addNewPaymentMethod"
                        defaultMessage="Add New Payment Method"
                    />
                </Text>
            </Card>
        </View>
    );
};

const paymentSelectionPageStyles = StyleSheet.create({
    TextHeader: {
        fontSize: 20,
        fontWeight: '700',
    },
});

const paymentMethodStyles = StyleSheet.create({
    ViewParent: {
        marginTop: 5,
        marginBottom: 5,
        width: '100%',
    },
});

const newPaymentStyles = StyleSheet.create({
    ViewParent: {
        marginTop: 5,
        marginBottom: 5,
        width: '100%',
    },
});
