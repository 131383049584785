import { Machine, assign, State } from 'xstate';

import { ErrorMessage } from '@valet/ui-components';
import { CustomerAddress } from '../Components/AddressSelection/AddressSelection';
import { CustomerPaymentMethod } from '../Components/PaymentSelection/PaymentSelection';
import { ScheduleWindow } from '../Components/VisitSelection/NewVisit/ScheduleWindowSelection';
import { VisitStatus, VisitSelectionVisitFragmentFragment } from '../../../../schema-types';

interface PickupMachineSchema {
    states: {
        itemSelection: {};
        addressSelection: {};
        newAddress: {};
        visitSelection: {};
        newVisit: {
            type: 'compound';
            states: {
                scheduleWindowSelection: {};
            };
        };
        containerVisitsSelection: {};
        secondAddressSelection: {};
        secondNewAddress: {};
        secondVisitSelection: {};
        secondNewVisit: {
            type: 'compound';
            states: {
                secondScheduleWindowSelection: {};
            };
        };
        paymentSelection: {};
        newPayment: {};
        requestReview: {};
        requestSubmitted: {};
    };
}

type PickupMachineNextEvent = { type: 'NEXT' };
type PickupMachineBackEvent = { type: 'BACK' };
type PickupMachineOneVisitEvent = { type: 'ONE_VISIT' };
type PickupMachineTwoVisitsEvent = { type: 'TWO_VISITS' };
type PickupMachineFigureOutLaterEvent = { type: 'FIGURE_OUT_LATER' };
type PickupMachineNewVisitEvent = { type: 'NEW_VISIT' };
type PickupMachineNewSecondVisitEvent = { type: 'NEW_SECOND_VISIT' };
type PickupMachineNoLoadedVisitsEvent = { type: 'NO_LOADED_VISITS' };
type PickupMachineNewAddressEvent = { type: 'NEW_ADDRESS' };
type PickupMachineNewSecondAddressEvent = { type: 'NEW_SECOND_ADDRESS' };
type PickupMachineNoLoadedAddressesEvent = { type: 'NO_LOADED_ADDRESSES' };
type PickupMachineNewPaymentMethodEvent = { type: 'NEW_PAYMENT_METHOD' };
type PickupMachineNoLoadedPaymentMethodsEvent = { type: 'NO_LOADED_PAYMENT_METHODS' };

type PickupMachineAddItemPayload = {
    storageItemTypeId: string;
    storageItemTypeName: string;
    quantity: number;
    container: boolean;
};

type PickupMachineAddVisitPayload = { visit: VisitSelectionVisitFragmentFragment };
type PickupMachineAddAddressPayload = { address: CustomerAddress };

type PickupMachineAddScheduleWindowPayload = { scheduleWindow: ScheduleWindow };
type PickupMachineAddPaymentMethodPayload = CustomerPaymentMethod;

type PickupMachineAddItemEvent = {
    type: 'ADD_ITEM';
    payload: PickupMachineAddItemPayload;
};
type PickupMachineAddVisitEvent = {
    type: 'ADD_VISIT';
    payload: PickupMachineAddVisitPayload;
};
type PickupMachineAddSecondVisitEvent = {
    type: 'ADD_SECOND_VISIT';
    payload: PickupMachineAddVisitPayload;
};
type PickupMachineAddAddressEvent = {
    type: 'ADD_ADDRESS';
    payload: PickupMachineAddAddressPayload;
};
type PickupMachineAddSecondAddressEvent = {
    type: 'ADD_SECOND_ADDRESS';
    payload: PickupMachineAddAddressPayload;
};
type PickupMachineAddScheduleWindowEvent = {
    type: 'ADD_SCHEDULE_WINDOW';
    payload: PickupMachineAddScheduleWindowPayload;
};
type PickupMachineAddSecondScheduleWindowEvent = {
    type: 'ADD_SECOND_SCHEDULE_WINDOW';
    payload: PickupMachineAddScheduleWindowPayload;
};
type PickupMachineClearScheduleWindowEvent = {
    type: 'CLEAR_SCHEDULE_WINDOW';
};
type PickupMachineClearSecondScheduleWindowEvent = {
    type: 'CLEAR_SECOND_SCHEDULE_WINDOW';
};
type PickupMachineAddPaymentMethodEvent = {
    type: 'ADD_PAYMENT_METHOD';
    payload: PickupMachineAddPaymentMethodPayload;
};
type PickupMachineGoToItemSelectionEvent = {
    type: 'GO_TO_ITEM_SELECTION';
};
type PickupMachineRequestSubmittedEvent = {
    type: 'REQUEST_SUBMITTED';
};

export type PickupMachineEvent =
    | PickupMachineNextEvent
    | PickupMachineBackEvent
    | PickupMachineOneVisitEvent
    | PickupMachineTwoVisitsEvent
    | PickupMachineFigureOutLaterEvent
    | PickupMachineAddItemEvent
    | PickupMachineAddVisitEvent
    | PickupMachineAddSecondVisitEvent
    | PickupMachineNewVisitEvent
    | PickupMachineNewSecondVisitEvent
    | PickupMachineNoLoadedVisitsEvent
    | PickupMachineAddScheduleWindowEvent
    | PickupMachineAddSecondScheduleWindowEvent
    | PickupMachineAddAddressEvent
    | PickupMachineAddSecondAddressEvent
    | PickupMachineNewAddressEvent
    | PickupMachineNewSecondAddressEvent
    | PickupMachineNoLoadedAddressesEvent
    | PickupMachineClearScheduleWindowEvent
    | PickupMachineClearSecondScheduleWindowEvent
    | PickupMachineAddPaymentMethodEvent
    | PickupMachineNewPaymentMethodEvent
    | PickupMachineNoLoadedPaymentMethodsEvent
    | PickupMachineGoToItemSelectionEvent
    | PickupMachineRequestSubmittedEvent;

export interface PickupMachineContext {
    previousPage: string[];
    errorMessage: ErrorMessage | undefined;
    containersFigureOutLater: boolean;
    selectedItems: Record<
        string,
        { storageItemTypeName: string; quantity: number; container: boolean }
    >;
    selectedVisit: VisitSelectionVisitFragmentFragment | undefined;
    secondSelectedVisit: VisitSelectionVisitFragmentFragment | undefined;
    selectedAddress: CustomerAddress | undefined;
    secondSelectedAddress: CustomerAddress | undefined;
    selectedPaymentMethod: CustomerPaymentMethod | undefined;
    newVisit: VisitSelectionVisitFragmentFragment | undefined;
    secondNewVisit: VisitSelectionVisitFragmentFragment | undefined;
}

const setPreviousPage = (context: PickupMachineContext, page: string): PickupMachineContext => {
    return {
        ...context,
        previousPage: [page, ...context.previousPage],
    };
};

const updatePreviousPage = (context: PickupMachineContext): PickupMachineContext => {
    return {
        ...context,
        previousPage: [...context.previousPage.slice(1)],
    };
};

const goBack = (): Array<{}> => {
    return [
        {
            target: 'itemSelection',
            cond: (context: PickupMachineContext) => context.previousPage[0] === 'itemSelection',
            actions: assign((context: PickupMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'newAddress',
            cond: (context: PickupMachineContext) => context.previousPage[0] === 'newAddress',
            actions: assign((context: PickupMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'addressSelection',
            cond: (context: PickupMachineContext) => context.previousPage[0] === 'addressSelection',
            actions: assign((context: PickupMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'visitSelection',
            cond: (context: PickupMachineContext) => context.previousPage[0] === 'visitSelection',
            actions: assign((context: PickupMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'newVisit.scheduleWindowSelection',
            cond: (context: PickupMachineContext) =>
                context.previousPage[0] === 'newVisit.scheduleWindowSelection',
            actions: assign((context: PickupMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'containerVisitsSelection',
            cond: (context: PickupMachineContext) =>
                context.previousPage[0] === 'containerVisitsSelection',
            actions: assign((context: PickupMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'secondAddressSelection',
            cond: (context: PickupMachineContext) =>
                context.previousPage[0] === 'secondAddressSelection',
            actions: assign((context: PickupMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'secondVisitSelection',
            cond: (context: PickupMachineContext) =>
                context.previousPage[0] === 'secondVisitSelection',
            actions: assign((context: PickupMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'secondNewVisit.secondScheduleWindowSelection',
            cond: (context: PickupMachineContext) =>
                context.previousPage[0] === 'secondNewVisit.secondScheduleWindowSelection',
            actions: assign((context: PickupMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'secondVisitSelection',
            cond: (context: PickupMachineContext) =>
                context.previousPage[0] === 'secondVisitSelection',
            actions: assign((context: PickupMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'paymentSelection',
            cond: (context: PickupMachineContext) => context.previousPage[0] === 'paymentSelection',
            actions: assign((context: PickupMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'newPayment',
            cond: (context: PickupMachineContext) => context.previousPage[0] === 'newPayment',
            actions: assign((context: PickupMachineContext) => updatePreviousPage(context)),
        },
    ];
};

const updateErrorMessage = (
    context: PickupMachineContext,
    errorMessage: ErrorMessage | undefined,
): PickupMachineContext => {
    return {
        ...context,
        errorMessage: errorMessage,
    };
};

const removeErrorMessage = (context: PickupMachineContext): PickupMachineContext => {
    if (context.errorMessage) {
        return updateErrorMessage(context, undefined);
    }

    return context;
};

const updateSelectedItemsContext = (
    context: PickupMachineContext,
    payload: PickupMachineAddItemPayload,
): PickupMachineContext => {
    const { storageItemTypeId, storageItemTypeName, quantity, container } = payload;

    const { [storageItemTypeId]: currentQuantity, ...updatedSelectedItems } = context.selectedItems;

    if (quantity > 0) {
        updatedSelectedItems[storageItemTypeId] = {
            storageItemTypeName: storageItemTypeName,
            quantity: quantity,
            container: container,
        };
    }

    return {
        ...context,
        selectedItems: updatedSelectedItems,
    };
};

const updateSelectedVisitContext = (
    context: PickupMachineContext,
    payload: PickupMachineAddVisitPayload,
): PickupMachineContext => {
    return {
        ...context,
        selectedVisit: payload.visit,
    };
};

const updateSelectedSecondVisitContext = (
    context: PickupMachineContext,
    payload: PickupMachineAddVisitPayload,
): PickupMachineContext => {
    return {
        ...context,
        secondSelectedVisit: payload.visit,
    };
};

const updateSelectedAddressContext = (
    context: PickupMachineContext,
    payload: PickupMachineAddAddressPayload,
): PickupMachineContext => {
    return {
        ...context,
        selectedAddress: payload.address,
    };
};

const updateSecondSelectedAddressContext = (
    context: PickupMachineContext,
    payload: PickupMachineAddAddressPayload,
): PickupMachineContext => {
    return {
        ...context,
        secondSelectedAddress: payload.address,
    };
};

const updateScheduleWindowContext = (
    context: PickupMachineContext,
    payload: PickupMachineAddScheduleWindowPayload,
): PickupMachineContext => {
    return context.newVisit
        ? {
              ...context,
              newVisit: {
                  ...context.newVisit,
                  startTime: payload.scheduleWindow.startTime,
                  endTime: payload.scheduleWindow.endTime,
              },
          }
        : context.selectedAddress
        ? {
              ...context,
              newVisit: {
                  id: '',
                  status: VisitStatus.Created,
                  expectedDuration: 0,
                  startTime: payload.scheduleWindow.startTime,
                  endTime: payload.scheduleWindow.endTime,
              },
          }
        : context;
};

const updateSecondScheduleWindowContext = (
    context: PickupMachineContext,
    payload: PickupMachineAddScheduleWindowPayload,
): PickupMachineContext => {
    return context.secondNewVisit
        ? {
              ...context,
              secondNewVisit: {
                  ...context.secondNewVisit,
                  startTime: payload.scheduleWindow.startTime,
                  endTime: payload.scheduleWindow.endTime,
              },
          }
        : context.secondSelectedAddress
        ? {
              ...context,
              secondNewVisit: {
                  id: '',
                  status: VisitStatus.Created,
                  expectedDuration: 0,
                  startTime: payload.scheduleWindow.startTime,
                  endTime: payload.scheduleWindow.endTime,
              },
          }
        : context;
};

const clearScheduleWindowContext = (context: PickupMachineContext): PickupMachineContext => {
    return context.newVisit && context.selectedAddress
        ? {
              ...context,
              newVisit: {
                  id: '',
                  status: VisitStatus.Created,
                  expectedDuration: 0,
                  startTime: '',
                  endTime: '',
              },
          }
        : context;
};

const clearSecondScheduleWindowContext = (context: PickupMachineContext): PickupMachineContext => {
    return context.secondNewVisit && context.secondSelectedAddress
        ? {
              ...context,
              secondNewVisit: {
                  id: '',
                  status: VisitStatus.Created,
                  expectedDuration: 0,
                  startTime: '',
                  endTime: '',
              },
          }
        : context;
};

const defaultEvents = {};

const itemSelectionState = {
    exit: ['removeErrorMessage'],
    on: {
        ...defaultEvents,
        NEXT: {
            target: 'addressSelection',
            cond: (context: PickupMachineContext) => Object.keys(context.selectedItems).length > 0,
            actions: [
                assign((context: PickupMachineContext) =>
                    setPreviousPage(context, 'itemSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        ADD_ITEM: {
            actions: [
                assign((context: PickupMachineContext, event: PickupMachineAddItemEvent) =>
                    updateSelectedItemsContext(context, event.payload),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const addressSelectionState = {
    exit: ['removeErrorMessage'],
    on: {
        ...defaultEvents,
        BACK: goBack(),
        NEXT: [
            {
                target: 'visitSelection',
                cond: (context: PickupMachineContext) => !!context.selectedAddress,
                actions: [
                    assign((context: PickupMachineContext) =>
                        setPreviousPage(context, 'addressSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                ],
            },
        ],
        ADD_ADDRESS: {
            actions: [
                assign((context: PickupMachineContext, event: PickupMachineAddAddressEvent) =>
                    updateSelectedAddressContext(context, event.payload),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        NEW_ADDRESS: {
            target: 'newAddress',
            actions: [
                assign((context: PickupMachineContext) =>
                    setPreviousPage(context, 'addressSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        NO_LOADED_ADDRESSES: {
            target: 'newAddress',
        },
    },
};

const newAddressState = {
    on: {
        ...defaultEvents,
        BACK: goBack(),
        NEXT: {
            target: 'visitSelection',
            cond: (context: PickupMachineContext) => !!context.selectedAddress,
            actions: [
                assign((context: PickupMachineContext) =>
                    setPreviousPage(context, 'addressSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        ADD_ADDRESS: {
            actions: [
                assign((context: PickupMachineContext, event: PickupMachineAddAddressEvent) =>
                    updateSelectedAddressContext(context, event.payload),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const visitSelectionState = {
    exit: ['removeErrorMessage'],
    on: {
        ...defaultEvents,
        BACK: goBack(),
        NEXT: [
            {
                target: 'containerVisitsSelection',
                // Check if user has selected containers
                cond: (context: PickupMachineContext) =>
                    Object.keys(context.selectedItems).length > 0 &&
                    Object.values(context.selectedItems)
                        .map((item) => item.container)
                        .filter((container) => container === true).length > 0,
                actions: [
                    assign((context: PickupMachineContext) =>
                        setPreviousPage(context, 'visitSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                ],
            },
            {
                target: 'paymentSelection',
                cond: (context: PickupMachineContext): boolean => !!context.selectedVisit,
                actions: [
                    assign((context: PickupMachineContext) =>
                        setPreviousPage(context, 'visitSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                ],
            },
        ],
        ADD_VISIT: {
            actions: [
                assign((context: PickupMachineContext, event: PickupMachineAddVisitEvent) =>
                    updateSelectedVisitContext(context, event.payload),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        NEW_VISIT: {
            target: 'newVisit',
            actions: [
                assign((context: PickupMachineContext) =>
                    setPreviousPage(context, 'visitSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        NO_LOADED_VISITS: {
            target: 'newVisit',
        },
    },
};

const scheduleWindowSelectionState = {
    exit: ['removeErrorMessage'],
    on: {
        ...defaultEvents,
        NEXT: [
            {
                target: '#pickup-request.containerVisitsSelection',
                cond: (context: PickupMachineContext) =>
                    Object.keys(context.selectedItems).length > 0 &&
                    Object.values(context.selectedItems)
                        .map((item) => item.container)
                        .filter((container) => container === true).length > 0,
                actions: [
                    assign((context: PickupMachineContext) =>
                        setPreviousPage(context, 'newVisit.scheduleWindowSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                ],
            },
            {
                target: '#pickup-request.paymentSelection',
                cond: (context: PickupMachineContext): boolean =>
                    !!context.newVisit?.startTime && !!context.newVisit?.endTime,
                actions: [
                    assign((context: PickupMachineContext) =>
                        setPreviousPage(context, 'newVisit.scheduleWindowSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                ],
            },
        ],
        ADD_SCHEDULE_WINDOW: {
            actions: [
                assign(
                    (context: PickupMachineContext, event: PickupMachineAddScheduleWindowEvent) =>
                        updateScheduleWindowContext(context, event.payload),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        CLEAR_SCHEDULE_WINDOW: {
            actions: [
                assign((context: PickupMachineContext) =>
                    clearScheduleWindowContext(context),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const newVisitState = {
    on: {
        ...defaultEvents,
        BACK: goBack(),
    },
    type: 'compound' as const, // Reference: https://github.com/davidkpiano/xstate/issues/965#issuecomment-579773494
    initial: 'scheduleWindowSelection' as const,
    states: {
        scheduleWindowSelection: scheduleWindowSelectionState,
    },
};

const containerVisitsSelectionState = {
    exit: ['removeErrorMessage'],
    on: {
        ...defaultEvents,
        BACK: goBack(),
        ONE_VISIT: [
            {
                target: 'paymentSelection',
                actions: [
                    assign((context: PickupMachineContext) =>
                        setPreviousPage(context, 'containerVisitsSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                    assign((context: PickupMachineContext) => {
                        return {
                            ...context,
                            secondNewVisit: undefined,
                            secondSelectedAddress: undefined,
                            secondSelectedVisit: undefined,
                        };
                    }),
                ],
            },
        ],
        TWO_VISITS: [
            {
                target: 'secondAddressSelection',
                actions: [
                    assign((context: PickupMachineContext) =>
                        setPreviousPage(context, 'containerVisitsSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                ],
            },
        ],
        FIGURE_OUT_LATER: [
            {
                target: 'paymentSelection',
                actions: [
                    assign((context: PickupMachineContext) =>
                        setPreviousPage(context, 'containerVisitsSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                    assign((context: PickupMachineContext) => {
                        return {
                            ...context,
                            containersFigureOutLater: true,
                        };
                    }),
                    assign((context: PickupMachineContext) => {
                        return {
                            ...context,
                            secondNewVisit: undefined,
                            secondSelectedAddress: undefined,
                            secondSelectedVisit: undefined,
                        };
                    }),
                ],
            },
        ],
    },
};

const secondAddressSelectionState = {
    exit: ['removeErrorMessage'],
    on: {
        ...defaultEvents,
        BACK: goBack(),
        NEXT: [
            {
                target: 'secondVisitSelection',
                cond: (context: PickupMachineContext) => !!context.secondSelectedAddress,
                actions: [
                    assign((context: PickupMachineContext) =>
                        setPreviousPage(context, 'secondAddressSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                ],
            },
        ],
        ADD_SECOND_ADDRESS: {
            actions: [
                assign((context: PickupMachineContext, event: PickupMachineAddAddressEvent) =>
                    updateSecondSelectedAddressContext(context, event.payload),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        NEW_SECOND_ADDRESS: {
            target: 'secondNewAddress',
            actions: [
                assign((context: PickupMachineContext) =>
                    setPreviousPage(context, 'secondAddressSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        NO_LOADED_ADDRESSES: {
            target: 'secondNewAddress',
        },
    },
};

const secondNewAddressState = {
    on: {
        ...defaultEvents,
        BACK: goBack(),
        NEXT: {
            target: 'secondVisitSelection',
            cond: (context: PickupMachineContext) => !!context.secondSelectedAddress,
            actions: [
                assign((context: PickupMachineContext) =>
                    setPreviousPage(context, 'secondAddressSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        ADD_SECOND_ADDRESS: {
            actions: [
                assign((context: PickupMachineContext, event: PickupMachineAddAddressEvent) =>
                    updateSecondSelectedAddressContext(context, event.payload),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const secondVisitSelectionState = {
    exit: ['removeErrorMessage'],
    on: {
        ...defaultEvents,
        BACK: goBack(),
        NEXT: [
            {
                target: 'paymentSelection',
                cond: (context: PickupMachineContext): boolean => !!context.secondSelectedVisit,
                actions: [
                    assign((context: PickupMachineContext) =>
                        setPreviousPage(context, 'secondVisitSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                ],
            },
        ],
        ADD_SECOND_VISIT: {
            actions: [
                assign((context: PickupMachineContext, event: PickupMachineAddVisitEvent) =>
                    updateSelectedSecondVisitContext(context, event.payload),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        NEW_SECOND_VISIT: {
            target: 'secondNewVisit',
            actions: [
                assign((context: PickupMachineContext) =>
                    setPreviousPage(context, 'secondVisitSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        NO_LOADED_VISITS: {
            target: 'secondNewVisit',
        },
    },
};

const secondScheduleWindowSelectionState = {
    exit: ['removeErrorMessage'],
    on: {
        ...defaultEvents,
        NEXT: [
            {
                target: '#pickup-request.paymentSelection',
                cond: (context: PickupMachineContext): boolean =>
                    !!context.secondNewVisit?.startTime && !!context.secondNewVisit?.endTime,
                actions: [
                    assign((context: PickupMachineContext) =>
                        setPreviousPage(context, 'secondNewVisit.secondScheduleWindowSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                ],
            },
        ],
        ADD_SECOND_SCHEDULE_WINDOW: {
            actions: [
                assign(
                    (context: PickupMachineContext, event: PickupMachineAddScheduleWindowEvent) =>
                        updateSecondScheduleWindowContext(context, event.payload),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        CLEAR_SECOND_SCHEDULE_WINDOW: {
            actions: [
                assign((context: PickupMachineContext) =>
                    clearSecondScheduleWindowContext(context),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const secondNewVisitState = {
    on: {
        ...defaultEvents,
        BACK: goBack(),
    },
    type: 'compound' as const, // Reference: https://github.com/davidkpiano/xstate/issues/965#issuecomment-579773494
    initial: 'secondScheduleWindowSelection' as const,
    states: {
        secondScheduleWindowSelection: secondScheduleWindowSelectionState,
    },
};

const paymentSelectionState = {
    on: {
        ...defaultEvents,
        BACK: goBack(),
        NEXT: [
            {
                target: 'requestReview',
                cond: (context: PickupMachineContext): boolean => !!context.selectedPaymentMethod,
                actions: [
                    assign((context: PickupMachineContext) =>
                        setPreviousPage(context, 'paymentSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                ],
            },
        ],
        ADD_PAYMENT_METHOD: {
            actions: [
                assign(
                    (context: PickupMachineContext, event: PickupMachineAddPaymentMethodEvent) => {
                        return {
                            ...context,
                            selectedPaymentMethod: event.payload,
                        };
                    },
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        NEW_PAYMENT_METHOD: {
            target: 'newPayment',
            actions: [
                assign((context: PickupMachineContext) =>
                    setPreviousPage(context, 'paymentSelection'),
                ),
                assign((
                    context: PickupMachineContext,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    _: any, // Reference: https://github.com/davidkpiano/xstate/issues/1198#issuecomment-632899035
                ) => removeErrorMessage(context)) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        NO_LOADED_PAYMENT_METHODS: {
            target: 'newPayment',
            actions: [
                assign((context: PickupMachineContext) => removeErrorMessage(context)) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const newPaymentState = {
    entry: ['removePaymentMethodSelection'],
    on: {
        ...defaultEvents,
        BACK: goBack(),
        NEXT: {
            target: 'requestReview',
            cond: (context: PickupMachineContext): boolean => !!context.selectedPaymentMethod,
            actions: [
                assign((context: PickupMachineContext) =>
                    setPreviousPage(context, 'paymentSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        ADD_PAYMENT_METHOD: {
            actions: [
                assign(
                    (context: PickupMachineContext, event: PickupMachineAddPaymentMethodEvent) => {
                        return {
                            ...context,
                            selectedPaymentMethod: event.payload,
                        };
                    },
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const requestReviewState = {
    on: {
        ...defaultEvents,
        BACK: goBack(),
        GO_TO_ITEM_SELECTION: {
            target: 'itemSelection',
            actions: assign((context: PickupMachineContext) => updatePreviousPage(context)) as any, // eslint-disable-line @typescript-eslint/no-explicit-any,
        },
        REQUEST_SUBMITTED: {
            target: 'requestSubmitted',
        },
    },
};

const requestSubmittedState = {
    type: 'final' as 'final',
};

export const PickupMachine = Machine<PickupMachineContext, PickupMachineSchema, PickupMachineEvent>(
    {
        id: 'pickup-request',
        context: {
            previousPage: [],
            errorMessage: undefined,
            containersFigureOutLater: false,
            selectedItems: {},
            selectedVisit: undefined,
            secondSelectedVisit: undefined,
            selectedAddress: undefined,
            secondSelectedAddress: undefined,
            selectedPaymentMethod: undefined,
            newVisit: undefined,
            secondNewVisit: undefined,
        },
        initial: 'itemSelection',
        states: {
            itemSelection: itemSelectionState,
            addressSelection: addressSelectionState,
            newAddress: newAddressState,
            visitSelection: visitSelectionState,
            newVisit: newVisitState,
            containerVisitsSelection: containerVisitsSelectionState,
            secondAddressSelection: secondAddressSelectionState,
            secondNewAddress: secondNewAddressState,
            secondVisitSelection: secondVisitSelectionState,
            secondNewVisit: secondNewVisitState,
            paymentSelection: paymentSelectionState,
            newPayment: newPaymentState,
            requestReview: requestReviewState,
            requestSubmitted: requestSubmittedState,
        },
    },
    {
        actions: {
            removeErrorMessage: assign((context: PickupMachineContext) =>
                removeErrorMessage(context),
            ),
            removeAddressSelection: assign((context: PickupMachineContext) => {
                return {
                    ...context,
                    selectedAddress: undefined,
                };
            }),
            removePaymentMethodSelection: assign((context: PickupMachineContext) => {
                return {
                    ...context,
                    selectedPaymentMethod: undefined,
                };
            }),
        },
    },
);

export const backButtonEnabled = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: State<PickupMachineContext, PickupMachineEvent, any, any>,
): boolean | undefined => {
    return PickupMachine.transition(state, { type: 'BACK' }).changed;
};
