import React from 'react';

import { View, Text } from 'react-native';
import { Button } from '@valet/ui-components';
import { FormattedMessage, useIntl } from 'react-intl';

type RequestSubmittedPageProps = {
    onGoToInventoryPress: () => void;
    onGoToEditMetadataPress: () => void;
};

export const RequestSubmittedPage: React.FC<RequestSubmittedPageProps> = ({
    onGoToInventoryPress,
    onGoToEditMetadataPress,
}) => {
    const intl = useIntl();
    return (
        <View testID="data-requestSubmittedPage">
            <Text>
                <FormattedMessage
                    id="requestSubmitted.confirmationMessage"
                    defaultMessage="The request has been submitted - a confirmation email will be sent shortly."
                />
            </Text>

            <View style={{ marginVertical: 3 }}>
                <Button
                    title={intl.formatMessage({
                        id: 'requestSubmitted.goToInventory',
                        defaultMessage: 'Go to inventory',
                    })}
                    onPress={onGoToInventoryPress}
                />
            </View>

            <View style={{ marginVertical: 3 }}>
                <Button
                    title={intl.formatMessage({
                        id: 'requestSubmitted.goToMetadata',
                        defaultMessage: 'Edit storage item metadata',
                    })}
                    onPress={onGoToEditMetadataPress}
                />
            </View>
        </View>
    );
};
