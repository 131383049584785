import React from 'react';

import { View, Text, StyleSheet } from 'react-native';
import { FormattedMessage } from 'react-intl';

type OrderLoaderProps = {};

export const OrderLoader: React.FC<OrderLoaderProps> = () => {
    return (
        <View style={orderLoaderStyles.ViewParent} testID="data-orderLoader">
            <Text>
                <FormattedMessage id="loading" defaultMessage="Loading..." />
            </Text>
        </View>
    );
};

const orderLoaderStyles = StyleSheet.create({
    ViewParent: {
        flex: 1,
    },
});
