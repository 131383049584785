import React from 'react';

import { View, Text, StyleSheet } from 'react-native';
import { FormattedMessage } from 'react-intl';
import { Card } from '@valet/ui-components';
import { AddressSelectionAddressFragmentFragment } from '../../../../../schema-types';

type AddressItemProps = {
    details: AddressSelectionAddressFragmentFragment;
    selected: boolean;
    onAddressItemSelection: (address: AddressSelectionAddressFragmentFragment) => void;
};

export const AddressItem: React.FC<AddressItemProps> = ({
    details,
    selected,
    onAddressItemSelection,
}) => {
    const { address1, city, zoneId, countryId, zip } = details;

    return (
        <View style={addressStyles.ViewParent}>
            <Card
                onPress={() => onAddressItemSelection(details)}
                testID="data-addressItem"
                status={selected ? 'success' : undefined}
            >
                <Text>{address1}</Text>
                <Text>
                    {/* TODO: Format address based on regions address format */}
                    {city} {zoneId}, {countryId}
                </Text>
                <Text>{zip}</Text>
            </Card>
        </View>
    );
};

type NewAddressItemProps = {
    onNewAddressSelection: () => void;
};

export const NewAddressItem: React.FC<NewAddressItemProps> = ({ onNewAddressSelection }) => {
    return (
        <View style={newAddressStyles.ViewParent}>
            <Card onPress={onNewAddressSelection} testID="data-newAddressItem">
                <Text>
                    <FormattedMessage
                        id="addressSelection.addNewAddress"
                        defaultMessage="Add New Address"
                    />
                </Text>
            </Card>
        </View>
    );
};

const addressStyles = StyleSheet.create({
    ViewParent: {
        marginTop: 5,
        marginBottom: 5,
        width: '100%',
    },
});

const newAddressStyles = StyleSheet.create({
    ViewParent: {
        marginTop: 5,
        marginBottom: 5,
        width: '100%',
    },
});
