import React, { useState, useEffect, createContext, useCallback } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';

import { useApplicationContext } from '../../ApplicationContext';

type HubPayload = {
    event: string;
    data?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    message?: string;
};

type HubCapsule = {
    channel: string;
    payload: HubPayload;
    source: string;
    patternInfo?: string[];
};

type AuthContextProviderProps = {};

type AuthContextType = {
    isAuthenticated: boolean;
    username?: string;
    // This needs to be a function to insure the tokens are refreshed when needed
    getCurrentAccessToken: () => Promise<string | undefined>;
};

const getCurrentAccessToken = async (): Promise<string | undefined> => {
    try {
        const session = await Auth.currentSession();
        return session.getAccessToken().getJwtToken();
        // eslint-disable-next-line no-empty
    } catch (ignore) {}
};

const defaultAuthContextValues = {
    isAuthenticated: false,
    getCurrentAccessToken,
};

export const AuthContext = createContext<AuthContextType>(defaultAuthContextValues);

export const AuthContextProvider: React.FC<AuthContextProviderProps> = (props) => {
    const { children } = props;
    const [authContext, setAuthContext] = useState<AuthContextType>(defaultAuthContextValues);
    const [authContextInitialized, setAuthContextInitialized] = useState<boolean>(false);

    const {
        userPoolId,
        userPoolWebClientId,
        userPoolRegion,
        userHostedDomain,
        userSocialSignInCallback,
        userSocialSignOutCallback,
    } = useApplicationContext();

    const updateAuthContext = useCallback(() => {
        (async () => {
            try {
                const authenticatedUser = await Auth.currentAuthenticatedUser();

                setAuthContext({
                    ...defaultAuthContextValues,
                    isAuthenticated: true,
                    username: authenticatedUser?.username,
                });
                setAuthContextInitialized(true);
            } catch (ignore) {
                setAuthContext(defaultAuthContextValues);
                setAuthContextInitialized(true);
            }
        })();
    }, []);

    useEffect(() => {
        Amplify.configure({
            Auth: {
                region: userPoolRegion,
                userPoolId: userPoolId,
                userPoolWebClientId: userPoolWebClientId,
                oauth: {
                    domain: userHostedDomain,
                    scope: ['email', 'openid'],
                    redirectSignIn: userSocialSignInCallback,
                    redirectSignOut: userSocialSignOutCallback,
                    responseType: 'code',
                },
            },
        });

        updateAuthContext();
    }, [
        userPoolId,
        userPoolWebClientId,
        userPoolRegion,
        userHostedDomain,
        userSocialSignInCallback,
        userSocialSignOutCallback,
        updateAuthContext,
    ]);

    useEffect(() => {
        const authListener = (data: HubCapsule): void => {
            switch (data.payload.event) {
                case 'signIn':
                    updateAuthContext();
                    break;
                case 'signOut':
                    updateAuthContext();
                    break;
                case 'signIn_failure':
                    updateAuthContext();
                    break;
                case 'customState_failure':
                    updateAuthContext();
                    break;
                case 'configured':
                    updateAuthContext();
                    break;
                default:
                    break;
            }
        };

        Hub.listen('auth', authListener);

        return () => {
            Hub.remove('auth', authListener);
        };
    }, [updateAuthContext]);

    // Only render the content once authentication is initialized to prevent a flicker.
    return (
        <AuthContext.Provider value={authContext}>
            {authContextInitialized && children}
        </AuthContext.Provider>
    );
};

export const SignInFunction = (
    email: string,
    password: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
    return Auth.signIn({
        username: email,
        password: password,
    }).catch((err) => {
        throw err;
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ForgotPasswordFunction = (email: string): Promise<any> => {
    return Auth.forgotPassword(email).catch((err) => {
        throw err;
    });
};

export const ResetPasswordFunction = (
    email: string,
    code: string,
    password: string,
): Promise<void> => {
    return Auth.forgotPasswordSubmit(email, code, password).catch((err) => {
        throw err;
    });
};

export const SetNewPasswordPasswordFunction = (
    user: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    password: string,
    requiredAttributes: any, // eslint-disable-line @typescript-eslint/no-explicit-any
): Promise<void> => {
    return Auth.completeNewPassword(user, password, requiredAttributes).catch((err) => {
        throw err;
    });
};

export const SignUpFunction = (
    email: string,
    password: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
    return Auth.signUp({
        username: email,
        password: password,
    }).catch((err) => {
        throw err;
    });
};

export const ConfirmSignUpFunction = (
    email: string,
    code: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
    return Auth.confirmSignUp(email, code).catch((err) => {
        throw err;
    });
};

export const ResendCodeFunction = (email: string): Promise<string> => {
    return Auth.resendSignUp(email).catch((err) => {
        throw err;
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SignOutFunction = (): Promise<any> => {
    return Auth.signOut().catch((err) => {
        throw err;
    });
};
