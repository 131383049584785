import { Machine, assign, State } from 'xstate';

import { CustomerPaymentMethod } from '../Components/PaymentSelection/PaymentSelection';
import { ScheduleWindow } from '../Components/VisitSelection/NewVisit/ScheduleWindowSelection';
import { CustomerAddress } from '../Components/AddressSelection/AddressSelection';
import { ErrorMessage } from '@valet/ui-components';
import { VisitStatus, VisitSelectionVisitFragmentFragment } from '../../../../schema-types';

interface DeliveryMachineSchema {
    states: {
        itemSelection: {};
        addressSelection: {};
        newAddress: {};
        visitSelection: {};
        newVisit: {
            type: 'compound';
            states: {
                scheduleWindowSelection: {};
            };
        };
        paymentSelection: {};
        newPayment: {};
        requestReview: {};
        requestSubmitted: {};
    };
}

type DeliveryMachineNextEvent = { type: 'NEXT' };
type DeliveryMachineBackEvent = { type: 'BACK' };

type DeliveryMachineNewVisitEvent = { type: 'NEW_VISIT' };
type DeliveryMachineNewAddressEvent = { type: 'NEW_ADDRESS' };
type DeliveryMachineNewPaymentMethodEvent = { type: 'NEW_PAYMENT_METHOD' };

export type DeliveryMachineAddItemPayload = {
    delivery: {
        storageItemId: string;
        storageItemTypeName: string;
        description: string;
        container: boolean;
    }[];
    pickup: {
        storageItemId: string;
        storageItemTypeName: string;
        description: string;
        container: boolean;
    }[];
    emptyContainers: {
        storageContainerId: string;
    }[];
};

type DeliveryMachineAddVisitPayload = { visit: VisitSelectionVisitFragmentFragment };
type DeliveryMachineAddAddressPayload = { address: CustomerAddress };

type DeliveryMachineAddScheduleWindowPayload = { scheduleWindow: ScheduleWindow };
type DeliveryMachineAddPaymentMethodPayload = CustomerPaymentMethod;

type DeliveryMachineAddItemEvent = {
    type: 'ADD_ITEM';
    payload: DeliveryMachineAddItemPayload;
};
type DeliveryMachineAddVisitEvent = {
    type: 'ADD_VISIT';
    payload: DeliveryMachineAddVisitPayload;
};
type DeliveryMachineAddAddressEvent = {
    type: 'ADD_ADDRESS';
    payload: DeliveryMachineAddAddressPayload;
};
type DeliveryMachineAddScheduleWindowEvent = {
    type: 'ADD_SCHEDULE_WINDOW';
    payload: DeliveryMachineAddScheduleWindowPayload;
};
type DeliveryMachineClearScheduleWindowEvent = {
    type: 'CLEAR_SCHEDULE_WINDOW';
};
type DeliveryMachineAddPaymentMethodEvent = {
    type: 'ADD_PAYMENT_METHOD';
    payload: DeliveryMachineAddPaymentMethodPayload;
};
type DeliveryMachineRequestSubmittedEvent = {
    type: 'REQUEST_SUBMITTED';
};

export type DeliveryMachineEvent =
    | DeliveryMachineNextEvent
    | DeliveryMachineBackEvent
    | DeliveryMachineAddItemEvent
    | DeliveryMachineAddVisitEvent
    | DeliveryMachineNewVisitEvent
    | DeliveryMachineAddScheduleWindowEvent
    | DeliveryMachineAddAddressEvent
    | DeliveryMachineNewAddressEvent
    | DeliveryMachineClearScheduleWindowEvent
    | DeliveryMachineAddPaymentMethodEvent
    | DeliveryMachineNewPaymentMethodEvent
    | DeliveryMachineRequestSubmittedEvent;

export type DeliveryMachineContext = {
    previousPage: string[];
    errorMessage: ErrorMessage | undefined;
    selectedItems: {
        delivery: {
            storageItemId: string;
            storageItemTypeName: string;
            description: string;
            container: boolean;
        }[];
        pickup: {
            storageItemId: string;
            storageItemTypeName: string;
            description: string;
            container: boolean;
        }[];
        emptyContainers: {
            storageContainerId: string;
        }[];
    };
    selectedVisit: VisitSelectionVisitFragmentFragment | undefined;
    selectedAddress: CustomerAddress | undefined;
    selectedPaymentMethod: CustomerPaymentMethod | undefined;
    newVisit: VisitSelectionVisitFragmentFragment | undefined;
};

const setPreviousPage = (context: DeliveryMachineContext, page: string): DeliveryMachineContext => {
    return {
        ...context,
        previousPage: [page, ...context.previousPage],
    };
};

const updatePreviousPage = (context: DeliveryMachineContext): DeliveryMachineContext => {
    return {
        ...context,
        previousPage: [...context.previousPage.slice(1)],
    };
};

const goBack = (): Array<{}> => {
    return [
        {
            target: 'newAddress',
            cond: (context: DeliveryMachineContext) => context.previousPage[0] === 'newAddress',
            actions: assign((context: DeliveryMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'addressSelection',
            cond: (context: DeliveryMachineContext) =>
                context.previousPage[0] === 'addressSelection',
            actions: assign((context: DeliveryMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'visitSelection',
            cond: (context: DeliveryMachineContext) => context.previousPage[0] === 'visitSelection',
            actions: assign((context: DeliveryMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'newVisit.scheduleWindowSelection',
            cond: (context: DeliveryMachineContext) =>
                context.previousPage[0] === 'newVisit.scheduleWindowSelection',
            actions: assign((context: DeliveryMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'paymentSelection',
            cond: (context: DeliveryMachineContext) =>
                context.previousPage[0] === 'paymentSelection',
            actions: assign((context: DeliveryMachineContext) => updatePreviousPage(context)),
        },
        {
            target: 'newPayment',
            cond: (context: DeliveryMachineContext) => context.previousPage[0] === 'newPayment',
            actions: assign((context: DeliveryMachineContext) => updatePreviousPage(context)),
        },
    ];
};

const updateErrorMessage = (
    context: DeliveryMachineContext,
    errorMessage: ErrorMessage | undefined,
): DeliveryMachineContext => {
    return {
        ...context,
        errorMessage: errorMessage,
    };
};

const removeErrorMessage = (context: DeliveryMachineContext): DeliveryMachineContext => {
    if (context.errorMessage) {
        return updateErrorMessage(context, undefined);
    }

    return context;
};

const updateSelectedVisitContext = (
    context: DeliveryMachineContext,
    payload: DeliveryMachineAddVisitPayload,
): DeliveryMachineContext => {
    return {
        ...context,
        selectedVisit: payload.visit,
    };
};

const updateSelectedAddressContext = (
    context: DeliveryMachineContext,
    payload: DeliveryMachineAddAddressPayload,
): DeliveryMachineContext => {
    return {
        ...context,
        selectedAddress: payload.address,
    };
};

const updateScheduleWindowContext = (
    context: DeliveryMachineContext,
    payload: DeliveryMachineAddScheduleWindowPayload,
): DeliveryMachineContext => {
    return context.newVisit
        ? {
              ...context,
              newVisit: {
                  ...context.newVisit,
                  startTime: payload.scheduleWindow.startTime,
                  endTime: payload.scheduleWindow.endTime,
              },
          }
        : context.selectedAddress
        ? {
              ...context,
              newVisit: {
                  id: '',
                  status: VisitStatus.Created,
                  expectedDuration: 0,
                  startTime: payload.scheduleWindow.startTime,
                  endTime: payload.scheduleWindow.endTime,
              },
          }
        : context;
};

const clearScheduleWindowContext = (context: DeliveryMachineContext): DeliveryMachineContext => {
    return context.newVisit && context.selectedAddress
        ? {
              ...context,
              newVisit: {
                  id: '',
                  status: VisitStatus.Created,
                  expectedDuration: 0,
                  startTime: '',
                  endTime: '',
              },
          }
        : context;
};

const defaultEvents = {};

const itemSelectionState = {
    always: [
        {
            target: 'addressSelection',
            cond: (context: DeliveryMachineContext) =>
                context.selectedItems.delivery.length > 0 ||
                context.selectedItems.pickup.length > 0 ||
                context.selectedItems.emptyContainers.length > 0,
        },
    ],
    on: {
        ADD_ITEM: {
            actions: [
                assign((context: DeliveryMachineContext, event: DeliveryMachineAddItemEvent) => {
                    return {
                        ...context,
                        selectedItems: event.payload,
                    };
                }) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const addressSelectionState = {
    exit: ['removeErrorMessage'],
    on: {
        ...defaultEvents,
        BACK: goBack(),
        NEXT: [
            {
                target: 'visitSelection',
                cond: (context: DeliveryMachineContext) => !!context.selectedAddress,
                actions: [
                    assign((context: DeliveryMachineContext) =>
                        setPreviousPage(context, 'addressSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                ],
            },
        ],
        ADD_ADDRESS: {
            actions: [
                assign((context: DeliveryMachineContext, event: DeliveryMachineAddAddressEvent) =>
                    updateSelectedAddressContext(context, event.payload),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        NEW_ADDRESS: {
            target: 'newAddress',
            actions: [
                assign((context: DeliveryMachineContext) =>
                    setPreviousPage(context, 'addressSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const newAddressState = {
    on: {
        ...defaultEvents,
        BACK: goBack(),
        NEXT: {
            target: 'visitSelection',
            cond: (context: DeliveryMachineContext) => !!context.selectedAddress,
            actions: [
                assign((context: DeliveryMachineContext) =>
                    setPreviousPage(context, 'addressSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        ADD_ADDRESS: {
            actions: [
                assign((context: DeliveryMachineContext, event: DeliveryMachineAddAddressEvent) =>
                    updateSelectedAddressContext(context, event.payload),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const visitSelectionState = {
    exit: ['removeErrorMessage'],
    on: {
        ...defaultEvents,
        BACK: goBack(),
        NEXT: {
            target: 'paymentSelection',
            cond: (context: DeliveryMachineContext): boolean => !!context.selectedVisit,
            actions: [
                assign((context: DeliveryMachineContext) =>
                    setPreviousPage(context, 'visitSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        ADD_VISIT: {
            actions: [
                assign((context: DeliveryMachineContext, event: DeliveryMachineAddVisitEvent) =>
                    updateSelectedVisitContext(context, event.payload),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        NEW_VISIT: {
            target: 'newVisit',
            actions: [
                assign((context: DeliveryMachineContext) =>
                    setPreviousPage(context, 'visitSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const scheduleWindowSelectionState = {
    exit: ['removeErrorMessage'],
    on: {
        ...defaultEvents,
        NEXT: {
            target: '#delivery-request.paymentSelection',
            cond: (context: DeliveryMachineContext): boolean =>
                !!context.newVisit?.startTime && !!context.newVisit?.endTime,
            actions: [
                assign((context: DeliveryMachineContext) =>
                    setPreviousPage(context, 'newVisit.scheduleWindowSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        ADD_SCHEDULE_WINDOW: {
            actions: [
                assign(
                    (
                        context: DeliveryMachineContext,
                        event: DeliveryMachineAddScheduleWindowEvent,
                    ) => updateScheduleWindowContext(context, event.payload),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        CLEAR_SCHEDULE_WINDOW: {
            actions: [
                assign((context: DeliveryMachineContext) =>
                    clearScheduleWindowContext(context),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const newVisitState = {
    on: {
        ...defaultEvents,
        BACK: goBack(),
    },
    type: 'compound' as const, // Reference: https://github.com/davidkpiano/xstate/issues/965#issuecomment-579773494
    initial: 'scheduleWindowSelection' as const,
    states: {
        scheduleWindowSelection: scheduleWindowSelectionState,
    },
};

const paymentSelectionState = {
    on: {
        ...defaultEvents,
        BACK: goBack(),
        NEXT: [
            {
                target: 'requestReview',
                cond: (context: DeliveryMachineContext): boolean => !!context.selectedPaymentMethod,
                actions: [
                    assign((context: DeliveryMachineContext) =>
                        setPreviousPage(context, 'paymentSelection'),
                    ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                ],
            },
        ],
        ADD_PAYMENT_METHOD: {
            actions: [
                assign(
                    (
                        context: DeliveryMachineContext,
                        event: DeliveryMachineAddPaymentMethodEvent,
                    ) => {
                        return {
                            ...context,
                            selectedPaymentMethod: event.payload,
                        };
                    },
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        NEW_PAYMENT_METHOD: {
            target: 'newPayment',
            actions: [
                assign((context: DeliveryMachineContext) =>
                    setPreviousPage(context, 'paymentSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                assign((context: DeliveryMachineContext) => removeErrorMessage(context)) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const newPaymentState = {
    entry: ['removePaymentMethodSelection'],
    on: {
        ...defaultEvents,
        BACK: goBack(),
        NEXT: {
            target: 'requestReview',
            cond: (context: DeliveryMachineContext): boolean => !!context.selectedPaymentMethod,
            actions: [
                assign((context: DeliveryMachineContext) =>
                    setPreviousPage(context, 'paymentSelection'),
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
        ADD_PAYMENT_METHOD: {
            actions: [
                assign(
                    (
                        context: DeliveryMachineContext,
                        event: DeliveryMachineAddPaymentMethodEvent,
                    ) => {
                        return {
                            ...context,
                            selectedPaymentMethod: event.payload,
                        };
                    },
                ) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            ],
        },
    },
};

const requestReviewState = {
    on: {
        ...defaultEvents,
        BACK: goBack(),
        REQUEST_SUBMITTED: {
            target: 'requestSubmitted',
        },
    },
};

const requestSubmittedState = {
    type: 'final' as 'final',
};

export const DeliveryMachine = Machine<
    DeliveryMachineContext,
    DeliveryMachineSchema,
    DeliveryMachineEvent
>(
    {
        id: 'delivery-request',
        context: {
            previousPage: [],
            errorMessage: undefined,
            selectedItems: {
                delivery: [],
                pickup: [],
                emptyContainers: [],
            },
            selectedVisit: undefined,
            selectedAddress: undefined,
            selectedPaymentMethod: undefined,
            newVisit: undefined,
        },
        initial: 'itemSelection',
        states: {
            itemSelection: itemSelectionState,
            addressSelection: addressSelectionState,
            newAddress: newAddressState,
            visitSelection: visitSelectionState,
            newVisit: newVisitState,
            paymentSelection: paymentSelectionState,
            newPayment: newPaymentState,
            requestReview: requestReviewState,
            requestSubmitted: requestSubmittedState,
        },
    },
    {
        actions: {
            removeErrorMessage: assign((context: DeliveryMachineContext) =>
                removeErrorMessage(context),
            ),
            removeAddressSelection: assign((context: DeliveryMachineContext) => {
                return {
                    ...context,
                    selectedAddress: undefined,
                };
            }),
            removePaymentMethodSelection: assign((context: DeliveryMachineContext) => {
                return {
                    ...context,
                    selectedPaymentMethod: undefined,
                };
            }),
        },
    },
);

export const backButtonEnabled = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: State<DeliveryMachineContext, DeliveryMachineEvent, any, any>,
): boolean | undefined => {
    return DeliveryMachine.transition(state, { type: 'BACK' }).changed;
};
