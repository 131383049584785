import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { format, addDays, parseISO, set } from 'date-fns';

import { View, Text, StyleSheet } from 'react-native';
import { OrderLoader } from '../../OrderLoader';

import { FormattedMessage, FormattedDate, useIntl } from 'react-intl';
import { Card, Calendar } from '@valet/ui-components';
import {
    useRetrieveScheduleWindowsQuery,
    VisitSelectionVisitFragmentFragment,
} from '../../../../../../schema-types';

export type ScheduleWindow = {
    startTime: string;
    endTime: string;
    isLocked: boolean;
};

export const PICKUP_RETRIEVE_SCHEDULE_WINDOWS_QUERY = gql`
    query RetrieveScheduleWindows($zipCode: String!, $startDate: DateTime!, $endDate: DateTime!) {
        territoryByZipCode(zipCode: $zipCode) {
            schedule(startDate: $startDate, endDate: $endDate, requestType: PICKUP_STORAGE_ITEM) {
                startTime
                endTime
                isLocked
            }
        }
    }
`;

type DatesData = Record<string, ScheduleWindow[]>;

type IsDateAvailable = boolean;
type DatesAvailability = Record<string, IsDateAvailable>;

// Date Range
const startDate = set(addDays(new Date(), 0), { seconds: 0, milliseconds: 0 });
const endDate = set(addDays(new Date(), 90), { seconds: 0, milliseconds: 0 });

type ScheduleWindowSelectionPageProps = {
    newVisitDetails: VisitSelectionVisitFragmentFragment;
    onClearNewVisitDate: () => void;
    onScheduleWindowSelection: (scheduleWindow: ScheduleWindow) => void;
    zip?: string;
};

export const ScheduleWindowSelectionPage: React.FC<ScheduleWindowSelectionPageProps> = ({
    newVisitDetails,
    onClearNewVisitDate,
    onScheduleWindowSelection,
    zip,
}) => {

    const [selectedDate, setSelectedDate] = useState<Date | undefined>(
        newVisitDetails.startTime ? parseISO(newVisitDetails.startTime) : undefined,
    );


    const [availableDates, setAvailableDates] = useState<DatesAvailability | undefined>(undefined);
    const [datesData, setDatesData] = useState<DatesData | undefined>(undefined);

    const { data, loading, error } = useRetrieveScheduleWindowsQuery({
        variables: {
            zipCode: zip ?? '',
            startDate: new Date(startDate).toISOString(),
            endDate: new Date(endDate).toISOString(),
        },
    });

    const scheduleWindows: ScheduleWindow[] = data?.territoryByZipCode?.schedule ?? [];

    if (error) {
        return (
            <View testID="data-scheduleWindowSelectionPage">
                <Text>
                    <FormattedMessage
                        id="errorLoading"
                        defaultMessage="There was an error. Please reload this page."
                    />
                </Text>
            </View>
        );
    }

    if (loading) {
        return (
            <View testID="data-scheduleWindowSelectionPage">
                <OrderLoader />
            </View>
        );
    }

    if (data && scheduleWindows.length > 0) {
        if (!availableDates || !datesData) {
            const [datesAvailability, datesData] = processDates(scheduleWindows);
            setAvailableDates(datesAvailability);
            setDatesData(datesData);
        }

        const isDateAvailable = (date: Date): boolean => {
            if (availableDates) {
                if (availableDates[format(date, 'yyyy-MM-dd')] !== undefined) {
                    return !availableDates[format(date, 'yyyy-MM-dd')];
                }
            }

            return false;
        };

        const handleChangeSelectedDate = (date: Date): void => {
            setSelectedDate(date);
        };

        const handleClearDate = (): void => {
            setSelectedDate(undefined);
            onClearNewVisitDate();
        };

        return (
            <View style={scheduleWindowStyles.ViewParent} testID="data-scheduleWindowSelectionPage">
                <View
                    style={scheduleWindowStyles.ViewDatePicker}
                    testID="data-scheduleWindowDatepicker"
                >
                    <Calendar
                        date={selectedDate ?? startDate}
                        onSelect={handleChangeSelectedDate}
                        min={startDate}
                        filter={isDateAvailable}
                        testID="data-scheduleWindowCalendar"
                        renderDay={DayCell}
                    />
                </View>

                {selectedDate && (
                    <Text style={scheduleWindowStyles.TextClearDate} onPress={handleClearDate}>
                        <FormattedMessage id="clear" defaultMessage="Clear" />
                    </Text>
                )}

                <ScheduleWindowParent
                    selectedDate={selectedDate}
                    datesData={datesData}
                    newVisitDetails={newVisitDetails}
                    onScheduleWindowSelection={onScheduleWindowSelection}
                />
            </View>
        );
    }

    return (
        <Text>
            <FormattedMessage
                id="scheduleWindowSelection.noAvailableWindowsForAddress"
                defaultMessage="There are no available schedule windows for this address."
            />
        </Text>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DayCell = (calendarInfo: any, style: any): JSX.Element => {
    const { date } = calendarInfo;

    return (
        <View
            style={[dayCellStyles.dayContainer, style.container]}
            testID="data-scheduleWindowDayCell"
        >
            <Text
                style={style.text}
                testID="data-scheduleWindowDayCellText"
            >{`${date.getDate()}`}</Text>
        </View>
    );
};

const dayCellStyles = StyleSheet.create({
    dayContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        aspectRatio: 1,
    },
});

type ScheduleWindowParentProps = {
    selectedDate: Date | undefined;
    datesData: DatesData | undefined;
    newVisitDetails: VisitSelectionVisitFragmentFragment;
    onScheduleWindowSelection: (scheduleWindow: ScheduleWindow) => void;
};

const ScheduleWindowParent: React.FC<ScheduleWindowParentProps> = ({
    selectedDate,
    datesData,
    newVisitDetails,
    onScheduleWindowSelection,
}) => {
    const [dateData, setDateData] = useState<ScheduleWindow[] | undefined>(undefined);

    useEffect(() => {
        if (selectedDate && datesData) {
            //TODO: Use date format from locale
            const formattedDate: string = format(selectedDate, 'yyyy-MM-dd');
            setDateData(datesData[`${formattedDate}`]);
        }
    }, [selectedDate, datesData]);

    return (
        <>
            {selectedDate ? (
                <Text style={scheduleWindowStyles.TextHeader}>
                    <FormattedMessage
                        id="scheduleWindowSelection.selectTime"
                        defaultMessage="Select a time"
                    />
                </Text>
            ) : (
                <Text style={scheduleWindowStyles.TextHeader}>
                    <FormattedMessage
                        id="scheduleWindowSelection.selectDate"
                        defaultMessage="Select a date"
                    />
                </Text>
            )}

            {selectedDate && (
                <View>
                    <ScheduleWindows
                        selectedDate={selectedDate}
                        scheduleWindows={dateData}
                        newVisitDetails={newVisitDetails}
                        onScheduleWindowSelection={onScheduleWindowSelection}
                    />
                </View>
            )}
        </>
    );
};

type ScheduleWindowsProps = {
    selectedDate: Date | undefined;
    scheduleWindows: ScheduleWindow[] | undefined;
    newVisitDetails: VisitSelectionVisitFragmentFragment;
    onScheduleWindowSelection: (scheduleWindow: ScheduleWindow) => void;
};

const ScheduleWindows: React.FC<ScheduleWindowsProps> = ({
    selectedDate,
    scheduleWindows,
    newVisitDetails,
    onScheduleWindowSelection,
}) => {
    if (selectedDate) {
        return (
            <View>
                <Text>
                    <FormattedDate value={selectedDate} />
                </Text>

                {scheduleWindows?.map((window: ScheduleWindow) => (
                    <ScheduleWindowItem
                        scheduleWindow={window}
                        key={window.startTime}
                        newVisitDetails={newVisitDetails}
                        onScheduleWindowSelection={onScheduleWindowSelection}
                    />
                ))}
            </View>
        );
    }

    // No selected date
    return <OrderLoader />;
};

type ScheduleWindowItemProps = {
    scheduleWindow: ScheduleWindow;
    newVisitDetails: VisitSelectionVisitFragmentFragment;
    onScheduleWindowSelection: (scheduleWindow: ScheduleWindow) => void;
};

const ScheduleWindowItem: React.FC<ScheduleWindowItemProps> = ({
    scheduleWindow,
    newVisitDetails,
    onScheduleWindowSelection,
}) => {
    const intl = useIntl();

    const { isLocked, startTime, endTime } = scheduleWindow;
    const [selected, setSelected] = useState<boolean>(false);

    useEffect(() => {
        if (newVisitDetails) {
            setSelected(
                newVisitDetails.startTime === scheduleWindow.startTime &&
                newVisitDetails.endTime === scheduleWindow.endTime,
            );
        }
    }, [newVisitDetails, scheduleWindow]);

    return (
        <Card
            style={[
                isLocked === false
                    ? scheduleWindowStyles.TouchableScheduleWindowAvailable
                    : scheduleWindowStyles.TouchableScheduleWindowUnavailable,
                selected && scheduleWindowStyles.TouchableScheduleWindowSelected,
            ]}
            onPress={() => {
                onScheduleWindowSelection(scheduleWindow);
            }}
            activeOpacity={isLocked === false ? 0.2 : 1}
            testID={
                isLocked === false
                    ? `data-scheduleWindowItemAvailable`
                    : `data-scheduleWindowItemUnavailable`
            }
        >
            <Text
                style={{
                    color: isLocked === false ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.2)',
                    fontWeight: '600',
                }}
            >
                <FormattedMessage
                    id="scheduleWindowSelection.visitWindowbetweenTimes"
                    defaultMessage="Between {startTime} and {endTime}"
                    values={{
                        startTime: intl.formatTime(parseISO(startTime), {
                            hour: 'numeric',
                            minute: 'numeric',
                        }),

                        endTime: intl.formatTime(parseISO(endTime), {
                            hour: 'numeric',
                            minute: 'numeric',
                        }),
                    }}
                />
            </Text>

            <Text
                style={
                    isLocked === false
                        ? scheduleWindowStyles.TextStatusAvailable
                        : scheduleWindowStyles.TextStatusUnavailable
                }
            >
                {isLocked === false && (
                    <FormattedMessage
                        id="scheduleWindowSelection.available"
                        defaultMessage="Available"
                    />
                )}
                {isLocked === true && (
                    <FormattedMessage
                        id="scheduleWindowSelection.unavailable"
                        defaultMessage="Unavailable"
                    />
                )}
            </Text>
        </Card>
    );
};

function processDates(scheduleWindows: ScheduleWindow[]): [DatesAvailability, DatesData] {
    const datesAvailability: DatesAvailability = {};
    const datesData: DatesData = {};

    let i = 0;

    while (i < scheduleWindows.length) {
        const key = format(parseISO(scheduleWindows[i].startTime), 'yyyy-MM-dd');

        // Check to see if there is at least one unlocked schedule window for the date
        if (datesAvailability[key] === undefined) {
            datesAvailability[key] = scheduleWindows[i].isLocked;
        } else if (datesAvailability[key] === true) {
            datesAvailability[key] = scheduleWindows[i].isLocked;
        }

        // Sort & pass schedule window data to datesData object
        if (datesData[key] === undefined) {
            datesData[key] = [scheduleWindows[i]];
        } else {
            datesData[key] = [...datesData[key], scheduleWindows[i]];
        }

        i++;
    }

    return [datesAvailability, datesData];
}

const scheduleWindowStyles = StyleSheet.create({
    ViewParent: {},
    ViewDatePicker: {},
    TouchableScheduleWindowAvailable: {
        marginTop: 5,
    },
    TouchableScheduleWindowUnavailable: {
        marginTop: 5,
        borderColor: 'rgba(0, 0, 0, 0.2)',
    },
    TouchableScheduleWindowSelected: {
        marginTop: 5,
        borderColor: 'rgba(39, 174, 96, 1)',
    },
    TextClearDate: {
        marginTop: 5,
        color: 'rgb(9, 132, 227, 0.5)',
        alignSelf: 'flex-start',
    },
    TextHeader: {
        marginTop: 5,
        fontWeight: '700',
    },
    TextStatusAvailable: {
        color: 'rgba(39, 174, 96, 1)',
    },
    TextStatusUnavailable: {
        color: 'rgba(192, 57, 43, 0.4)',
    },
});
