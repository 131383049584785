import React from 'react';

import { View, Text, StyleSheet } from 'react-native';
import { Card } from '@valet/ui-components';
import { format, parseISO } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { VisitSelectionVisitFragmentFragment } from '../../../../../schema-types';

type VisitItemProps = {
    details: VisitSelectionVisitFragmentFragment;
    selected: boolean;
    onVisitItemSelection: (visit: VisitSelectionVisitFragmentFragment) => void;
};

export const VisitItem: React.FC<VisitItemProps> = ({
    details,
    selected,
    onVisitItemSelection,
}) => {
    const intl = useIntl();

    const { startTime, endTime } = details;

    return (
        <View style={visitStyles.ViewParent}>
            <Card
                onPress={() => onVisitItemSelection(details)}
                testID="data-visitItem"
                status={selected ? 'success' : undefined}
            >
                <Text>{format(parseISO(startTime), 'MMMM dd yyyy')}</Text>
                <Text>
                    <FormattedMessage
                        id="pickupRequest.visitWindowbetweenTimes"
                        defaultMessage="Between {startTime} and {endTime}"
                        values={{
                            startTime: intl.formatTime(parseISO(startTime), {
                                hour: 'numeric',
                                minute: 'numeric',
                            }),

                            endTime: intl.formatTime(parseISO(endTime), {
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                        }}
                    />
                </Text>
            </Card>
        </View>
    );
};

type NewVisitItemProps = {
    onNewVisitSelection: () => void;
};

export const NewVisitItem: React.FC<NewVisitItemProps> = ({ onNewVisitSelection }) => {
    return (
        <View style={newVisitStyles.ViewParent}>
            <Card onPress={onNewVisitSelection} testID="data-newVisitItem">
                <Text>
                    <FormattedMessage
                        id="pickupRequest.createNewVisit"
                        defaultMessage="Create New Visit"
                    />
                </Text>
            </Card>
        </View>
    );
};

const visitStyles = StyleSheet.create({
    ViewParent: {
        marginTop: 5,
        marginBottom: 5,
        width: '100%',
    },
});

const newVisitStyles = StyleSheet.create({
    ViewParent: {
        marginTop: 5,
        marginBottom: 5,
        width: '100%',
    },
});
