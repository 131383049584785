import React, { useState, useCallback, useEffect } from 'react';
import { gql } from '@apollo/client';

import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { Card } from '@valet/ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    useRetrieveAllVisitsQuery, VisitSelectionVisitFragmentFragment,
} from '../../../schema-types';
import { format, parseISO } from 'date-fns';

type VisitManagementPageProps = {
    handleVisitManagementSelect: (visitId: string) => void;
};

const VISIT_SELECTION_VISIT_FRAGMENT = gql`
    fragment VisitManagement on Visit {
        id
        status
        startTime
        endTime
        expectedDuration
    }
`;

export const PICKUP_RETRIEVE_VISITS_QUERY = gql`
    query RetrieveAllVisits($pageSize: Int, $cursor: String) {
        currentCustomer {
            id
            visits(
                filter: {
                    pageSize: $pageSize
                    after: $cursor                   
                }
            ) {
                edges {
                    cursor
                    node {
                        id
                        ...VisitManagement
                    }
                }
                pageInfo {
                    cursor {
                        beforeCursor
                        afterCursor
                    }
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${VISIT_SELECTION_VISIT_FRAGMENT}
`;

export const visitManagementPageSize = 50;

export const VisitManagementPage: React.FC<VisitManagementPageProps> = ({
    handleVisitManagementSelect
}) => {

    const { data, loading, error, fetchMore } = useRetrieveAllVisitsQuery({
        variables: { pageSize: visitManagementPageSize, cursor: '' },
    });
    const visitEdges = data?.currentCustomer?.visits?.edges ?? [];
    const visits = visitEdges.map((visitEdge: any) => visitEdge?.node);
    const hasNextPage = data?.currentCustomer?.visits?.pageInfo?.hasNextPage ?? true;
    const endCursor = data?.currentCustomer?.visits?.pageInfo?.cursor?.afterCursor;
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const handleEndOfPageScroll = useCallback(() => {
        if (hasNextPage) {
            setIsFetching(true);
            fetchMore({
                variables: {
                    pageSize: visitManagementPageSize,
                    cursor: `${endCursor}`,
                },
            }).then(() => setIsFetching(false));
        }
    }, [endCursor, hasNextPage, fetchMore]);

    useEffect(() => {
        const windowHeight = window.innerHeight;
        const pageElement = document.querySelector('[data-testid="data-visitManagementPage"]');

        if (pageElement) {
            const pageWindow = parseInt(
                window.getComputedStyle(pageElement).height.replace('px', ''),
            );

            if (windowHeight > pageWindow) {
                if (data && !isFetching) {
                    handleEndOfPageScroll();
                }
            }
        }
    }, [data, handleEndOfPageScroll, isFetching]);

    const handleItemClick = useCallback(
        (id: string) => {
            handleVisitManagementSelect(id);
        },
        [handleVisitManagementSelect],
    );



    return (
        <ScrollView>
            <View testID="data-visitManagementPage">

                {loading &&
                    (
                        <View testID="data-errorLoading">
                            <Text>
                                <FormattedMessage
                                    id="errorLoading"
                                    defaultMessage="loading..."
                                />
                            </Text>
                        </View>
                    )}
                {error &&
                    (
                        <View testID="data-error">
                            <Text>
                                <FormattedMessage
                                    id="error"
                                    defaultMessage="There was an error. Please reload this page."
                                />
                            </Text>
                        </View>
                    )}
                {!data && visits.length < 0 &&
                    (
                        <Text>
                            <FormattedMessage
                                id="visitManagement.noUpcomingVisits"
                                defaultMessage="No upcoming visits."
                            />
                        </Text>
                    )}

                {data && visits.length > 0 &&
                    (<View style={visitManagementPageStyles.ViewContent}>
                        <Text style={visitManagementPageStyles.TextHeader}>
                            <FormattedMessage
                                id="visitManagement.selectVisitHeader"
                                defaultMessage="Select a Visit"
                            />
                        </Text>

                        <>
                            {visits.length === 0 ? (
                                <Text>
                                    <FormattedMessage
                                        id="visitManagement.noUpcomingVisits"
                                        defaultMessage="No upcoming visits."
                                    />
                                </Text>
                            ) : (
                                <>
                                    {visits.map((visit) => (
                                        <VisitManagementItem
                                            key={visit.id}
                                            details={visit}
                                            selected={false}
                                            onVisitItemManagement={handleItemClick}
                                        />
                                    ))}
                                </>
                            )}
                        </>
                    </View>)}
            </View>
        </ScrollView>
    );
}


const visitManagementPageStyles = StyleSheet.create({
    TextHeader: {
        fontSize: 20,
        fontWeight: '700',
    },
    TextContentHeader: {
        marginVertical: 5,
        fontSize: 18,
        fontWeight: '700',
    },
    ViewContent: {
        paddingVertical: 5,
    },
});

type VisitManagementItemProps = {
    details: VisitSelectionVisitFragmentFragment;
    selected: boolean;
    onVisitItemManagement: (visitId: string) => void;

};

const VisitManagementItem: React.FC<VisitManagementItemProps> = ({
    details,
    selected,
    onVisitItemManagement,
}) => {
    const intl = useIntl();

    const { startTime, endTime, status } = details;

    return (
        <View style={visitStyles.ViewParent}>
            <Card
                onPress={() => onVisitItemManagement(details?.id || '')}
                testID="data-visitItem"
                status={selected ? 'success' : undefined}
            >
                <Text>{format(parseISO(startTime), 'MMMM dd yyyy')}</Text>
                <Text>
                    <FormattedMessage
                        id="visitManagement.visitWindowbetweenTimes"
                        defaultMessage="Between {startTime} and {endTime}"
                        values={{
                            startTime: intl.formatTime(parseISO(startTime), {
                                hour: 'numeric',
                                minute: 'numeric',
                            }),

                            endTime: intl.formatTime(parseISO(endTime), {
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                        }}
                    />
                </Text>
                <Text>{status}</Text>
            </Card>
        </View>
    );
};

const visitStyles = StyleSheet.create({
    ViewParent: {
        marginTop: 5,
        marginBottom: 5,
        width: '100%',
    },
});



