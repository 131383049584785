import React, { useState } from 'react';

import { View, Text } from 'react-native';
import { TextInput, Button, Message, ErrorMessage } from '@valet/ui-components';

import { HandleAuthFormInputChange, HandleAuthFormSubmit } from './AuthTypes';
import { authStyles } from './SignIn';
import { useIntl, FormattedMessage } from 'react-intl';

type SetNewPasswordProps = {
    user: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleSetNewPasswordAttempt: (user: any, password: string, requiredAttributes: any) => void;
    handleRedirectSignIn: () => void;
};

export const SetNewPassword: React.FC<SetNewPasswordProps> = ({
    user,
    handleSetNewPasswordAttempt,
    handleRedirectSignIn,
}) => {
    const intl = useIntl();

    if (user === undefined) {
        handleRedirectSignIn();
    }

    type UserDetails = {
        password: string;
    };

    const [userDetails, setUserDetails] = useState<UserDetails>({
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>(undefined);

    const handlePasswordInputChange: HandleAuthFormInputChange = (value: string) => {
        setUserDetails({ ...userDetails, password: value });
    };

    const handleSetNewPasswordFormSubmit: HandleAuthFormSubmit = async () => {
        setErrorMessage({ content: '', type: 'info', header: '' });

        const { password } = userDetails;

        if (password === '') {
            enum UserKeys {
                email = 'email',
            }

            interface TmpUserDetails {
                [key: string]: UserKeys[keyof UserKeys];
            }

            const tmpUserDetails: TmpUserDetails = userDetails;
            const userKeys: string[] = Object.keys(tmpUserDetails);
            let emptyField;

            for (let i = 0; i < userKeys.length; i++) {
                if (tmpUserDetails[userKeys[i]] === '') {
                    emptyField = userKeys[i];
                    break;
                }
            }

            switch (emptyField) {
                case 'password':
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorEnterPassword',
                            defaultMessage: 'Please enter your password',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
                default:
                    return setErrorMessage({
                        content: intl.formatMessage({
                            id: 'authentication.errorFillInAllFields',
                            defaultMessage: 'Please fill in all the fields.',
                        }),
                        type: 'warning',
                        header: intl.formatMessage({
                            id: 'authentication.errorMessageHeader',
                            defaultMessage: 'There was an issue',
                        }),
                    });
            }
        }

        try {
            await handleSetNewPasswordAttempt(user, password, {});
        } catch (err) {
            setErrorMessage({
                ...errorMessage,
                content: err.message,
                type: 'negative',
                header: 'Error',
            });
        }
    };

    return (
        <View style={setNewPasswordStyles.ViewParent} testID="data-setNewPasswordPage">
            <View style={setNewPasswordStyles.ViewForm} testID="data-setNewPasswordForm">
                <Text style={setNewPasswordStyles.TextHeader}>
                    <FormattedMessage
                        id="authentication.setNewPasswordHeader"
                        defaultMessage="Set A New Password"
                    />
                </Text>

                {errorMessage && (
                    <View style={setNewPasswordStyles.ViewErrorMessage}>
                        <Message errorMessage={errorMessage} />
                    </View>
                )}

                <View style={setNewPasswordStyles.ViewFormGroup}>
                    <Text>
                        <FormattedMessage
                            id="authentication.newPassword"
                            defaultMessage="New Password"
                        />
                    </Text>

                    <TextInput
                        type="password"
                        testID="data-passwordInput"
                        onChange={handlePasswordInputChange}
                        ariaLabel={intl.formatMessage({
                            id: 'authentication.newPassword',
                            defaultMessage: 'New Password',
                        })}
                        value={userDetails.password}
                    />
                </View>

                <View style={setNewPasswordStyles.ViewFormGroup}>
                    <Button
                        title={intl.formatMessage({
                            id: 'authentication.setNewPasswordButton',
                            defaultMessage: 'Submit',
                        })}
                        onPress={handleSetNewPasswordFormSubmit}
                        testID="data-submitButton"
                    />
                </View>
            </View>
        </View>
    );
};

const setNewPasswordStyles = authStyles;
