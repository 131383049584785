import { InMemoryCache } from '@apollo/client';
import schemaFragments from '../schema-fragments';

type PaginatedItemEdge = {
    cursor: string;
    node: {};
};

type PaginatedResult = {
    edges: PaginatedItemEdge[];
    pageInfo: {
        cursor: {
            beforeCursor: string | null;
            afterCursor: string | null;
        };
        hasNextPage: boolean;
        hasPreviousPage: boolean;
    };
};

function mergePaginationResults(
    existing: PaginatedResult,
    incoming: PaginatedResult,
    args: Record<string, any> | null, // eslint-disable-line @typescript-eslint/no-explicit-any
): PaginatedResult {
    if (args && !args.filter.after) {
        return incoming;
    }

    const currentItems = existing.edges.map((item: PaginatedItemEdge) => item.cursor);
    const updatedItemsList = [];

    // Check for duplicate data
    for (let i = 0; i < incoming.edges.length; i++) {
        if (!currentItems.includes(incoming.edges[i].cursor)) {
            updatedItemsList.push(incoming.edges[i]);
        }
    }

    return {
        ...existing,
        pageInfo: incoming.pageInfo,
        edges: [...existing.edges, ...updatedItemsList],
    };
}

export const cache: InMemoryCache = new InMemoryCache({
    possibleTypes: schemaFragments.possibleTypes,
    typePolicies: {
        ServiceProvider: {
            fields: {
                storageItemTypes: {
                    keyArgs: [],
                    merge(existing = {}, incoming, { args }) {
                        return mergePaginationResults(existing, incoming, args);
                    },
                },
                storageItems: {
                    keyArgs: [],
                    merge(existing = {}, incoming, { args }) {
                        return mergePaginationResults(existing, incoming, args);
                    },
                },
                addresses: {
                    keyArgs: [],
                    merge(existing = {}, incoming, { args }) {
                        return mergePaginationResults(existing, incoming, args);
                    },
                },
                visits: {
                    keyArgs: [],
                    merge(existing = {}, incoming, { args }) {
                        return mergePaginationResults(existing, incoming, args);
                    },
                },
                paymentMethods: {
                    keyArgs: [],
                    merge(existing = {}, incoming, { args }) {
                        return mergePaginationResults(existing, incoming, args);
                    },
                },
            },
        },
        Customer: {
            fields: {
                visits: {
                    keyArgs: [],
                    merge(existing = {}, incoming, { args }) {
                        return mergePaginationResults(existing, incoming, args);
                    },
                },
            },
        },
    },
});
