import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { gql } from '@apollo/client';

import { View, Text, StyleSheet } from 'react-native';
import { Button, TextInput, Icon, Card, Modal } from '@valet/ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    useRetrieveStorageContainerItemQuery,
    useRetrieveStorageContainersQuery,
    InventoryStorageContainerFragment,
    StorageItemStatus,
    InventoryStorageItemFragment,
    useAssignStorageContainerMutation,
    RetrieveStorageContainerItemDocument,
    useUnassignStorageContainerMutation,
} from '../../../../schema-types';
import { INVENTORY_STORAGE_ITEM_FRAGMENT } from './InventoryItemDetail';

export const RETRIEVE_STORAGE_CONTAINER_ITEM_QUERY = gql`
    query RetrieveStorageContainerItem($itemId: String) {
        currentCustomer {
            id
            storageItems(filter: { conditions: { field: "id", value: $itemId, operator: Equal } }) {
                edges {
                    node {
                        id
                        ...InventoryStorageItem
                    }
                }
            }
        }
    }
    ${INVENTORY_STORAGE_ITEM_FRAGMENT}
`;

export const ASSIGN_STORAGE_CONTAINER_MUTATION = gql`
    mutation assignStorageContainer($storageItemId: ID!, $storageContainerId: ID!) {
        assignStorageContainer(
            input: { storageItemId: $storageItemId, storageContainerId: $storageContainerId }
        ) {
            storageItem {
                id
                ...InventoryStorageItem
            }
        }
    }
    ${INVENTORY_STORAGE_ITEM_FRAGMENT}
`;

export const UNASSIGN_STORAGE_CONTAINER_MUTATION = gql`
    mutation unassignStorageContainer($storageContainerId: ID!) {
        unassignStorageContainer(input: { storageContainerId: $storageContainerId }) {
            storageItem {
                id
                ...InventoryStorageItem
            }
        }
    }
    ${INVENTORY_STORAGE_ITEM_FRAGMENT}
`;

export const inventoryContainerItemDetailsPageSize = 200;

type DetailsType = { [key: string]: string };

type InventoryContainerItemDetailProps = {};

export const InventoryContainerItemDetail: React.FC<InventoryContainerItemDetailProps> = () => {
    const intl = useIntl();

    const [details, setDetails] = useState<DetailsType>({});

    const history = useHistory();
    const match = useRouteMatch();

    const params: { item?: string } = match.params;
    const itemId = params.item;

    const handleGoBackPress = useCallback((): void => {
        history.push('/inventory');
    }, [history]);

    useEffect(() => {
        if (!itemId) {
            handleGoBackPress();
        }
    }, [itemId, handleGoBackPress]);

    const [storageContainerBarcodeInput, setStorageContainerBarcodeInput] = useState<string>('');
    const [displayModal, setDisplayModal] = useState<boolean>(false);

    const { data, loading, error } = useRetrieveStorageContainerItemQuery({
        variables: { itemId: itemId },
    });
    const item: InventoryStorageItemFragment | undefined =
        data?.currentCustomer?.storageItems?.edges[0].node;

    const {
        data: storageContainersData,
        loading: storageContainersLoading,
        error: storageContainersError,
        fetchMore: storageContainersFetchMore,
    } = useRetrieveStorageContainersQuery({
        variables: { pageSize: inventoryContainerItemDetailsPageSize, cursor: '' },
    });

    const hasNextPage =
        storageContainersData?.currentCustomer?.storageContainers?.pageInfo?.hasNextPage ?? false;
    const endCursor =
        storageContainersData?.currentCustomer?.storageContainers?.pageInfo?.cursor?.afterCursor;
    const [isFetching, setIsFetching] = useState<boolean>(false);

    useEffect(() => {
        if (hasNextPage && !isFetching) {
            setIsFetching(true);
            storageContainersFetchMore({
                variables: {
                    pageSize: inventoryContainerItemDetailsPageSize,
                    cursor: `${endCursor}`,
                },
            }).then(() => setIsFetching(false));
        }
    }, [endCursor, hasNextPage, isFetching, storageContainersFetchMore]);

    const [
        assignStorageContainer,
        {
            // data: assignStorageContainerData,
            loading: assignStorageContainerLoading,
            error: assignStorageContainerError,
        },
    ] = useAssignStorageContainerMutation();

    const [
        unassignStorageContainer,
        {
            // data: unassignStorageContainerData,
            loading: unassignStorageContainerLoading,
            error: unassignStorageContainerError,
        },
    ] = useUnassignStorageContainerMutation();

    const storageContainers: InventoryStorageContainerFragment[] =
        storageContainersData?.currentCustomer?.storageContainers.edges.map(
            (edge: { cursor: string; node: InventoryStorageContainerFragment }) => edge.node,
        ) ?? [];

    const storageItemStatusTextMapping: { [key: string]: string } = {
        CREATED: intl.formatMessage({
            id: 'inventory.statusCreated',
            defaultMessage: 'This item has been recently added.',
        }),
        IN_TRANSIT: intl.formatMessage({
            id: 'inventory.statusInTransit',
            defaultMessage: 'This item is in transit.',
        }),
        AT_CUSTOMER_LOCATION: intl.formatMessage({
            id: 'inventory.statusAtCustomerLocation',
            defaultMessage: 'This item is not in storage.',
        }),
        IN_STORAGE: intl.formatMessage({
            id: 'inventory.statusInStorage',
            defaultMessage: 'This item is in storage.',
        }),
        ARCHIVED: intl.formatMessage({
            id: 'inventory.statusArchived',
            defaultMessage: 'This item is no longer active.',
        }),
    };

    if (loading || storageContainersLoading) {
        return (
            <View testID="data-inventoryContainerItemDetailPage">
                <FormattedMessage
                    id="inventoryContainerItemDetail.loading"
                    defaultMessage="Loading..."
                />
            </View>
        );
    }

    if (
        error ||
        storageContainersError ||
        !item ||
        assignStorageContainerError ||
        unassignStorageContainerError
    ) {
        return (
            <View testID="data-inventoryContainerItemDetailPage">
                <Text>
                    <FormattedMessage
                        id="inventoryContainerItemDetail.error"
                        defaultMessage="There was an error - please refresh this page."
                    />
                </Text>
            </View>
        );
    }

    const {
        id,
        status,
        storageItemType: { metadataDefinitions },
        visits: { edges: itemVisit },
        container,
    } = item;

    const storageContainersWithoutAssociatedItems = storageContainers.filter(
        (container) => !container.assignedStorageItem,
    );

    const sortedStorageContainers: {
        matchesItem: Array<string | undefined | null>;
        doesNotMatchItem: Array<string | undefined | null>;
    } = {
        matchesItem: storageContainersWithoutAssociatedItems
            .filter(
                (container) =>
                    container.containerType?.id === item.storageItemType.containerType?.id,
            )
            .filter((container) => container.barcode !== null && container.barcode !== undefined)
            .map((container) => container.barcode),
        doesNotMatchItem: storageContainersWithoutAssociatedItems
            .filter(
                (container) =>
                    container.containerType?.id !== item.storageItemType.containerType?.id,
            )
            .filter((container) => container.barcode !== null && container.barcode !== undefined)
            .map((container) => container.barcode),
    };

    if (details === undefined) {
        const detailsFields: DetailsType = {};

        item.storageItemType.metadataDefinitions?.forEach((data) => {
            // TODO: Support other types of data:
            //      STRING_SINGLE_LINE,
            //      STRING_MULTI_LINE,
            //      LIST_DROPDOWN,
            //      LIST_CHECKBOX,
            //      LIST_RADIO,
            //      BOOLEAN,
            //      NUMBER_INTEGER,
            //      NUMBER_DECIMAL,

            if (typeof data.values === 'string') {
                detailsFields[data.propertyName] = data.values;
            }
        });

        setDetails(detailsFields);
    }

    const handleSaveDetailsSuccess = async (storageItemId: string): Promise<void> => {
        const filteredStorageContainer = storageContainers.filter(
            (container) => container.barcode === storageContainerBarcodeInput,
        );

        if (filteredStorageContainer.length > 0) {
            const storageContainerId = filteredStorageContainer[0].id;

            await assignStorageContainer({
                variables: {
                    storageItemId: storageItemId,
                    storageContainerId: storageContainerId,
                },
                refetchQueries: [
                    {
                        query: RetrieveStorageContainerItemDocument,
                        variables: {
                            itemId: itemId,
                        },
                    },
                ],
            }).then(() => setStorageContainerBarcodeInput(''));
        }
    };

    const handleReleaseContainer = async (): Promise<void> => {
        if (container) {
            await unassignStorageContainer({
                variables: {
                    storageContainerId: container.id,
                },
                refetchQueries: [
                    {
                        query: RetrieveStorageContainerItemDocument,
                        variables: {
                            itemId: itemId,
                        },
                    },
                ],
            }).then(() => setStorageContainerBarcodeInput(''));
        }
    };

    const handleStorageContainerBarcodeInputChange = (value: string): void => {
        setStorageContainerBarcodeInput(value);
    };

    const handleSaveDetailsPress = (): void => {
        if (sortedStorageContainers.doesNotMatchItem.includes(storageContainerBarcodeInput)) {
            setDisplayModal(true);
        } else {
            handleSaveDetailsSuccess(id);
        }
    };

    return (
        <View style={styles.ViewParent} testID="data-inventoryDetailPage">
            <Modal
                visible={displayModal}
                onBackdropPress={() => setDisplayModal(false)}
                testID="data-inventoryDetailConfirmationModal"
            >
                <Card disabled style={{ margin: 10 }}>
                    <View style={{ marginBottom: 5 }}>
                        <Text>
                            <FormattedMessage
                                id="inventoryDetail.warningContainerSizeContent"
                                defaultMessage="Storage container size does not match size of this item. Do you want to continue?"
                            />
                        </Text>
                    </View>

                    <View
                        style={{
                            marginTop: 5,
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <View style={{ flex: 1, marginRight: 5 }}>
                            <Button
                                title={intl.formatMessage({
                                    id: 'inventoryDetail.confirmationApprove',
                                    defaultMessage: 'Yes, save details',
                                })}
                                onPress={() => {
                                    setDisplayModal(false);
                                    handleSaveDetailsSuccess(id);
                                }}
                                status="primary"
                            />
                        </View>

                        <View
                            style={{
                                flex: 1,
                                marginLeft: 5,
                            }}
                        >
                            <Button
                                title={intl.formatMessage({
                                    id: 'inventoryDetail.confirmationDeny',
                                    defaultMessage: 'No, go back',
                                })}
                                onPress={() => setDisplayModal(false)}
                                status="warning"
                            />
                        </View>
                    </View>
                </Card>
            </Modal>
            <View style={styles.ViewTopBar}>
                <Text onPress={handleGoBackPress}>
                    <Icon icon="back" />{' '}
                    <FormattedMessage id="inventoryDetail.back" defaultMessage="Back" />
                </Text>
            </View>

            {metadataDefinitions?.map((metadata) => (
                <View style={styles.ViewContent} key={metadata.propertyName}>
                    <Text style={styles.TextHeader}>{metadata.propertyName}</Text>

                    <TextInput
                        type="text"
                        value={details[metadata.propertyName]}
                        ariaLabel={details[metadata.propertyName]}
                        onChange={(value: string) =>
                            setDetails({
                                ...details,
                                [metadata.propertyName]: value,
                            })
                        }
                    />
                </View>
            ))}

            <View style={styles.ViewContent}>
                <Text style={styles.TextHeader}>
                    <FormattedMessage id="inventoryDetail.statusHeader" defaultMessage="Status" />
                </Text>
                <Text>{storageItemStatusTextMapping[`${status}`]}</Text>
            </View>

            <View style={styles.ViewContent}>
                <Text style={styles.TextHeader}>
                    <FormattedMessage
                        id="inventoryDetail.scheduleDetailsHeader"
                        defaultMessage="Schedule Details"
                    />
                </Text>
                <Text>
                    <FormattedMessage
                        id="inventoryDetail.scheduleDetailsText"
                        defaultMessage="Scheduled between {startTime} and {endTime} on {date}."
                        values={{
                            startTime: intl.formatTime(new Date(itemVisit[0].node.startTime), {
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                            endTime: intl.formatTime(new Date(itemVisit[0].node.endTime), {
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                            date: intl.formatDate(new Date(itemVisit[0].node.startTime), {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            }),
                        }}
                    />
                </Text>
            </View>

            {!item.container && item.status !== StorageItemStatus.InStorage ? (
                <View style={styles.ViewContent}>
                    <Text style={styles.TextHeader}>
                        <FormattedMessage
                            id="inventoryContainerItemDetail.associateItemDetailsHeader"
                            defaultMessage="Associate storage item to a storage container"
                        />
                    </Text>

                    <View style={styles.ViewContentChild}>
                        <TextInput
                            type="text"
                            onChange={handleStorageContainerBarcodeInputChange}
                            value={storageContainerBarcodeInput}
                            placeholder={intl.formatMessage({
                                id: 'inventoryContainerItemDetail.enterContainerBarcode',
                                defaultMessage: 'Enter storage container barcode',
                            })}
                            ariaLabel={intl.formatMessage({
                                id: 'inventoryContainerItemDetail.enterContainerBarcode',
                                defaultMessage: 'Enter storage container barcode',
                            })}
                            status={
                                sortedStorageContainers.matchesItem.includes(
                                    storageContainerBarcodeInput,
                                )
                                    ? 'success'
                                    : sortedStorageContainers.doesNotMatchItem.includes(
                                          storageContainerBarcodeInput,
                                      )
                                    ? 'warning'
                                    : undefined
                            }
                        />
                    </View>
                </View>
            ) : (
                <Button
                    title={intl.formatMessage({
                        id: 'inventoryDetail.disassociateContainerButton',
                        defaultMessage: 'Release container',
                    })}
                    onPress={handleReleaseContainer}
                    appearance="outline"
                />
            )}

            <View style={styles.ViewContent}>
                <Button
                    title={intl.formatMessage({
                        id: 'inventoryDetail.saveDetails',
                        defaultMessage: 'Save Details',
                    })}
                    onPress={handleSaveDetailsPress}
                    disabled={assignStorageContainerLoading || unassignStorageContainerLoading}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    ViewTopBar: {
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#d4d4d5',
    },
    ViewContent: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        marginVertical: 5,
    },
    ViewContentChild: {
        marginVertical: 5,
    },
    TextHeader: {
        fontWeight: '700',
    },
});
