import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { Button, TextInput } from '@valet/ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { InventoryContainers } from './Container/InventoryContainers';
import { InventoryItems } from './Item/InventoryItems';

import {
    InventoryStorageContainerFragment,
    InventoryStorageItemFragment,
} from '../../../schema-types';

export const inventoryItemsPageSize = 50;

export type InventorySelectedItemsType = {
    pickup: InventoryStorageItemFragment[];
    delivery: InventoryStorageItemFragment[];
    emptyContainers: InventoryStorageContainerFragment[];
};

type InventoryProps = {
    onScheduleVisit: (selectedItems: InventorySelectedItemsType) => void;
};

export const Inventory: React.FC<InventoryProps> = ({ onScheduleVisit }) => {
    const intl = useIntl();

    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedItems, setSelectedItems] = useState<InventorySelectedItemsType>({
        pickup: [],
        delivery: [],
        emptyContainers: [],
    });

    const history = useHistory();

    const handleSearchTextInputChange = (value: string): void => {
        setSearchValue(value);
    };

    const handleItemSelectionChange = useCallback(
        (item: InventoryStorageItemFragment) => {
            switch (item.status) {
                case 'AT_CUSTOMER_LOCATION':
                    if (selectedItems.pickup.includes(item)) {
                        setSelectedItems({
                            ...selectedItems,
                            pickup: selectedItems.pickup.filter(
                                (selectedItem) => selectedItem !== item,
                            ),
                        });
                    } else {
                        setSelectedItems({
                            ...selectedItems,
                            pickup: [...selectedItems.pickup, item],
                        });
                    }
                    break;
                case 'CREATED':
                    if (selectedItems.pickup.includes(item)) {
                        setSelectedItems({
                            ...selectedItems,
                            pickup: selectedItems.pickup.filter(
                                (selectedItem) => selectedItem !== item,
                            ),
                        });
                    } else {
                        setSelectedItems({
                            ...selectedItems,
                            pickup: [...selectedItems.pickup, item],
                        });
                    }
                    break;
                case 'IN_STORAGE':
                    if (selectedItems.delivery.includes(item)) {
                        setSelectedItems({
                            ...selectedItems,
                            delivery: selectedItems.delivery.filter(
                                (selectedItem) => selectedItem !== item,
                            ),
                        });
                    } else {
                        setSelectedItems({
                            ...selectedItems,
                            delivery: [...selectedItems.delivery, item],
                        });
                    }
                    break;
                default:
                    break;
            }
        },
        [selectedItems],
    );

    const handleEditItemPress = useCallback(
        (id: string, container: boolean) => {
            if (container) {
                history.push(`/inventory/item/container/${id}`);
            } else {
                history.push(`/inventory/item/${id}`);
            }
        },
        [history],
    );

    const handleEmptyContainerSelectionChange = useCallback(
        (container: InventoryStorageContainerFragment) => {
            if (selectedItems.emptyContainers.includes(container)) {
                setSelectedItems({
                    ...selectedItems,
                    emptyContainers: selectedItems.emptyContainers.filter(
                        (selectedItem) => selectedItem !== container,
                    ),
                });
            } else {
                setSelectedItems({
                    ...selectedItems,
                    emptyContainers: [...selectedItems.emptyContainers, container],
                });
            }
        },
        [selectedItems],
    );

    const handleEditStorageContainerPress = useCallback(
        (id: string) => {
            history.push(`/inventory/container/${id}`);
        },
        [history],
    );

    const handleScheduleVisitPress = (): void => {
        onScheduleVisit(selectedItems);
        return history.push('/delivery');
    };

    return (
        <View style={inventoryStyles.ViewParent} testID="data-inventoryPage">
            <ScrollView style={inventoryStyles.ScrollViewItemsParent}>
                <View>
                    <TextInput
                        onChange={handleSearchTextInputChange}
                        type="text"
                        value={searchValue}
                        ariaLabel={intl.formatMessage({
                            id: 'inventory.searchboxPlaceholder',
                            defaultMessage: 'Search for item',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'inventory.searchboxPlaceholder',
                            defaultMessage: 'Search for item',
                        })}
                        testID="data-inventorySearchbox"
                    />
                </View>

                <InventoryItems
                    searchValue={searchValue}
                    selectedItems={selectedItems}
                    onEditItemPress={handleEditItemPress}
                    onItemSelectionChange={handleItemSelectionChange}
                />

                <InventoryContainers
                    searchValue={searchValue}
                    selectedItems={selectedItems}
                    onEditStorageContainerPress={handleEditStorageContainerPress}
                    onEmptyContainerSelectionChange={handleEmptyContainerSelectionChange}
                />
            </ScrollView>

            <View style={inventoryStyles.ViewActions}>
                <View style={inventoryStyles.ViewSelectedItems}>
                    <Text testID="data-numPickupItems">
                        {selectedItems.pickup.length}{' '}
                        <FormattedMessage
                            id="inventory.numberItemsForPickup"
                            defaultMessage="x Items For Pickup"
                        />
                    </Text>
                    <Text testID="data-numDeliveryItems">
                        {selectedItems.delivery.length}{' '}
                        <FormattedMessage
                            id="inventory.numberItemsForDelivery"
                            defaultMessage="x Items For Delivery"
                        />
                    </Text>
                </View>

                <View style={inventoryStyles.ViewActionButtons}>
                    <Button
                        title={intl.formatMessage({
                            id: 'inventory.scheduleVisit',
                            defaultMessage: 'Schedule Visit',
                        })}
                        onPress={handleScheduleVisitPress}
                        disabled={
                            selectedItems.pickup.length === 0 &&
                            selectedItems.delivery.length === 0 &&
                            selectedItems.emptyContainers.length === 0
                        }
                    />
                </View>
            </View>
        </View>
    );
};

export const inventoryStyles = StyleSheet.create({
    ViewParent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column' as 'column',
        flex: 1,
    },
    ScrollViewItemsParent: {},
    ViewItems: {
        flex: 1,
    },
    ViewActions: {
        height: 50,
        borderTopColor: '#d4d4d5',
        borderTopWidth: 1,
        marginTop: 5,
        padding: 5,
        display: 'flex',
        flexDirection: 'row' as 'row',
    },
    ViewSelectedItems: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column' as 'column',
    },
    ViewActionButtons: {
        flex: 1,
        justifyContent: 'center',
    },
    ViewTabs: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
    },
    ViewContent: {},
    ViewGroupStatus: {
        paddingVertical: 5,
    },
    TextTab: {
        flex: 1,
        color: '#000',
        backgroundColor: '#D4D4D5',
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
    },
    TextGroupStatusHeader: {
        fontWeight: '700',
    },
});
