import React, { useState } from 'react';

import { format, parseISO } from 'date-fns';

import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Button } from '@valet/ui-components';

import { CustomerAddress } from '../../Pages/Request/Components/AddressSelection/AddressSelection';
import { CustomerPaymentMethod } from '../../Pages/Request/Components/PaymentSelection/PaymentSelection';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    SaveVisitInput,
    useSaveVisitMutation,
    VisitSelectionVisitFragmentFragment,
} from '../../../schema-types';

type RequestNewItemPickupInput = {
    storageItemTypeId: string;
    quantity: number;
};

type RequestExistingItemPickupInput = {
    storageItemId: string;
};

type RequestItemDeliveryInput = {
    storageItemId: string;
};

type RequestEmptyContainersPickupInput = {
    storageContainerId: string;
};

export type ReviewDetails = {
    newVisit: boolean;
    selectedItems: {
        newItems?: Record<string, { storageItemTypeName: string; quantity: number }>;
        existingItems?: {
            delivery: { storageItemId: string; storageItemTypeName: string; description: string }[];
            pickup: { storageItemId: string; storageItemTypeName: string; description: string }[];
        };
        emptyContainers?: { storageContainerId: string }[];
    };
    selectedVisit: VisitSelectionVisitFragmentFragment;
    selectedAddress: CustomerAddress;
    selectedPaymentMethod: CustomerPaymentMethod;
    containsContainers: boolean;
    containersFigureOutLater?: boolean;
    secondNewVisit?: boolean;
    secondSelectedVisit?: VisitSelectionVisitFragmentFragment;
    secondSelectedAddress?: CustomerAddress;
    totalCost: number;
    monthlyCost: number;
};

type RequestReviewProps = {
    requestType: 'pickup' | 'delivery';
    reviewDetails: ReviewDetails;
    onSuccessfulRequestSubmit: () => void;
    onBackToItemSelectionPagePress: () => void;
};

export const RequestReview: React.FC<RequestReviewProps> = ({
    requestType,
    reviewDetails,
    onSuccessfulRequestSubmit,
    onBackToItemSelectionPagePress,
}) => {
    const intl = useIntl();

    const [showBreakdown, setShowBreakdown] = useState<boolean>(false);

    const {
        newVisit,
        selectedItems,
        selectedVisit: { id: visitId, startTime, endTime },
        selectedAddress: { id: addressId, address1, city, zoneId, countryId, zip },
        selectedPaymentMethod: { id: paymentMethodId, description: paymentMethodDescription },
        containsContainers,
        containersFigureOutLater,
        secondNewVisit,
        secondSelectedVisit,
        secondSelectedAddress,
        totalCost,
        monthlyCost,
    } = reviewDetails;

    const [
        saveVisit,
        { data: saveVisitData, loading: saveVisitLoading, error: saveVisitError },
    ] = useSaveVisitMutation();

    const onRequestConfirmationPress = async (): Promise<void> => {
        // New Items For Pickup
        let newItemsPickup: RequestNewItemPickupInput[] = [];

        if (newVisit) {
            const items: Record<string, { storageItemTypeName: string; quantity: number }> =
                selectedItems.newItems ?? {};

            newItemsPickup = Object.keys(items).map((key) => {
                return {
                    storageItemTypeId: key,
                    quantity: items[key].quantity ?? 0,
                };
            });
        } else {
            const items = selectedItems.newItems ?? [];

            newItemsPickup = Object.keys(items).map((key) => {
                return {
                    storageItemTypeId: key,
                    quantity: selectedItems.newItems ? selectedItems.newItems[key].quantity : 0,
                };
            });
        }

        // Existing Items For Pickup
        const existingItemsPickup: RequestExistingItemPickupInput[] =
            selectedItems.existingItems?.pickup.map((storageItem) => {
                return {
                    storageItemId: storageItem.storageItemId,
                };
            }) ?? [];

        // Items For Delivery
        const itemsDelivery: RequestItemDeliveryInput[] =
            selectedItems.existingItems?.delivery.map((storageItem) => {
                return {
                    storageItemId: storageItem.storageItemId,
                };
            }) ?? [];

        // Empty containers for pickup
        const emptyContainersPickup: RequestEmptyContainersPickupInput[] =
            selectedItems.emptyContainers?.map((container) => {
                return {
                    storageContainerId: container.storageContainerId,
                };
            }) ?? [];

        // Default Option 1: One visit
        const saveVisitData: SaveVisitInput = {
            newItemPickupRequests: newItemsPickup,
            existingItemPickupRequests: existingItemsPickup,
            itemDeliveryRequests: itemsDelivery,
            emptyContainerPickupRequests: emptyContainersPickup,
            containsContainers: false,
            containersOneVisit: false,
        };

        if (newVisit) {
            saveVisitData.customerAddressId = addressId ?? '';
            saveVisitData.customerPaymentMethodId = paymentMethodId ?? '';
            saveVisitData.startTime = new Date(startTime);
            saveVisitData.endTime = new Date(endTime);
        } else {
            saveVisitData.visitId = visitId;
        }

        if (containsContainers) {
            saveVisitData.containsContainers = true;
            saveVisitData.containersOneVisit = !secondNewVisit && secondSelectedVisit === undefined;

            if (secondNewVisit) {
                // Option 2: Two visits
                saveVisitData.secondCustomerAddressId = secondSelectedAddress?.id ?? '';
                saveVisitData.secondCustomerPaymentMethodId = paymentMethodId ?? '';
                saveVisitData.secondStartTime = new Date(secondSelectedVisit?.startTime);
                saveVisitData.secondEndTime = new Date(secondSelectedVisit?.endTime);
            } else {
                if (secondSelectedVisit !== undefined) {
                    // Option 2: Two visits
                    saveVisitData.secondVisitId = secondSelectedVisit.id;
                } else if (containersFigureOutLater) {
                    // Option 3: Figure it out later
                    saveVisitData.containersOneVisit = false;
                }
            }
        }

        try {
            await saveVisit({
                variables: {
                    saveVisitData: saveVisitData,
                },
            });
        } catch (ignore) {} //eslint-disable-line no-empty
    };

    if (saveVisitLoading) {
        return (
            <View>
                <Text>
                    <FormattedMessage
                        id="requestReview.submittingRequest"
                        defaultMessage="Submitting request..."
                    />
                </Text>
            </View>
        );
    }

    if (saveVisitError) {
        return (
            <View>
                <Text>
                    <FormattedMessage
                        id="requestReview.error"
                        defaultMessage="There was an error."
                    />
                </Text>
            </View>
        );
    }

    if (saveVisitData) {
        onSuccessfulRequestSubmit();
        return (
            <View>
                <Text>
                    <FormattedMessage
                        id="requestReview.requestSubmitted"
                        defaultMessage="Submitted the request."
                    />
                </Text>
            </View>
        );
    }

    return (
        <View testID="data-requestReviewForm">
            <Text style={requestReviewStyles.TextHeader}>
                <FormattedMessage
                    id="requestReview.reviewOrderHeader"
                    defaultMessage="Review your order"
                />
            </Text>

            <View style={requestReviewStyles.ViewSection}>
                {secondSelectedVisit && (
                    <Text style={{ fontWeight: '700', fontSize: 16 }}>First Visit</Text>
                )}

                <Text style={requestReviewStyles.TextSubHeader}>
                    <FormattedMessage id="requestReview.addressHeader" defaultMessage="Address" />
                </Text>

                <Text>{address1}</Text>
                <Text>
                    {/* TODO: Format address based on template */}
                    {city} {zoneId}, {countryId}
                </Text>
                <Text>{zip}</Text>
            </View>

            <View style={requestReviewStyles.ViewSection}>
                <Text style={requestReviewStyles.TextSubHeader}>
                    <FormattedMessage
                        id="requestReview.scheduledVisitWindow"
                        defaultMessage="Scheduled Visit Window"
                    />
                </Text>

                <Text>{format(new Date(startTime), 'MMMM dd yyyy')}</Text>
                <Text>
                    <FormattedMessage
                        id="requestReview.visitWindowbetweenTimes"
                        defaultMessage="Between {startTime} and {endTime}"
                        values={{
                            startTime: intl.formatTime(parseISO(startTime), {
                                hour: 'numeric',
                                minute: 'numeric',
                            }),

                            endTime: intl.formatTime(parseISO(endTime), {
                                hour: 'numeric',
                                minute: 'numeric',
                            }),
                        }}
                    />
                </Text>
            </View>

            {secondSelectedVisit && (
                <View>
                    <Text style={{ fontWeight: '700', fontSize: 16 }}>Second Visit</Text>

                    <View style={requestReviewStyles.ViewSection}>
                        <Text style={requestReviewStyles.TextSubHeader}>
                            <FormattedMessage
                                id="requestReview.addressHeader"
                                defaultMessage="Address"
                            />
                        </Text>

                        {/* TODO: Use address selected for second visit */}
                        <Text>{secondSelectedAddress?.address1}</Text>
                        <Text>
                            {/* TODO: Format address based on template */}
                            {secondSelectedAddress?.city} {secondSelectedAddress?.zoneId},{' '}
                            {secondSelectedAddress?.countryId}
                        </Text>
                        <Text>{secondSelectedAddress?.zip}</Text>
                    </View>

                    <View style={requestReviewStyles.ViewSection}>
                        <Text style={requestReviewStyles.TextSubHeader}>
                            <FormattedMessage
                                id="requestReview.scheduledVisitWindow"
                                defaultMessage="Scheduled Visit Window"
                            />
                        </Text>

                        <Text>
                            {format(new Date(secondSelectedVisit.startTime), 'MMMM dd yyyy')}
                        </Text>
                        <Text>
                            <FormattedMessage
                                id="requestReview.visitWindowbetweenTimes"
                                defaultMessage="Between {startTime} and {endTime}"
                                values={{
                                    startTime: intl.formatTime(
                                        parseISO(secondSelectedVisit.startTime),
                                        {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                        },
                                    ),

                                    endTime: intl.formatTime(
                                        parseISO(secondSelectedVisit.endTime),
                                        {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                        },
                                    ),
                                }}
                            />
                        </Text>
                    </View>
                </View>
            )}

            <View style={requestReviewStyles.ViewSection}>
                <Text style={requestReviewStyles.TextSubHeader}>
                    <FormattedMessage
                        id="requestReview.selectedPaymentMethod"
                        defaultMessage="Selected Payment Method"
                    />
                </Text>

                <Text>{paymentMethodDescription}</Text>
            </View>

            <View
                style={{
                    margin: 10,
                    borderTopColor: 'rgba(0, 0, 0, 0.1)',
                    borderTopWidth: 2,
                }}
            />

            <View style={requestReviewStyles.ViewBreakdownSection}>
                <TouchableOpacity
                    activeOpacity={0.8}
                    onPress={() => setShowBreakdown(!showBreakdown)}
                >
                    <Text style={{ color: 'rgba(17, 82, 147, 1)' }}>
                        {showBreakdown ? (
                            <FormattedMessage
                                id="requestReview.requestBreakdownHideText"
                                defaultMessage="Hide breakdown"
                            />
                        ) : (
                            <FormattedMessage
                                id="requestReview.requestBreakdownShowText"
                                defaultMessage="Show breakdown"
                            />
                        )}
                    </Text>
                </TouchableOpacity>

                {showBreakdown && (
                    <View style={requestReviewStyles.ViewBreakdown}>
                        {(selectedItems.newItems ||
                            (selectedItems.existingItems?.pickup &&
                                selectedItems.existingItems.pickup.length > 0)) && (
                            <>
                                <View style={requestReviewStyles.ViewBreakdownItem}>
                                    <Text style={requestReviewStyles.BreakdownItemHeader}>
                                        <FormattedMessage
                                            id="requestReview.requestBreakdownItemHeaderItemsBeingPicked"
                                            defaultMessage="Items being picked up"
                                        />
                                    </Text>

                                    {Object.keys(selectedItems.newItems ?? {}).map((item) => (
                                        <BreakdownItemCost
                                            key={item}
                                            cost={10}
                                            item={item}
                                            name={
                                                selectedItems?.newItems
                                                    ? selectedItems.newItems[item]
                                                          .storageItemTypeName
                                                    : item
                                            }
                                        />
                                    ))}

                                    {selectedItems.newItems && (
                                        <View>
                                            <TouchableOpacity
                                                activeOpacity={0.8}
                                                onPress={onBackToItemSelectionPagePress}
                                            >
                                                <Text style={{ color: 'rgba(17, 82, 147, 1)' }}>
                                                    <FormattedMessage
                                                        id="requestReview.backToItemSelectionPageButton"
                                                        defaultMessage="Change item selection"
                                                    />
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    )}

                                    {selectedItems.existingItems?.pickup.map((item) => (
                                        <BreakdownItemCost
                                            key={item.storageItemId}
                                            cost={10}
                                            item={item.storageItemId}
                                            name={item.storageItemTypeName}
                                        />
                                    ))}
                                </View>
                            </>
                        )}

                        {selectedItems.existingItems?.delivery &&
                            selectedItems.existingItems?.delivery.length > 0 && (
                                <View style={requestReviewStyles.ViewBreakdownItem}>
                                    <Text style={requestReviewStyles.BreakdownItemHeader}>
                                        <FormattedMessage
                                            id="requestReview.requestBreakdownItemHeaderItemsBeingDelivered"
                                            defaultMessage="Items being delivered"
                                        />
                                    </Text>

                                    {selectedItems.existingItems.delivery.map((item) => (
                                        <BreakdownItemCost
                                            key={item.storageItemId}
                                            cost={10}
                                            item={item.storageItemId}
                                            name={item.storageItemTypeName}
                                        />
                                    ))}
                                </View>
                            )}
                    </View>
                )}
            </View>

            <View style={requestReviewStyles.ViewSection}>
                <Text>
                    {/* TODO: get currency type form application context */}
                    {newVisit ? (
                        <FormattedMessage
                            id="requestReview.visitTotal"
                            defaultMessage="Visit Total: {totalCost}"
                            values={{
                                totalCost: intl.formatNumber(totalCost, {
                                    style: 'currency',
                                    currency: 'USD',
                                }),
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="requestReview.updatedVisitTotal"
                            defaultMessage="Updated Visit Total: {totalCost}"
                            values={{
                                totalCost: intl.formatNumber(totalCost, {
                                    style: 'currency',
                                    currency: 'USD',
                                }),
                            }}
                        />
                    )}
                </Text>

                {monthlyCost > 0 && (
                    <Text>
                        {/* TODO: get currency type form application context */}
                        <FormattedMessage
                            id="requestReview.monthlyTotal"
                            defaultMessage="Additional monthly storage charges: {monthlyCost}"
                            values={{
                                monthlyCost: intl.formatNumber(monthlyCost, {
                                    style: 'currency',
                                    currency: 'USD',
                                }),
                            }}
                        />
                    </Text>
                )}
            </View>

            <View style={{ marginTop: 5 }}>
                {/* TODO: get currency type form application context */}
                <Button
                    title={intl.formatMessage(
                        {
                            id: 'requestReview.confirmOrderButton',
                            defaultMessage: 'Confirm Order - {totalCost}',
                        },
                        {
                            totalCost: intl.formatNumber(totalCost, {
                                style: 'currency',
                                currency: 'USD',
                            }),
                        },
                    )}
                    onPress={onRequestConfirmationPress}
                />
            </View>
        </View>
    );
};

const requestReviewStyles = StyleSheet.create({
    ViewParent: {},
    ViewSection: {},
    ViewBreakdownSection: {
        paddingVertical: 5,
    },
    ViewBreakdown: {
        padding: 3,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.2)',
    },
    ViewBreakdownItem: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    BreakdownItemHeader: {
        fontWeight: '700',
    },
    TextHeader: {
        fontWeight: '700',
        fontSize: 16,
    },
    TextSubHeader: {
        fontWeight: '600',
    },
});

type BreakdownItemCostProps = {
    cost: number;
    item: string;
    name: string;
};

const BreakdownItemCost: React.FC<BreakdownItemCostProps> = ({ cost, item, name }) => {
    const intl = useIntl();

    return (
        <View key={item} style={breakdownCostItemStyles.ViewParent}>
            <View style={{ marginRight: 3 }}>
                <Text style={{ fontWeight: '600' }}>
                    <FormattedMessage
                        id="requestReview.newItemBeingPickedCost"
                        defaultMessage="{cost}"
                        values={{
                            cost: intl.formatNumber(cost, {
                                style: 'currency',
                                currency: 'USD',
                            }),
                        }}
                    />
                </Text>
            </View>

            <View style={{ marginHorizontal: 3, flex: 1 }}>
                <Text>{name}</Text>
            </View>
        </View>
    );
};

const breakdownCostItemStyles = StyleSheet.create({
    ViewParent: {
        display: 'flex',
        flexDirection: 'row',
    },
});
