import React from 'react';
import { gql } from '@apollo/client';

import { View, Text } from 'react-native';
import { ReviewDetails, RequestReview } from '../../../../Organisms/RequestReview/RequestReview';
import { CustomerPaymentMethod } from '../PaymentSelection/PaymentSelection';
import { CustomerAddress } from '../AddressSelection/AddressSelection';
import { FormattedMessage } from 'react-intl';
import { VisitSelectionVisitFragmentFragment } from '../../../../../schema-types';

export const SAVE_VISIT_MUTATION = gql`
    mutation saveVisit($saveVisitData: SaveVisitInput!) {
        saveVisit(data: $saveVisitData) {
            visit {
                id
                status
                startTime
                endTime
                expectedDuration
            }
        }
    }
`;

// TODO: Get total cost from API
const totalCost = 300.04;
// TODO: Get monthly cost from API
const monthlyCost = 15.24;

type PickupRequestReviewPageProps = {
    selectedItems?: Record<string, { storageItemTypeName: string; quantity: number }>;
    selectedVisit?: VisitSelectionVisitFragmentFragment;
    newVisit: boolean;
    selectedAddress?: CustomerAddress;
    selectedPaymentMethod?: CustomerPaymentMethod;
    containsContainers: boolean;
    containersFigureOutLater: boolean;
    secondNewVisit?: boolean;
    secondSelectedVisit?: VisitSelectionVisitFragmentFragment;
    secondSelectedAddress?: CustomerAddress;
    onSuccessfulRequestSubmit: () => void;
    onBackToItemSelectionPagePress: () => void;
};

export const PickupRequestReviewPage: React.FC<PickupRequestReviewPageProps> = ({
    selectedItems = undefined,
    selectedVisit = undefined,
    newVisit,
    selectedAddress = undefined,
    selectedPaymentMethod = undefined,
    containsContainers,
    containersFigureOutLater,
    secondNewVisit,
    secondSelectedVisit = undefined,
    secondSelectedAddress = undefined,
    onSuccessfulRequestSubmit,
    onBackToItemSelectionPagePress,
}) => {
    if (!selectedItems || !selectedVisit || !selectedAddress || !selectedPaymentMethod) {
        // TODO: Provide better error message
        return (
            <View testID="data-pickupRequestReviewPage">
                <Text>
                    <FormattedMessage
                        id="pickupRequest.processErrorTryAgain"
                        defaultMessage="There was an issue; please restart the pickup request."
                    />
                </Text>
            </View>
        );
    }

    const reviewDetails: ReviewDetails = {
        newVisit: newVisit,
        selectedItems: {
            newItems: selectedItems,
        },
        selectedVisit: selectedVisit,
        selectedAddress: selectedAddress,
        selectedPaymentMethod: selectedPaymentMethod,
        containsContainers: containsContainers,
        containersFigureOutLater: containersFigureOutLater,
        secondNewVisit: secondNewVisit,
        secondSelectedVisit: secondSelectedVisit,
        secondSelectedAddress: secondSelectedAddress,
        totalCost: totalCost,
        monthlyCost: monthlyCost,
    };

    return (
        <View testID="data-pickupRequestReviewPage">
            <RequestReview
                requestType="pickup"
                reviewDetails={reviewDetails}
                onSuccessfulRequestSubmit={onSuccessfulRequestSubmit}
                onBackToItemSelectionPagePress={onBackToItemSelectionPagePress}
            />
        </View>
    );
};
