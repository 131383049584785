import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { State } from 'xstate';

import {
    DeliveryMachine,
    DeliveryMachineContext,
    DeliveryMachineEvent,
    backButtonEnabled,
    DeliveryMachineAddItemPayload,
} from './DeliveryStateMachine';

import { View, ScrollView, StyleSheet } from 'react-native';
import { Message, ErrorMessage, Button } from '@valet/ui-components';
import { OrderLoader } from '../Components/OrderLoader';

import { VisitSelectionPage } from '../Components/VisitSelection/VisitSelection';
import {
    AddressSelectionPage,
    CustomerAddress,
} from '../Components/AddressSelection/AddressSelection';
import { NewAddressPage } from '../Components/AddressSelection/NewAddress';
import { NewVisitPage } from '../Components/VisitSelection/NewVisit';
import {
    PaymentSelectionPage,
    CustomerPaymentMethod,
} from '../Components/PaymentSelection/PaymentSelection';
import { NewPaymentPage } from '../Components/PaymentSelection/NewPayment';
import { DeliveryRequestReviewPage } from '../Components/RequestReview/DeliveryRequestReview';
import { ScheduleWindow } from '../Components/VisitSelection/NewVisit/ScheduleWindowSelection';
import { InventorySelectedItemsType } from '../../Inventory/Inventory';
import { RequestSubmittedPage } from '../Components/RequestSubmitted/RequestSubmitted';

type DeliveryRequestProps = {
    selectedItems: InventorySelectedItemsType;
};

export const DeliveryRequest: React.FC<DeliveryRequestProps> = ({ selectedItems }) => {
    const [state, send] = useMachine<DeliveryMachineContext, DeliveryMachineEvent>(DeliveryMachine);

    const history = useHistory();

    useEffect(() => {
        const addItemPayload: DeliveryMachineAddItemPayload = {
            delivery: selectedItems.delivery.map((item) => {
                return {
                    storageItemId: item.id,
                    storageItemTypeName: item.storageItemType.name,
                    description: item.storageItemType.description,
                    container: item.storageItemType.containerType !== undefined,
                };
            }),
            pickup: selectedItems.pickup.map((item) => {
                return {
                    storageItemId: item.id,
                    storageItemTypeName: item.storageItemType.name,
                    description: item.storageItemType.description,
                    container: item.storageItemType.containerType !== undefined,
                };
            }),
            emptyContainers: selectedItems.emptyContainers.map((container) => {
                return {
                    storageContainerId: container.id,
                };
            }),
        };

        send({ type: 'ADD_ITEM', payload: addItemPayload });
    }, [selectedItems, send]);

    const handleGoToNextPage = useCallback(() => {
        send({ type: 'NEXT' });
    }, [send]);

    const handleGoToPreviousPage = useCallback(() => send({ type: 'BACK' }), [send]);

    const handleAddressItemSelection = useCallback(
        (address: CustomerAddress) => {
            send({
                type: 'ADD_ADDRESS',
                payload: {
                    address: address,
                },
            });
        },
        [send],
    );

    const handleNoLoadedAddresses = useCallback(() => {
        send({ type: 'NEW_ADDRESS' });
    }, [send]);

    const handleNewAddressSelection = useCallback(() => {
        send({ type: 'NEW_ADDRESS' });
    }, [send]);

    const handleNewAddressSubmit = useCallback(
        (newAddress: CustomerAddress) => {
            send({
                type: 'ADD_ADDRESS',
                payload: {
                    address: newAddress,
                },
            });
        },
        [send],
    );

    const handleVisitItemSelection = useCallback<
        React.ComponentProps<typeof VisitSelectionPage>['onVisitItemSelection']
    >(
        (visit) => {
            send({ type: 'ADD_VISIT', payload: { visit: visit } });

            send({ type: 'NEXT' });
        },
        [send],
    );

    const handleNewVisitSelection = useCallback(() => send({ type: 'NEW_VISIT' }), [send]);

    const handleNoLoadedVisits = useCallback(() => send({ type: 'NEW_VISIT' }), [send]);

    const handleClearNewVisitDate = useCallback(() => send({ type: 'CLEAR_SCHEDULE_WINDOW' }), [
        send,
    ]);

    const handleScheduleWindowSelection = useCallback(
        (scheduleWindow: ScheduleWindow) => {
            if (scheduleWindow.isLocked === false) {
                send({
                    type: 'ADD_SCHEDULE_WINDOW',
                    payload: {
                        scheduleWindow: scheduleWindow,
                    },
                });
            }
        },
        [send],
    );

    const handleNoLoadedPaymentMethods = useCallback(() => {
        send({ type: 'NEW_PAYMENT_METHOD' });
    }, [send]);

    const handlePaymentMethodSelection = useCallback(
        (paymentMethod: CustomerPaymentMethod): void => {
            send({
                type: 'ADD_PAYMENT_METHOD',
                payload: paymentMethod,
            });
        },
        [send],
    );

    const handleNewPaymentSelection = useCallback(() => {
        send({ type: 'NEW_PAYMENT_METHOD' });
    }, [send]);

    const handleNewPaymentMethodSubmit = useCallback(
        (newPaymentMethod: CustomerPaymentMethod) => {
            send({ type: 'ADD_PAYMENT_METHOD', payload: newPaymentMethod });
        },
        [send],
    );

    const handleSuccessfulRequestSubmit = useCallback(() => {
        send({ type: 'REQUEST_SUBMITTED' });
    }, [send]);

    const handleGoToInventoryPress = useCallback(() => {
        history.push('/inventory');
    }, [history]);

    const handleGoToEditMetadataPress = useCallback(() => {
        console.log('Pressed go to edit metadata ');
    }, []);

    if (!state) {
        return <OrderLoader />;
    }

    const newVisitStateMapping: {
        [key: string]: React.ComponentProps<typeof NewVisitPage>['currentState'];
    } = {
        'newVisit.scheduleWindowSelection': 'scheduleWindowSelection',
    };

    const newVisitState = newVisitStateMapping[`${state?.toStrings(state?.value).pop() ?? ''}`];

    return (
        <ScrollView testID="data-deliveryRequestPage">
            <DeliveryNavigation state={state} handleGoToPreviousPage={handleGoToPreviousPage} />

            <View style={deliveryPageStyles.ViewParent}>
                {state.matches('itemSelection') ? (
                    <OrderLoader />
                ) : state.matches('visitSelection') ? (
                    <VisitSelectionPage
                        onGoToNextPage={handleGoToNextPage}
                        customerAddressId={state.context.selectedAddress?.id}
                        selectedVisit={state.context.selectedVisit}
                        onVisitItemSelection={handleVisitItemSelection}
                        onNewVisitSelection={handleNewVisitSelection}
                        onNoLoadedVisits={handleNoLoadedVisits}
                    />
                ) : state.matches('addressSelection') ? (
                    <AddressSelectionPage
                        onGoToNextPage={handleGoToNextPage}
                        onAddressItemSelection={handleAddressItemSelection}
                        onNewAddressSelection={handleNewAddressSelection}
                        selectedAddress={state.context.selectedAddress}
                        onNoLoadedAddresses={handleNoLoadedAddresses}
                    />
                ) : state.matches('newAddress') ? (
                    <NewAddressPage
                        onGoToNextPage={handleGoToNextPage}
                        customerLocale={'en'} // TODO: Retrieve this from backend
                        customerCountryCode={'CA'} // TODO: Retrieve this from backend
                        onNewAddressSubmit={handleNewAddressSubmit}
                    />
                ) : newVisitState ? (
                    <NewVisitPage
                        onGoToNextPage={handleGoToNextPage}
                        currentState={newVisitState}
                        newVisitDetails={state.context.newVisit}
                        onClearNewVisitDate={handleClearNewVisitDate}
                        onScheduleWindowSelection={handleScheduleWindowSelection}
                        zip={state.context.selectedAddress?.zip || undefined}
                    />
                ) : state.matches('paymentSelection') ? (
                    <PaymentSelectionPage
                        onGoToNextPage={handleGoToNextPage}
                        onPaymentMethodSelection={handlePaymentMethodSelection}
                        selectedPaymentMethod={state.context.selectedPaymentMethod}
                        handleNewPaymentSelection={handleNewPaymentSelection}
                        onNoLoadedPaymentMethods={handleNoLoadedPaymentMethods}
                    />
                ) : state.matches('newPayment') ? (
                    <NewPaymentPage
                        onGoToNextPage={handleGoToNextPage}
                        onNewPaymentMethodSubmit={handleNewPaymentMethodSubmit}
                    />
                ) : state.matches('requestReview') ? (
                    <DeliveryRequestReviewPage
                        selectedItems={state.context.selectedItems}
                        selectedVisit={state.context.selectedVisit ?? state.context.newVisit}
                        newVisit={!!state.context.newVisit}
                        selectedAddress={state.context.selectedAddress}
                        selectedPaymentMethod={state.context.selectedPaymentMethod}
                        containsContainers={
                            [
                                ...Object.values(state.context.selectedItems.delivery),
                                ...Object.values(state.context.selectedItems.pickup),
                            ]
                                .flat()
                                .map((item) => item.container)
                                .filter((container) => container === true).length > 0
                        }
                        onSuccessfulRequestSubmit={handleSuccessfulRequestSubmit}
                    />
                ) : state.matches('requestSubmitted') ? (
                    <RequestSubmittedPage
                        onGoToInventoryPress={handleGoToInventoryPress}
                        onGoToEditMetadataPress={handleGoToEditMetadataPress}
                    />
                ) : (
                    <OrderLoader />
                )}
            </View>
        </ScrollView>
    );
};

type DeliveryNavigationProps = {
    state: State<DeliveryMachineContext, DeliveryMachineEvent, any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    handleGoToPreviousPage: () => void;
};

const DeliveryNavigation: React.FC<DeliveryNavigationProps> = ({
    state,
    handleGoToPreviousPage,
}) => {
    if (!state) {
        return <OrderLoader />;
    }

    return (
        <>
            <View
                style={deliveryNavigationStyles.ViewNavigationButtonsParent}
                testID="data-deliveryRequestNavigation"
            >
                {backButtonEnabled(state) && (
                    <View style={deliveryNavigationStyles.ViewNavigationButton}>
                        <Button
                            title="Back"
                            onPress={handleGoToPreviousPage}
                            disabled={!backButtonEnabled(state)}
                        />
                    </View>
                )}
            </View>

            {state.context.errorMessage && (
                <View
                    style={deliveryNavigationStyles.ViewErrorMessageParent}
                    testID="data-errorMessage"
                >
                    <DeliveryErrorMessage errorMessage={state.context.errorMessage} />
                </View>
            )}
        </>
    );
};

type DeliveryErrorMessageProps = {
    errorMessage: ErrorMessage;
};

const DeliveryErrorMessage: React.FC<DeliveryErrorMessageProps> = ({ errorMessage }) => {
    return <Message errorMessage={errorMessage} />;
};

const deliveryPageStyles = StyleSheet.create({
    ViewParent: {
        paddingTop: 5,
        paddingBottom: 5,
    },
});

const deliveryNavigationStyles = StyleSheet.create({
    ViewNavigationButtonsParent: {
        flexDirection: 'row',
    },
    ViewNavigationButton: {
        flex: 1,
    },
    ViewErrorMessageParent: {
        marginTop: 5,
        marginBottom: 5,
    },
});
