import React from 'react';

import { ValetThemeProvider, ValetTheme } from '@valet/ui-components';

// Include the following properties to ensure the color contrasts are accessible:
// 'color-basic-600': '#576582',
// 'text-hint-color': '$color-basic-600',

const theme: ValetTheme = {
    'color-basic-600': '#576582',
    'text-hint-color': '$color-basic-600',
    'background-basic-color-2': '$color-basic-100',
    'background-basic-color-3': '$color-basic-200',
    'color-primary-100': '#F7C8F8',
    'color-primary-200': '#E894F2',
    'color-primary-300': '#C35AD8',
    'color-primary-400': '#9330B1',
    'color-primary-500': '#59047D',
    'color-primary-600': '#45026B',
    'color-primary-700': '#340259',
    'color-primary-800': '#240148',
    'color-primary-900': '#1A003B',
    'color-primary-transparent-100': 'rgba(89, 4, 125, 0.08)',
    'color-primary-transparent-200': 'rgba(89, 4, 125, 0.16)',
    'color-primary-transparent-300': 'rgba(89, 4, 125, 0.24)',
    'color-primary-transparent-400': 'rgba(89, 4, 125, 0.32)',
    'color-primary-transparent-500': 'rgba(89, 4, 125, 0.4)',
    'color-primary-transparent-600': 'rgba(89, 4, 125, 0.48)',
    'color-success-100': '#E9F9CC',
    'color-success-200': '#CFF49B',
    'color-success-300': '#A6E065',
    'color-success-400': '#7BC13C',
    'color-success-500': '#47990D',
    'color-success-600': '#358309',
    'color-success-700': '#256E06',
    'color-success-800': '#185804',
    'color-success-900': '#0F4902',
    'color-success-transparent-100': 'rgba(71, 153, 13, 0.08)',
    'color-success-transparent-200': 'rgba(71, 153, 13, 0.16)',
    'color-success-transparent-300': 'rgba(71, 153, 13, 0.24)',
    'color-success-transparent-400': 'rgba(71, 153, 13, 0.32)',
    'color-success-transparent-500': 'rgba(71, 153, 13, 0.4)',
    'color-success-transparent-600': 'rgba(71, 153, 13, 0.48)',
    'color-info-100': '#C8FBEB',
    'color-info-200': '#94F7E0',
    'color-info-300': '#5CE7D3',
    'color-info-400': '#33CFC6',
    'color-info-500': '#00AAAF',
    'color-info-600': '#008596',
    'color-info-700': '#00647D',
    'color-info-800': '#004865',
    'color-info-900': '#003553',
    'color-info-transparent-100': 'rgba(0, 170, 175, 0.08)',
    'color-info-transparent-200': 'rgba(0, 170, 175, 0.16)',
    'color-info-transparent-300': 'rgba(0, 170, 175, 0.24)',
    'color-info-transparent-400': 'rgba(0, 170, 175, 0.32)',
    'color-info-transparent-500': 'rgba(0, 170, 175, 0.4)',
    'color-info-transparent-600': 'rgba(0, 170, 175, 0.48)',
    'color-warning-100': '#FBF1C9',
    'color-warning-200': '#F7E094',
    'color-warning-300': '#E8C35D',
    'color-warning-400': '#D2A234',
    'color-warning-500': '#B57800',
    'color-warning-600': '#9B6100',
    'color-warning-700': '#824D00',
    'color-warning-800': '#683A00',
    'color-warning-900': '#562D00',
    'color-warning-transparent-100': 'rgba(181, 120, 0, 0.08)',
    'color-warning-transparent-200': 'rgba(181, 120, 0, 0.16)',
    'color-warning-transparent-300': 'rgba(181, 120, 0, 0.24)',
    'color-warning-transparent-400': 'rgba(181, 120, 0, 0.32)',
    'color-warning-transparent-500': 'rgba(181, 120, 0, 0.4)',
    'color-warning-transparent-600': 'rgba(181, 120, 0, 0.48)',
    'color-danger-100': '#F9CDCD',
    'color-danger-200': '#F49DA7',
    'color-danger-300': '#DF6781',
    'color-danger-400': '#C03F68',
    'color-danger-500': '#961048',
    'color-danger-600': '#810B48',
    'color-danger-700': '#6C0845',
    'color-danger-800': '#57053F',
    'color-danger-900': '#48033B',
    'color-danger-transparent-100': 'rgba(150, 16, 72, 0.08)',
    'color-danger-transparent-200': 'rgba(150, 16, 72, 0.16)',
    'color-danger-transparent-300': 'rgba(150, 16, 72, 0.24)',
    'color-danger-transparent-400': 'rgba(150, 16, 72, 0.32)',
    'color-danger-transparent-500': 'rgba(150, 16, 72, 0.4)',
    'color-danger-transparent-600': 'rgba(150, 16, 72, 0.48)',
};

type ThemeProviderProps = {};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    return <ValetThemeProvider theme={theme}>{children}</ValetThemeProvider>;
};
