import React, { useCallback, useEffect } from 'react';
import { gql } from '@apollo/client';

import { NewPayment } from '../../../../Organisms/NewPayment/NewPaymentForm';

import { View, Text } from 'react-native';
import { Message } from '@valet/ui-components';
import {
    CustomerPaymentMethod,
    PICKUP_RETRIEVE_PAYMENT_METHODS_QUERY,
    paymentMethodsPageSize,
} from './PaymentSelection';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAddPaymentMethodMutation } from '../../../../../schema-types';

export const PICKUP_ADD_CUSTOMER_PAYMENT_METHOD_MUTATION = gql`
    mutation addPaymentMethod($paymentMethod: AddCustomerPaymentMethodInput!) {
        addCustomerPaymentMethod(data: $paymentMethod) {
            paymentMethod {
                id
                description
                isDefault
            }
        }
    }
`;

type NewPaymentPageProps = {
    onGoToNextPage: () => void;
    onNewPaymentMethodSubmit: (newPaymentMethod: CustomerPaymentMethod) => void;
};

export const NewPaymentPage: React.FC<NewPaymentPageProps> = ({
    onGoToNextPage,
    onNewPaymentMethodSubmit,
}) => {
    const intl = useIntl();

    const [addCustomerPaymentMethod, { data, loading, error }] = useAddPaymentMethodMutation({
        refetchQueries: [
            {
                query: PICKUP_RETRIEVE_PAYMENT_METHODS_QUERY,
                variables: { pageSize: paymentMethodsPageSize, cursor: '' },
            },
        ],
        awaitRefetchQueries: true,
    });

    useEffect(() => {
        if (data !== undefined) {
            onGoToNextPage();
            return;
        }
    }, [data, onGoToNextPage]);

    const handleSuccessfulSubmit = useCallback(
        async (paymentMethod: CustomerPaymentMethod): Promise<void> => {
            const { description } = paymentMethod;

            try {
                const res = await addCustomerPaymentMethod({
                    variables: {
                        paymentMethod: {
                            description: description,
                            isDefault: false,
                        },
                    },
                });

                if (res.data?.addCustomerPaymentMethod.paymentMethod) {
                    onNewPaymentMethodSubmit(res.data?.addCustomerPaymentMethod.paymentMethod);
                }
            } catch (ignore) {} //eslint-disable-line no-empty
        },
        [addCustomerPaymentMethod, onNewPaymentMethodSubmit],
    );

    if (loading) {
        return (
            <View testID="data-newPaymentPage">
                <Text>
                    <FormattedMessage
                        id="paymentSelection.addingNewPaymentMethodLoading"
                        defaultMessage="Adding new payment method..."
                    />
                </Text>
            </View>
        );
    }

    if (!data) {
        return (
            <View testID="data-newPaymentPage">
                {error && (
                    <Message
                        type="negative"
                        header={intl.formatMessage({
                            id: 'paymentSelection.errorCouldNoAddPaymentMethodTryAgain',
                            defaultMessage: 'Could not add your payment method - please try again',
                        })}
                        content={error.message}
                        testID="data-newPaymentPageMessage"
                    />
                )}

                <NewPayment onSuccessfulSubmit={handleSuccessfulSubmit} />
            </View>
        );
    }

    return (
        <Text>Check the console. Remove this text after implementing the request review page</Text>
    );
};
